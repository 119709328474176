import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Switch,
  Text,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { IconArrowRight, IconPlus } from '@tabler/icons-react'
import L, { latLngBounds, Map } from 'leaflet'
// @ts-ignore importing image
import 'leaflet/dist/leaflet.css'
import throttle from 'lodash/throttle'
import unionBy from 'lodash/unionBy'
import pluralize from 'pluralize'
import * as React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { useMountedState } from 'react-use'
import { subscribeToChannel, SubscriptionEmitter } from '../../../channels/generic_channel'
import { concurrentGET } from '../../../lib/api'
import Router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import type { Project } from '../../../types/Project'
import { SdkSettings } from '../../../types/ProjectSettings'
import type { Visitor } from '../../../types/Visitor'
import { useUrlFilters } from '../../data/use-url-filters'
import { GrayCard } from '../../ui/Card'
import { green } from '../../ui/colors'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { HoverCard } from '../../ui/HoverCard'
import { BroomIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import Pulse from '../../ui/Pulse'
import StatusAvatar from '../../ui/StatusAvatar'
import { TableFooter } from '../../ui/TableFooter'
import { TopBarContent } from '../../ui/TopBarContext'
import { UrlHoverCard } from '../../ui/UrlHoverCard'
import useLocation from '../../ui/useLocation'
import { useCurrentUser } from '../../ui/UserContext'
import useTitle from '../../ui/useTitle'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { accountPath } from '../accounts/lib/account-path'
import { friendlyNumber } from '../billing/v2'
import { mergeParams } from '../icps/types'

interface VisitorListProps {
  visitors: Visitor[]
  map_markers: Array<Pick<Visitor, 'id' | 'lat_lng'>>
  page_meta: PageMeta
  total_count: number
  project: Project
  perPage?: number
  hidden_accounts_enabled?: boolean
  show_hidden_accounts?: boolean
  sdk_settings: SdkSettings
  apps: Apps
}

export default function VisitorsIndex(props: VisitorListProps) {
  const [visitors, setVisitors] = React.useState<Visitor[]>(props.visitors)
  const [markers, setMarkers] = React.useState<Visitor[]>(props.map_markers)
  const [pageMeta, setPageMeta] = React.useState<PageMeta>(props.page_meta)
  const [totalCount, setTotalCount] = React.useState<number>(props.total_count)
  const subscription = React.useRef<SubscriptionEmitter | undefined>()
  const user = useCurrentUser()
  const [map, setMap] = React.useState<Map | null>(null)
  const theme = useTheme()

  const showHiddenAccounts = props.show_hidden_accounts ?? false

  const setShowHiddenAccounts = React.useCallback((showAll: boolean) => {
    if (showAll) {
      Router.visit(mergeParams(window.location.toString(), { all: 'true' }))
    } else {
      Router.visit(mergeParams(window.location.toString(), { all: '' }))
    }
  }, [])

  useTitle(`Live Visitors | Koala`)

  const facets = useUrlFilters({
    initialRange: undefined
  })
  const withLocation = React.useMemo(
    () =>
      unionBy(
        markers,
        visitors.filter((v) => Boolean(v.lat_lng)),
        'id'
      ),
    [visitors, markers]
  ) as Array<Visitor & { lat_lng: { lat: string; lng: string } }>

  const locations = React.useMemo(
    () =>
      withLocation.map((l) => {
        return {
          lat: parseFloat(l.lat_lng.lat),
          lng: parseFloat(l.lat_lng.lng)
        }
      }),
    [withLocation]
  )

  React.useEffect(() => {
    const head = document.head
    const link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css'

    head.appendChild(link)

    return () => {
      head.removeChild(link)
    }
  }, [])

  React.useEffect(() => {
    setVisitors(props.visitors)
    setMarkers(props.map_markers)
    setPageMeta(props.page_meta)
    setTotalCount(props.total_count)
  }, [props.visitors, props.map_markers, props.page_meta, props.total_count])

  const hasMapMoved = React.useRef(false)
  const mapHeight = '100vh'

  React.useEffect(() => {
    const setMoved = () => {
      hasMapMoved.current = true
    }

    map?.on('move', setMoved)

    return () => {
      map?.off('move', setMoved)
    }
  }, [map])

  // Zoom to fit markers when the map is ready
  React.useEffect(() => {
    if (!map) return
    // whenever the locations change
    // zoom to fit within the bounds
    // unless the user has moved the map
    if (locations.length && !hasMapMoved.current) {
      // Don't zoom super close to city streets
      map.fitBounds(latLngBounds(locations), { maxZoom: 6 })
    }
  }, [map, locations])

  // Only allow one fetch at a time
  const fetchingRef = React.useRef(false)

  const mounted = useMountedState()

  const refetch = React.useCallback(
    (data) => {
      // ignore self updates
      if (data?.agent?.id === user.id || fetchingRef.current || document.hidden || !document.hasFocus()) {
        return
      }

      fetchingRef.current = true

      const search = window.location.search
      const location = window.location.toString()
      concurrentGET<VisitorListProps>(
        mergeParams(location, {
          range: undefined,
          order: undefined
        })
      ).then((res) => {
        if (!mounted()) return

        fetchingRef.current = false

        // Skip updates if the search has changed while waiting for this response
        if (search !== window.location.search) {
          return
        }

        // reset to page one if page is out of range
        if (props.page_meta.current_page >= 1 && res.visitors.length === 0) {
          Router.visit(
            mergeParams(location, {
              page: '1'
            })
          )
          return
        }

        setVisitors(res.visitors)
        setMarkers(res.map_markers)
        setPageMeta(res.page_meta)
        setTotalCount(res.total_count)
      })
    },
    [user.id, props.page_meta.current_page, mounted]
  )

  React.useEffect(() => {
    if (subscription.current) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'UsersChannel',
      project_slug: props.project?.slug,
      location: 'visitors'
    })

    const update = throttle(refetch, 5000)
    subscription.current.on('received', update)

    const resub = () => {
      if (!subscription.current?.subscribed) {
        subscription.current?.subscribe()
        subscription.current?.on('received', update)
      }
    }

    const unsub = () => {
      update.cancel()
      subscription.current?.off('received', update)
      subscription.current?.unsubscribe()
    }

    // unsubscribe when the tab is hidden
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        resub()
      } else {
        unsub()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      unsub()
      subscription.current = undefined
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [props.project, refetch])

  const isFiltering = React.useMemo(() => Object.keys(facets.facetFilters).length > 0, [facets.facetFilters])

  const pulseIcon = React.useMemo(() => {
    return PulseMarker(8, theme.colors.purple['500'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.colors.purple?.['500']])

  const location = useLocation()
  const hasHiddenVisitors = totalCount !== pageMeta.total_count

  return (
    <PageLayout
      flush
      maxH="var(--content-height)"
      maxW="100vw"
      flexDirection="row"
      gap={0}
      overflow="hidden"
      bg="white"
    >
      <VStack
        flex="none"
        width="500px"
        maxWidth="100%"
        maxHeight="100%"
        overflow="auto"
        alignItems="flex-start"
        borderRight="1px"
        borderColor="gray.200"
        bg="white"
        paddingTop={8}
        paddingX={6}
        spacing={4}
      >
        <TopBarContent>
          <HStack justifyContent={'space-between'} w="100%">
            <HStack flex="1 1 auto" spacing={2.5}>
              <Pulse size={2} bg={pageMeta.total_count > 0 ? green : 'gray.300'} paused={!pageMeta.total_count} />
              <HStack spacing="1">
                <Heading size="sm" fontWeight={'semibold'}>
                  Live visitors
                </Heading>

                <HoverCard
                  // @ts-ignore this is how we disable the trigger
                  trigger={hasHiddenVisitors ? 'hover' : 'none'}
                  isPortal
                  placement="bottom-end"
                  popoverContentProps={{
                    maxW: 400,
                    minW: 200
                  }}
                  hoverContent={
                    <Stack p="2" py="4" fontSize={'sm'} spacing="3">
                      <Heading size="xs">You're seeing filtered results</Heading>
                      <Text fontSize="xs">
                        There's currently {friendlyNumber(totalCount)} {pluralize('visitor', totalCount)} live on your
                        website.
                      </Text>
                      <Text fontSize="xs">
                        But you are seeing {friendlyNumber(pageMeta.total_count)}{' '}
                        {pluralize('visitor', pageMeta.total_count)} based
                        {isFiltering && <> on your current filter</>}
                        {isFiltering && props.hidden_accounts_enabled && <> and </>}
                        {props.hidden_accounts_enabled && (
                          <>
                            {' '}
                            your workspace's{' '}
                            <Link href={projectPath('/settings/excluded-accounts')}>Hidden Accounts</Link> configuration
                          </>
                        )}
                        .
                      </Text>
                      <Stack spacing="1">
                        {isFiltering && (
                          <Button
                            size="xs"
                            fontSize={'xs'}
                            colorScheme={'gray'}
                            leftIcon={<Icon as={BroomIcon} />}
                            // variant="outline"
                            onClick={() => {
                              const path = location.pathname
                              Router.visit(path)
                            }}
                          >
                            Clear Filters
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  }
                >
                  <HStack spacing="0" fontSize={'sm'} color="gray.500" alignItems={'center'}>
                    <Text color="gray.800">({friendlyNumber(pageMeta.total_count)}</Text>
                    {hasHiddenVisitors && <Text pl="1"> of {friendlyNumber(totalCount)}</Text>}
                    <Text color="gray.800">)</Text>
                  </HStack>
                </HoverCard>
              </HStack>
            </HStack>

            {props.hidden_accounts_enabled && (hasHiddenVisitors || showHiddenAccounts) && (
              <Flex gap={2} alignItems="center" flex="none" marginLeft="auto">
                <Text fontSize="xs">Show Everyone</Text>
                <Switch
                  size="sm"
                  colorScheme="purple"
                  isChecked={showHiddenAccounts}
                  onChange={(e) => setShowHiddenAccounts(e.target.checked)}
                />
              </Flex>
            )}
          </HStack>
        </TopBarContent>

        <VStack spacing={0} flex="1 1 auto" alignItems="stretch" width="100%" divider={<Divider />}>
          {visitors.map((visitor) => (
            <Box key={visitor.id}>
              <Flex direction="column" py={3} w="100%" justifyContent="center" gap={2}>
                <HStack w="100%" spacing={3} alignItems="center" flex="1">
                  <StatusAvatar
                    size="sm"
                    name={visitor.name ?? visitor.title}
                    email={visitor.email}
                    image={visitor.image ?? visitor.company?.logo}
                    status={visitor.status}
                  />

                  <VStack spacing={'0'} flex="1" overflow="hidden" alignItems="stretch">
                    <Link
                      fontSize="sm"
                      wordBreak="break-word"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      href={projectPath(`/profiles/${visitor.id}`)}
                    >
                      {visitor.name ?? visitor.email ?? visitor.anonymous_name}
                    </Link>
                    <HStack spacing={1}>
                      {visitor.currently_viewing ? (
                        <UrlHoverCard url={visitor.currently_viewing.url}>
                          <Code
                            fontSize="xs"
                            bg="transparent"
                            paddingX={0}
                            color="gray.500"
                            flexWrap="nowrap"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {visitor.currently_viewing.path === '/'
                              ? visitor.currently_viewing.url
                              : visitor.currently_viewing.path}
                          </Code>
                        </UrlHoverCard>
                      ) : visitor.title || visitor.simple_location || (visitor.name && visitor.email) ? (
                        <Text fontSize="xs" color="gray.500">
                          {visitor.title || visitor.simple_location || visitor.email}
                        </Text>
                      ) : null}
                    </HStack>
                  </VStack>
                </HStack>
                {visitor.company && (
                  <Box marginLeft={8} paddingLeft={3}>
                    <Flex
                      as="a"
                      href={accountPath({
                        company: visitor.company
                      })}
                      display="inline-flex"
                      alignItems="center"
                      gap={1}
                      fontSize="xs"
                      fontWeight="medium"
                      paddingY={1}
                      paddingX={1}
                      marginX={-1}
                      rounded="md"
                      _hover={{
                        background: 'gray.100',
                        '& .hover-icon': {
                          opacity: 1
                        }
                      }}
                    >
                      <CompanyAvatar name={visitor.company.name} domain={visitor.company.domain} size="20px" />
                      <Text>{visitor.company.name || visitor.company.domain}</Text>
                      <Icon className="hover-icon" opacity={0} as={IconArrowRight} boxSize={4} color="gray.600" />
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Box>
          ))}
        </VStack>

        {pageMeta.total_count > 0 && (
          <TableFooter
            word="person"
            width="100%"
            pageMeta={pageMeta}
            page={pageMeta.current_page || ''}
            prevPath={`${projectPath('/live')}?order=${pageMeta?.order ?? 'natural'}&page=${pageMeta?.prev_page}`}
            nextPath={`${projectPath('/live')}?order=${pageMeta?.order ?? 'natural'}&page=${pageMeta?.next_page}`}
            sticky
          />
        )}
      </VStack>

      <Box flex="1" overflow="hidden" height="100%" opacity={visitors.length ? undefined : 0.5}>
        <GrayCard
          borderBottomWidth={'1px'}
          position="absolute"
          zIndex={1001}
          w="100%"
          rounded="none"
          maxW={'calc(100vw - 500px)'}
        >
          <FilterPreview
            facetValuesPath={'/live/facet-values'}
            kind="profile"
            {...facets}
            range={undefined}
            apps={props.apps}
            canClearFilters={false}
            shouldShowListFilters
          >
            <Button size="sm" variant="outline" leftIcon={<IconPlus size="14" />} colorScheme="lightPurple">
              Add Filter
            </Button>
          </FilterPreview>
        </GrayCard>
        <MapContainer
          center={[30, 0]}
          bounds={locations.length ? latLngBounds(locations) : undefined}
          zoom={2}
          minZoom={2}
          maxZoom={10}
          style={{ height: mapHeight }}
          whenCreated={setMap}
        >
          {withLocation.map((visitor) => (
            <Marker
              key={visitor.id}
              icon={pulseIcon}
              interactive={false}
              position={{
                lat: parseFloat(visitor.lat_lng.lat),
                lng: parseFloat(visitor.lat_lng.lng)
              }}
            />
          ))}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </Box>
    </PageLayout>
  )
}

function PulseMarker(radius, color) {
  const cssStyle = `
    width: ${radius}px;
    height: ${radius}px;
    background: ${color};
    border-radius: 50%;
    color: ${color};
    box-shadow: 0 0 0 ${color};
    animation: shadow-pulse 3s infinite;
  `

  return L.divIcon({
    html: `<div style="${cssStyle}" class="marker-pulse" />`,
    // empty class name to prevent the default leaflet-div-icon to apply
    className: ''
  })
}
