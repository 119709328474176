import {
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { IconArchive, IconClick, IconDotsVertical, IconInfoCircle, IconUsers } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { SearchBar } from '../accounts/facets/search-bar'
import { eventList } from './path-helpers'

export interface EventEntry {
  value: string
  count: number
}

interface Props {
  events: EventEntry[]
  settings: Record<string, { ignored: boolean }>
}

export default function Reports(props: Props) {
  const [filter, setFilter] = useState('')
  const [tab, setTab] = useState<'active' | 'archived'>('active')
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState(props.settings)
  const events = props.events

  const filtered = useMemo(() => {
    if (filter === '') {
      return events
    }

    return events.filter((event) => event.value.toLowerCase().includes(filter.toLowerCase()))
  }, [filter, events])

  const active = useMemo(() => filtered.filter((k) => !settings[k.value]?.ignored), [filtered, settings])
  const archived = useMemo(() => filtered.filter((k) => !!settings[k.value]?.ignored), [filtered, settings])

  const toggleEvent = useCallback(
    async (e: string) => {
      const ignored = settings[e]?.ignored

      try {
        setLoading(true)
        await post<Props>(projectPath(`/events/${ignored ? 'restore' : 'ignore'}?name=${e}&model=Event`)).then(
          (res) => {
            setSettings(res.settings)
          }
        )

        toast.success(`Successfully ${ignored ? 'restored' : 'ignored'} event!`)
      } catch (_error) {
        toast.error(`Failed to ${ignored ? 'restore' : 'ignore'} event!`)
      } finally {
        setLoading(false)
      }
    },
    [settings]
  )

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb rootPath={{ path: projectPath('/events'), title: 'Events' }} offscreen />

      <SettingsHeader borderBottomWidth={0} paddingBottom={0}>
        <HStack w="100%" justifyContent={'space-between'}>
          <Stack flex="3" w="100%">
            <HStack>
              <IconClick />
              <PageTitle>Events</PageTitle>
            </HStack>
            <PageDescription>Manage and view events.</PageDescription>
          </Stack>
          <Flex w="12">{loading && <Spinner />}</Flex>
          <Flex flex="1">
            <SearchBar size="sm" value={filter} onChange={(val) => setFilter(val)} />
          </Flex>
        </HStack>

        <Tabs size="sm" marginTop={6}>
          <TabList>
            <Tab isSelected={tab === 'active'} onClick={() => setTab('active')}>
              Tracked ({active.length})
            </Tab>
            <Tab isSelected={tab === 'archived'} onClick={() => setTab('archived')}>
              Ignored ({archived.length})
            </Tab>
          </TabList>
        </Tabs>
      </SettingsHeader>

      {props.events.length === 0 && <Text>No events for the selected time period.</Text>}

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>
                <HStack>
                  <Text>Event</Text>
                  <Tooltip label="These are events that are tracked in the Koala pixel.">
                    <IconInfoCircle size={14} />
                  </Tooltip>
                </HStack>
              </Th>
              <Th isNumeric>
                <Tooltip label="The number of times Koala ingested this event">
                  <HStack justifyContent={'flex-end'}>
                    <Text>Stats</Text>
                    <IconInfoCircle size={14} />
                  </HStack>
                </Tooltip>
              </Th>
              <Th isNumeric>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(tab === 'active' ? active : archived).map((event) => (
              <Tr
                key={event.value}
                _hover={{
                  bg: 'gray.50'
                }}
              >
                <Td>{event.value}</Td>
                <Td isNumeric>{event.count}</Td>
                <Td isNumeric>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Actions"
                      icon={<IconDotsVertical size={16} />}
                      variant="ghost"
                      size="xs"
                    />
                    <MenuList>
                      <MenuItem
                        icon={<IconArchive size={16} />}
                        onClick={() => {
                          toggleEvent(event.value)
                        }}
                        _focus={{
                          boxShadow: 'unset',
                          background: 'gray.100'
                        }}
                      >
                        {settings[event.value]?.ignored ? 'Enable' : 'Ignore'}
                      </MenuItem>
                      <MenuDivider />

                      <MenuItem
                        icon={<IconUsers size={16} />}
                        as={Link}
                        href={eventList('visitors', `[uniq_events][]=${event.value}`, 'month')}
                        _focus={{
                          boxShadow: 'unset',
                          background: 'gray.100'
                        }}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <Text>View Submissions</Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </PageLayout>
  )
}
