import * as React from 'react'

export interface AlternateEmail {
  id: string
  email: string
  confirmed: Boolean
}

export interface User {
  created_at: string
  email: string
  alternate_emails: AlternateEmail[]
  name: string
  first_name?: string
  // only set if connected via google
  provider?: string
  // only set if connected via google
  avatar?: string
  image?: string
  id: string
  status?: 'online' | 'offline' | 'away'
  updated_at: string
  role: 'admin' | 'member' | 'read_only' | 'developer'
  project_ids?: string[]
}

declare global {
  interface Window {
    currentUser?: User
  }
}

export const UserContext = React.createContext({
  id: window.currentUser?.id,
  email: window.currentUser?.email,
  name: window.currentUser?.name,
  firstName: window.currentUser?.first_name,
  image: window.currentUser?.image,
  alternate_emails: window.currentUser?.alternate_emails,
  project_ids: window.currentUser?.project_ids,
  role: window.currentUser?.role
})

export function useCurrentUser() {
  const user = React.useContext(UserContext)
  const currentUser = React.useMemo(() => {
    const alternateEmails = user.alternate_emails
      ? user.alternate_emails.map((e) => e.confirmed && e.email).filter((e) => !!e)
      : []

    return {
      ...user,
      isInternalUser: ['getkoala.com'].includes(user.email?.split('@')[1] ?? 'unknown'),
      emails: (user.email ? [user.email] : []).concat(alternateEmails).flat()
    }
  }, [user])

  return currentUser
}
