import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  OrderedList,
  Stack,
  Textarea,
  UnorderedList,
  Code,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { Toggle } from '../../accounts/components/Toggle'

interface Props {
  app_id: string
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  secrets: string[]
  connected?: boolean
  deps: {}
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [isChangingPrivateKey, setIsChangingPrivateKey] = useState(false)

  const [accountUrl, setAccountUrl] = useState(props.settings['account_url'] ?? '')
  const [account, setAccount] = useState(props.settings['account'] ?? '')
  const [organization, setOrganization] = useState(props.settings['organization'] ?? '')

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/snowflake'),
            title: 'Snowflake'
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>
        <Badge colorScheme={'purple'}>ALPHA</Badge>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'Snowflake'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4" pt="8">
          <Heading size="sm" fontWeight={'semibold'}>
            🔐 Auth Settings
          </Heading>

          <Stack spacing="8">
            <LightBgCard as={Stack}>
              <Stack spacing={'4'}>
                <FormControl id="account_url">
                  <FormLabel>Account URL</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[account_url]"
                    placeholder="https://account-organization.snowflakecomputing.com"
                    type="url"
                    required
                    value={accountUrl}
                    onChange={(e) => {
                      setAccountUrl(e.target.value)
                      const url = new URL(e.target.value)
                      const org = url.hostname.split('.')[0]

                      const urlAccount = org?.split('-')[0]
                      const urlOrganization = org?.split('-')[1]

                      if (account === '' || !account) {
                        setAccount(urlAccount)
                      }

                      if (organization === '' || !organization) {
                        setOrganization(urlOrganization)
                      }
                    }}
                  />
                  <FormHelperText>
                    Your Snowflake Account URL. e.g. https://account-organization.snowflakecomputing.com
                  </FormHelperText>
                </FormControl>

                <FormControl id="account">
                  <FormLabel>Account</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[account]"
                    placeholder="Snowflake Account"
                    required
                    value={account}
                    onChange={(e) => {
                      setAccount(e.target.value)
                    }}
                  />
                  <FormHelperText>
                    Your Snowflake account name. This is typically the first part of your account URL before the `-`.
                    e.g.
                  </FormHelperText>
                </FormControl>

                <FormControl id="organization">
                  <FormLabel>Organization</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[organization]"
                    placeholder="Snowflake Organization"
                    value={organization}
                    onChange={(e) => {
                      setOrganization(e.target.value)
                    }}
                  />
                  <FormHelperText>
                    Your Snowflake organization name (if applicable). This is typically the second part of your account
                    URL after the `-`.
                  </FormHelperText>
                </FormControl>

                <FormControl id="username">
                  <FormLabel>Username</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[username]"
                    placeholder="Snowflake Username"
                    defaultValue={props.settings['username'] ?? ''}
                    required
                  />
                </FormControl>

                <FormControl id="warehouse">
                  <FormLabel>Warehouse</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[warehouse]"
                    placeholder="Snowflake Warehouse"
                    defaultValue={props.settings['warehouse'] ?? ''}
                    required
                  />
                </FormControl>

                <FormControl id="database">
                  <FormLabel>Database</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[database]"
                    placeholder="Snowflake Database"
                    defaultValue={props.settings['database'] ?? ''}
                    required
                  />
                </FormControl>

                <FormControl id="private_key">
                  <FormLabel>Private Key</FormLabel>
                  {!props.secrets.includes('private_key') || isChangingPrivateKey ? (
                    <Textarea
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[private_key]"
                      placeholder="Snowflake Private Key"
                      defaultValue={props.settings['private_key'] ?? ''}
                      rows={16}
                      required
                    />
                  ) : (
                    <HStack>
                      <Input bg="gray.50" w="100%" type="password" isReadOnly value={'***********************'} />
                      <Button
                        variant={'ghost'}
                        colorScheme="purple"
                        size="sm"
                        onClick={() => setIsChangingPrivateKey(true)}
                      >
                        Change
                      </Button>
                    </HStack>
                  )}
                  <FormHelperText>Your Snowflake private key for authentication.</FormHelperText>
                </FormControl>
              </Stack>
            </LightBgCard>

            <Divider />

            <Stack spacing="4">
              <Toggle
                title={
                  <Heading size="sm" fontWeight={'semibold'}>
                    How to set up Snowflake for Koala
                  </Heading>
                }
              >
                <OrderedList fontSize={'sm'} px="4" spacing="4">
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Generate an RSA key pair:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Open a terminal and run:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl genrsa 2048 | openssl pkcs8 -topk8 -nocrypt -inform PEM -out rsa_key.p8
                        </Code>
                      </li>
                      <li>
                        Generate the public key:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -in rsa_key.p8 -pubout -out rsa_key.pub
                        </Code>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Assign the public key to your Snowflake user:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>Log in to your Snowflake account with admin privileges</li>
                      <li>
                        Copy the contents of the public key file (rsa_key.pub), excluding the "BEGIN PUBLIC KEY" and
                        "END PUBLIC KEY" lines
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -pubin -in rsa_key.pub -outform DER | openssl base64 -A
                        </Code>
                      </li>
                      <li>
                        Execute the following SQL command in Snowflake:{' '}
                        <Code p="4" my="2" display={'block'}>
                          ALTER USER your_username SET RSA_PUBLIC_KEY='your_public_key_content';
                        </Code>
                        <Text fontSize={'sm'}>
                          <strong>Important:</strong> Snowflake does not like the % character in the public key. If you
                          have a # in your key, remove it before pasting it in.
                        </Text>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Enter the required information in the form above:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Account URL: Your Snowflake account URL (e.g., https://your_account.snowflakecomputing.com)
                      </li>
                      <li>Account: Your Snowflake account name</li>
                      <li>Username: Your Snowflake username</li>
                      <li>Warehouse: The default warehouse to use</li>
                      <li>Database: The default database to use</li>
                      <li>Organization: Your Snowflake organization name (if applicable)</li>
                      <li>
                        Private Key: The contents of your private key file (rsa_key.p8)
                        <UnorderedList spacing={'2'} mt={'2'}>
                          <li>Open the rsa_key.p8 file in a text editor</li>
                          <li>
                            Copy the entire contents, including the "BEGIN PRIVATE KEY" and "END PRIVATE KEY" lines
                          </li>
                          <li>Paste the copied content into the Private Key field in Koala</li>
                          <li>
                            Alternatively, if you're using macOS, you can use the following command to copy the content
                            directly to your clipboard:{' '}
                            <Code p="4" my="2" display={'block'}>
                              pbcopy &lt; rsa_key.p8
                            </Code>
                          </li>
                        </UnorderedList>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Click "Save" to store your Snowflake credentials securely in Koala
                    </Text>
                  </li>
                </OrderedList>
              </Toggle>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing="8" mt="8">
          <HStack w="100%">
            <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
              Save
            </Button>
          </HStack>
        </Stack>
      </form>
    </PageLayout>
  )
}
