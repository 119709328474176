import { Box, Button, Heading, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconList } from '@tabler/icons-react'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import React, { useState } from 'react'
import type { PageMeta } from '../../../types/PageMeta'
import type { Company } from '../../../types/Profile'
import { UrlFilterParams, useUrlFilters } from '../../data/use-url-filters'
import { BulkActionBar } from '../../ui/BulkActionBar'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { BuildingIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { CustomTable } from '../../ui/Table'
import { TableFooter } from '../../ui/TableFooter'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { AddToListModal } from '../lists/components/AddToListModal'

const columns: ColumnDef<Company>[] = [
  {
    id: 'company',
    accessorFn: (row) => row.name || row.domain,
    size: 300,
    header: 'Company',
    cell: ({ row, getValue }) => {
      if (!getValue()) {
        return null
      }

      return (
        <HStack spacing={2} isTruncated>
          <CompanyAvatar size="xs" name={row.original.name} domain={row.original.domain} />

          <Text fontSize="15px" minWidth="100px" lineHeight="1.2" fontWeight="semibold" isTruncated>
            {row.original.name || row.original.domain}
          </Text>
        </HStack>
      )
    }
  },
  {
    id: 'category.industry',
    accessorKey: 'category.industry',
    header: 'Industry',
    cell: ({ getValue }) => <Box isTruncated>{getValue()}</Box>
  },
  {
    id: 'metrics.employeesRange',
    accessorKey: 'metrics.employeesRange',
    header: 'Employees',
    size: 100
  },
  {
    id: 'metrics.estimatedAnnualRevenue',
    accessorKey: 'metrics.estimatedAnnualRevenue',
    header: 'Est. Revenue',
    size: 100
  },
  {
    id: 'geo',
    accessorKey: 'geo',
    header: 'Location',
    cell: ({ getValue }) => {
      const geo = getValue() as Company['geo']
      return [geo?.state, geo?.country].filter(Boolean).join(', ')
    }
  }
]

interface Props {
  companies: Company[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const facets: UrlFilterParams = useUrlFilters({
    initialRange: null,
    facetCloudPath: '/companies/facet-cloud'
  })

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const addToList = useDisclosure()

  const selections = Object.entries(selectedRows)
    .filter(([_id, selected]) => selected)
    .map(([id]) => id)

  return (
    <PageLayout size="full">
      <PageTitle skipRendering>Companies</PageTitle>

      <Stack>
        <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
          <HStack spacing={3}>
            <BuildingIcon boxSize={5} />
            <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
              Companies{'  '}
              <Box whiteSpace="nowrap">
                <Text as="span">{props.page_meta.total_count.toLocaleString()}</Text>
              </Box>
            </Heading>
          </HStack>
        </HStack>

        <FilterPreview
          {...facets}
          range={undefined}
          kind="account"
          facetValuesPath="/companies/facet-values"
          excludedKeys={['claimed', 'claimed_by']}
          shouldShowICPFilters={false}
          shouldShowIntentFilters={false}
          shouldShow3rdPartyFilters={false}
          shouldShowStaticListFilters={false}
          shouldShowUserAttributeFilters={false}
          shouldShowActiveVisitorsFilters={false}
        />
      </Stack>

      <Box>
        <CustomTable
          data={props.companies}
          columns={columns}
          stickyFirstColumn
          enableRowSelection
          selectedRows={selectedRows}
          onRowSelectionChange={setSelectedRows}
        />
        <TableFooter
          word="company"
          pageMeta={props.page_meta}
          page={(facets.page ?? 1) as number}
          setPage={facets.setPage}
          sticky
        />
      </Box>

      <BulkActionBar selectionCount={selections.length ?? 0} onRemoveSelection={() => setSelectedRows({})}>
        <Button
          variant="outline"
          size="sm"
          onClick={addToList.onOpen}
          leftIcon={<IconList size={16} />}
          iconSpacing={1.5}
        >
          Add to List
        </Button>
      </BulkActionBar>
      <AddToListModal recordIds={selections} kind="account" {...addToList} />
    </PageLayout>
  )
}
