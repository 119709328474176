import React from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { Badge, Box, HStack, Image } from '@chakra-ui/react'
import { Introduction } from '../components/Introduction'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {}
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/linkedin_xt'),
            title: 'Chrome Extension for LinkedIn'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            </HStack>
            {props.connected && <DisconnectAppDialog appTitle="LinkedIn" showRemoveCachesOption={false} />}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      <Introduction
        app="LinkedIn"
        icon={props.logo}
        description="Use our Chrome Extension to track reactions and comments on LinkedIn posts. Reach out to support@getkoala.com to request access."
      />
    </PageLayout>
  )
}
