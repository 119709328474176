import { Divider, FormControl, FormHelperText, Stack, Switch } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { SalesforceFieldMapper, SalesforceFieldMapping } from './salesforce-field-mapper'
import { SalesforceActionProps } from './salesforce-setup'
import { nanoid } from 'nanoid'

export function ImportAccount(props: SalesforceActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.salesforce?.import_account?.enabled))
  const sfFieldNames = useMemo(() => props.deps.account_layout.map((f) => f.name), [props.deps.account_layout])

  const isPersisted = useMemo(
    () => !!props.delivery_rules?.salesforce?.import_account?.fields?.length,
    [props.delivery_rules?.salesforce?.import_account]
  )

  const suggestions: SalesforceFieldMapping[] = useMemo(() => {
    if (props.suggestedMappings) {
      return props.suggestedMappings
    }

    return [
      {
        id: nanoid(),
        koala: 'account.company.name',
        salesforce: 'Name'
      },
      {
        id: nanoid(),
        koala: 'account.company.domain',
        salesforce: 'Website'
      },
      {
        id: nanoid(),
        koala: 'account.company.category.industry',
        salesforce: 'Industry'
      },
      {
        id: nanoid(),
        koala: 'account.company.description',
        salesforce: 'Description'
      },
      {
        id: nanoid(),
        koala: 'account.company.geo.city',
        salesforce: 'BillingCity'
      },
      {
        id: nanoid(),
        koala: 'account.company.geo.state',
        salesforce: 'BillingState'
      },
      {
        id: nanoid(),
        koala: 'account.company.geo.country',
        salesforce: 'BillingCountry'
      },
      {
        id: nanoid(),
        koala: 'account.company.metrics.employees',
        salesforce: 'Employees'
      },
      {
        id: nanoid(),
        koala: 'account.company.metrics.annualRevenue',
        salesforce: 'AnnualRevenue'
      }
    ].filter((s) => s.salesforce && sfFieldNames.includes(s.salesforce))
  }, [sfFieldNames, props.suggestedMappings])

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                import_account: {
                  ...props.delivery_rules?.salesforce?.import_account,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][import_account][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>
          Import the current Account as an Account in Salesforce if it doesn't already exist or update the fields if it
          does.
        </FormHelperText>
      </FormControl>

      {enabled && props.actionSchema && (
        <Stack spacing={'8'}>
          <Divider />
          <SalesforceFieldMapper
            actionsSchema={props.actionSchema}
            namespace="follow_rule[delivery_rules][salesforce][import_account]"
            type="account"
            mappings={props.delivery_rules?.salesforce?.import_account?.fields ?? (isPersisted ? [] : suggestions)}
            suggestions={suggestions}
            deps={props.deps}
            refetchDeps={props.refetchDeps}
            loadingDeps={props.loadingDeps}
            updateSettingEnabled={true}
          />
        </Stack>
      )}
    </Stack>
  )
}
