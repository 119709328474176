import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { SlackChannel } from '../../../../types/Slack'
import { useAppDep } from '../../../data/use-app-dep'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { Card, LightBgCard } from '../../../ui/Card'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import PageDescription from '../../../ui/PageDescription'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { ChannelPicker } from '../../account_view_subscriptions/components/Slack'
import { Introduction } from '../components/Introduction'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  deps: {
    channels: SlackChannel[]
  }
  logo: string
  valid?: boolean
  connected?: boolean
  settings?: {
    include_shared_channels: boolean
    track_channels: 'all' | 'selected'
    signal_joined_channel?: boolean
    signal_first_message?: boolean
    tracked_channels?: SlackChannel[] | null
  }
  connection_details: ConnectionDetail[]
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({
    on: 'project',
    action: 'can_edit'
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [trackChannels, setTrackChannels] = useState(props.settings?.track_channels ?? 'all')
  const [isAddingChannel, setIsAddingChannel] = useState(false)
  const [trackedChannels, setTrackedChannels] = useState(props.settings?.tracked_channels ?? [])
  const [newChannel, setNewChannel] = useState<SlackChannel | null>(null)

  const {
    refetch: refetchChannels,
    isLoading: isLoadingChannels,
    data: channelData
  } = useAppDep<'uncached_channel_deps', SlackChannel[]>(
    'SlackCommunity',
    'uncached_channel_deps',
    {},
    // do not cache
    false,
    // do not fetch on mount, only when called explicitly
    false
  )

  const channels = useMemo(() => {
    if (channelData?.data.uncached_channel_deps) {
      return channelData.data.uncached_channel_deps
    }

    return props.deps.channels
  }, [channelData, props.deps.channels])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/slack-community'),
            title: 'Slack Community'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack w="100%">
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>

        {props.connected && <DisconnectAppDialog appTitle={'Slack Community'} showRemoveCachesOption={false} />}
      </HStack>

      <Introduction
        app="slack"
        icon={props.logo}
        description="The Koala App for Slack Communities helps you track sales signals in your Slack communities."
        docsLink="https://getkoala.com/docs/integrations/slack-community"
      />

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} allowNonAdmins />
        </LightBgCard>
      )}

      {props.connected && (
        <Stack spacing="12">
          <ConnectionDetails appTitle={'Slack Community'} valid={props.valid} details={props.connection_details} />

          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault()
              setIsSubmitting(true)
              e.currentTarget.submit()
            }}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="PUT" />

            <Stack as={Card} spacing="8">
              <Heading size="sm">Signal Settings</Heading>

              <FormControl>
                <HStack>
                  <input type="hidden" name="app_instance_settings[include_shared_channels]" value="false" />
                  <Switch
                    id="slack_connect"
                    name="app_instance_settings[include_shared_channels]"
                    defaultChecked={
                      props.settings?.include_shared_channels !== undefined
                        ? props.settings?.include_shared_channels
                        : false
                    }
                    value="true"
                  />
                  <FormLabel htmlFor="slack_connect">Include Shared Slack Connect Channels</FormLabel>
                </HStack>
                <FormHelperText>
                  When enabled, Koala will include channels that are shared with your Slack Connect account.
                </FormHelperText>
              </FormControl>

              <Divider />

              <FormControl>
                <HStack pb="2">
                  <FormLabel pt="0" m="0">
                    Tracked Channels
                  </FormLabel>
                  <HelpTooltip>
                    Choose between tracking all channels in your Slack community or only selected channels.
                  </HelpTooltip>
                </HStack>

                <RadioGroup
                  defaultValue={trackChannels}
                  name="app_instance_settings[track_channels]"
                  onChange={(e) => setTrackChannels(e as 'all' | 'selected')}
                >
                  <Stack spacing="1">
                    <Radio value="all">
                      <Text fontSize="sm">All public channels</Text>
                    </Radio>
                    <Radio value="selected">
                      <Text fontSize="sm">Selected channels only</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>

                {trackChannels === 'selected' && (
                  <Stack py="4" spacing="4">
                    {trackedChannels.length === 0 && (
                      <>
                        <input type="hidden" name="app_instance_settings[tracked_channels]" value="null" />
                      </>
                    )}
                    <Card py="3" px="6">
                      <Stack spacing="2">
                        {trackedChannels.map((channel) => (
                          <HStack key={channel.id} w="100%" justify="space-between">
                            <Text fontSize={'sm'} key={channel.id}>
                              #{channel.name}
                            </Text>
                            <Tooltip label={`Remove channel #${channel.name}`}>
                              <IconButton
                                size="xs"
                                variant="ghost"
                                aria-label="Remove channel"
                                icon={<IconX size="12" />}
                                onClick={() => {
                                  setTrackedChannels(trackedChannels.filter((c) => c.id !== channel.id))
                                }}
                              />
                            </Tooltip>
                            <input
                              type="hidden"
                              name="app_instance_settings[tracked_channels][][id]"
                              value={channel.id}
                            />
                            <input
                              type="hidden"
                              name="app_instance_settings[tracked_channels][][name]"
                              value={channel.name}
                            />
                          </HStack>
                        ))}
                      </Stack>

                      {isAddingChannel && (
                        <HStack py="4">
                          <ChannelPicker
                            channels={channels}
                            isNewChannelEntered={false}
                            allowCreateNewChannel={false}
                            setChannel={(channel) => {
                              if (channel && !channel.refreshChannels) {
                                setNewChannel(channel)
                              } else if (channel && channel.refreshChannels) {
                                setNewChannel(null)
                                refetchChannels()
                              } else {
                                setNewChannel(null)
                              }
                            }}
                          />
                          <Button
                            size="sm"
                            isLoading={isLoadingChannels}
                            onClick={() => {
                              if (newChannel) {
                                setTrackedChannels([...trackedChannels, newChannel])
                              }
                              setNewChannel(null)
                              setIsAddingChannel(false)
                            }}
                            colorScheme="purple"
                            variant={'ghost'}
                            isDisabled={!newChannel}
                          >
                            Confirm
                          </Button>
                          <Button
                            size="sm"
                            onClick={() => setIsAddingChannel(false)}
                            colorScheme="gray"
                            color="gray.500"
                            variant={'ghost'}
                          >
                            Cancel
                          </Button>
                        </HStack>
                      )}
                    </Card>

                    <Button
                      disabled={isAddingChannel}
                      size="sm"
                      variant="ghost"
                      colorScheme="purple"
                      onClick={() => {
                        setIsAddingChannel(true)
                        setNewChannel(null)
                      }}
                    >
                      Add Channel
                    </Button>
                  </Stack>
                )}

                <FormHelperText>
                  *Note: Koala can only track private channels that it has access to. If you want to track a private,
                  you must invite the Koala Community bot to the channel first.
                </FormHelperText>
              </FormControl>

              <Divider />

              <FormControl>
                <FormLabel>Signals</FormLabel>
                <input type="hidden" name="app_instance_settings[signal_joined_channel]" value="false" />
                <input type="hidden" name="app_instance_settings[signal_first_message]" value="false" />

                <Stack>
                  <Checkbox
                    name="app_instance_settings[signal_joined_channel]"
                    defaultChecked={
                      props.settings?.signal_joined_channel !== undefined ? props.settings?.signal_joined_channel : true
                    }
                    value="true"
                  >
                    User Joined Channel
                  </Checkbox>
                  <Checkbox
                    name="app_instance_settings[signal_first_message]"
                    defaultChecked={
                      props.settings?.signal_first_message !== undefined ? props.settings?.signal_first_message : true
                    }
                    value="true"
                  >
                    User Posted Message for the first time
                  </Checkbox>
                </Stack>
                <FormHelperText>
                  Select which signals you want to track. Koala will automatically issue a track event every time one of
                  these signals fire.
                </FormHelperText>
              </FormControl>

              <Divider />

              <Button colorScheme="purple" type="submit" w="100%" isDisabled={isSubmitting || !canEditProject}>
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
    </PageLayout>
  )
}
