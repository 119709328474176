import { Input, InputGroup, InputGroupProps, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import debounce from 'lodash/debounce'
import React from 'react'
import { SearchIcon } from '../../../ui/icons/SearchIcon'

interface Props {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  debounce?: number
  size?: InputGroupProps['size']
  inputProps?: InputProps
  searchIcon?: React.ReactNode
}

export const SearchBar = (props: Props) => {
  const [val, setVal] = React.useState(props.value ?? '')

  React.useEffect(() => {
    if (props.value) {
      setVal(props.value)
    }
  }, [props.value])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = React.useCallback(
    debounce((value: string) => {
      props.onChange?.(value)
    }, props.debounce ?? 0),
    [props.onChange, props.debounce]
  )

  return (
    <InputGroup size={props.size}>
      <InputLeftElement color="gray.500" cursor={val === '' ? 'pointer' : 'default'}>
        {props.searchIcon ?? <SearchIcon size={16} />}
      </InputLeftElement>
      <Input
        bg="white"
        rounded="md"
        size={props.size}
        placeholder={props.placeholder ?? 'Search...'}
        value={val}
        onChange={(e) => {
          setVal(e.target.value)
          debounced(e.target.value)
        }}
        {...props.inputProps}
      />
      {val !== '' && (
        <InputRightElement
          cursor="pointer"
          color="gray.500"
          onClick={() => {
            props.onChange?.('')
            setVal('')
          }}
        >
          <IconX size={14} />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
