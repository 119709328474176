import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Stack,
  Wrap
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconWebhook } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Iconify } from '../../../ui/Iconify'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../../ui/icons'
import { humanize } from '../../accounts/facets/filter-cloud'
import { FollowRule } from '../../notifications'
import { ApolloSetup } from './apollo/apollo-setup'
import { EmailSetup } from './email/email-setup'
import { HubspotSetup } from './hubspot/hubspot-setup'
import { InstantlySetup } from './instantly/instantly-setup'
import { StepCard } from './notification-rule-form'
import { OutreachSetup } from './outreach/outreach-setup'
import { SalesforceSetup } from './salesforce/salesforce-setup'
import { SlackSetup } from './slack/slack-setup'
import { WebhookSetup } from './webhook/webhook-setup'

interface Props {
  follow_rule?: FollowRule
  targetType: 'Account' | 'Profile'
}

const defaultRules = {
  slack: {
    trigger: 'immediate'
  },
  email: {
    trigger: 'immediate'
  },
  webhook: {
    trigger: 'immediate'
  },
  hubspot: {
    trigger: 'immediate'
  },
  salesforce: {},
  outreach: {},
  apollo: {},
  instantly: {}
}

export const channelLogos = {
  slack: 'https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg',
  email: 'https://cdn.worldvectorlogo.com/logos/mail-ios.svg',
  webhook: IconWebhook,
  hubspot: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg',
  salesforce: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg',
  outreach: 'https://www.outreach.io/_resources/img/logomark_sm.min.svg',
  apollo: 'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png',
  instantly: 'https://cdn.prod.website-files.com/63860c8c65e7bef4a1eeebeb/63f62e4d1df86f1bf7f133d5_cleaned_rounded.png'
}

type Channel = keyof typeof defaultRules

interface RuleProps {
  deliveryRules: FollowRule['delivery_rules']
  setDeliveryRules: React.Dispatch<React.SetStateAction<FollowRule['delivery_rules']>>
  children: React.ReactNode
  title: string
  icon: string | TablerIcon
  channel: Channel
}

function DeliveryRule(props: RuleProps) {
  const deliveryRules = props.deliveryRules
  const setDeliveryRules = props.setDeliveryRules
  const channel = props.channel

  const logo = typeof props.icon === 'string' ? <Image src={props.icon} w="6" /> : <Icon as={props.icon} boxSize={6} />

  return (
    <StepCard wrap title={props.title} logo={logo}>
      <Stack
        w="100%"
        aria-disabled={!deliveryRules?.[channel]}
        _disabled={{
          opacity: 0.4,
          pointerEvents: 'none'
        }}
      >
        {props.children}
      </Stack>
    </StepCard>
  )
}

export function DeliverySetup(props: Props) {
  const [deliveryRules, setDeliveryRules] = useState<FollowRule['delivery_rules']>(props.follow_rule?.delivery_rules)
  const channels = Object.keys(defaultRules) as Channel[]

  const [selectedChannels, setSelectedChannels] = useState<Channel[]>(
    Object.keys(props.follow_rule?.delivery_rules ?? {}) as Channel[]
  )

  return (
    <FormControl>
      <FormLabel>Deliver to</FormLabel>

      <Wrap gap="2" pt="0" pb="8">
        {channels.map((channel) => {
          const isActive = deliveryRules?.[channel] !== undefined
          return (
            <Button
              key={channel}
              variant="outline"
              leftIcon={<Iconify icon={channelLogos[channel]} size={16} />}
              rightIcon={
                isActive ? (
                  <Icon flex="none" as={CheckboxCircleIcon} boxSize="18px" color={'purple.600'} />
                ) : (
                  <Icon flex="none" as={CheckboxBlankCircleIcon} boxSize="18px" color={'gray.300'} />
                )
              }
              borderColor={isActive ? 'purple.500' : 'gray.300'}
              bg={isActive ? 'white' : undefined}
              onClick={() => {
                if (isActive) {
                  setSelectedChannels(selectedChannels.filter((c) => c !== channel))
                  setDeliveryRules({
                    ...deliveryRules,
                    [channel]: undefined
                  })
                } else {
                  setSelectedChannels((channels) => {
                    return [...channels, channel]
                  })

                  setDeliveryRules({
                    ...deliveryRules,
                    [channel]: deliveryRules?.[channel] ?? defaultRules[channel]
                  })
                }
              }}
            >
              {humanize(channel)}
            </Button>
          )
        })}
      </Wrap>

      <Box>
        {!!(selectedChannels.includes('slack') && deliveryRules?.slack) && (
          <Box py={4}>
            <DeliveryRule
              channel="slack"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Slack'}
              icon={channelLogos.slack}
            >
              <SlackSetup
                targetType={props.targetType}
                delivery_rules={deliveryRules}
                follow_rule={props.follow_rule}
              />
            </DeliveryRule>
          </Box>
        )}

        <Collapse in={!!(selectedChannels.includes('email') && deliveryRules?.email)}>
          <Box py={4}>
            <DeliveryRule
              channel="email"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Email'}
              icon={channelLogos.email}
            >
              <EmailSetup delivery_rules={deliveryRules} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('webhook') && deliveryRules?.webhook)}>
          <Box py={4}>
            <DeliveryRule
              channel="webhook"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Webhook'}
              icon={channelLogos.webhook}
            >
              <WebhookSetup delivery_rules={deliveryRules} targetType={props.targetType} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('hubspot') && deliveryRules?.hubspot)}>
          <Box py={4}>
            <DeliveryRule
              channel="hubspot"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'HubSpot'}
              icon={channelLogos.hubspot}
            >
              <HubspotSetup delivery_rules={deliveryRules} targetType={props.targetType} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('salesforce') && deliveryRules?.salesforce)}>
          <Box py={4}>
            <DeliveryRule
              channel="salesforce"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Salesforce'}
              icon={channelLogos.salesforce}
            >
              <SalesforceSetup delivery_rules={deliveryRules} targetType={props.targetType} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('outreach') && deliveryRules?.outreach)}>
          <Box py={4}>
            <DeliveryRule
              channel="outreach"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Outreach'}
              icon={channelLogos.outreach}
            >
              <OutreachSetup delivery_rules={deliveryRules} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('apollo') && deliveryRules?.apollo)}>
          <Box py={4}>
            <DeliveryRule
              channel="apollo"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Apollo'}
              icon={channelLogos.apollo}
            >
              <ApolloSetup delivery_rules={deliveryRules} />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse in={!!(selectedChannels.includes('instantly') && deliveryRules?.instantly)}>
          <Box py={4}>
            <DeliveryRule
              channel="instantly"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Instantly'}
              icon={channelLogos.instantly}
            >
              <InstantlySetup delivery_rules={deliveryRules} />
            </DeliveryRule>
          </Box>
        </Collapse>
      </Box>
    </FormControl>
  )
}
