import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconArrowUpRight,
  IconCheck,
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconExternalLink,
  IconLock,
  IconPlayerPlay,
  IconPlus,
  IconRefresh,
  IconTrash,
  IconUsers,
  IconX
} from '@tabler/icons-react'
import { keyBy } from 'lodash'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactLiquid } from 'react-liquid'
import { toHTML } from 'slack-markdown'
import { toast } from 'sonner'
import { post, postForm, put } from '../../../../lib/api'
import { AccountView } from '../../../../types/AccountView'
import { Apps } from '../../../../types/App'
import { useAccountViews } from '../../../data/use-account-views'
import { filtersAsText, useFacets } from '../../../data/use-facets'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { useSlack } from '../../../data/use-slack'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GrayCard, LightBgCard } from '../../../ui/Card'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { BuildingIcon } from '../../../ui/icons'
import { InfoBox } from '../../../ui/InfoBox'
import KoalaLogo from '../../../ui/KoalaLogo'
import PageDescription from '../../../ui/PageDescription'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { accountViewPath } from '../../account_views/lib/list-paths'
import { ChannelPicker } from '../../account_view_subscriptions/components/Slack'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { PreviewDrawer } from '../../follow_rules/components/preview-drawer'
import { buttons } from '../../follow_rules/components/slack-message-builder/message-builder'
import { DefinitionFormModal } from '../../kql_definitions/components/DefinitionFormModal'
import { anyIntent, SelectSignalsModal, Signal } from '../../kql_definitions/components/SelectSignalsModal'
import { KqlDefinition } from '../../kql_definitions/types'
import { Notification } from '../../notifications'
import { slackAlertNewFromAlertPath, slackAlertPath, slackAlertsPath } from '../../notifications/lib/path-helper'
import { Team } from '../../teams/show'
import { ExtraField, SlackAlert } from '../types'
import { SelectFieldsModal } from './SelectFieldsModal'

interface SlackRuleProps {
  apps?: Apps
  slack_alert?: SlackAlert
  inline?: boolean
  hideAudience?: boolean
}

const defaultTitle = '{{actor}} {{signal.name}}'

export function DefinitionForm(props: SlackRuleProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const [submitting, setSubmitting] = useState(false)
  const [selectedSignal, setSelectedSignal] = useState<KqlDefinition | null>(null)
  const [enabled, setEnabled] = useState(props.slack_alert?.enabled ?? true)
  const [optimisticEnabled, setOptimisticEnabled] = useState(props.slack_alert?.enabled ?? true)

  const persisted = Boolean(props.slack_alert?.id)
  const [atMentions, setAtMentions] = useState<boolean | undefined>(props.slack_alert?.mentions ?? false)
  const [intentSummary, setIntentSummary] = useState<boolean | undefined>(props.slack_alert?.intent_summary ?? false)
  const [channelRouting, setChannelRouting] = useState<SlackAlert['channel_routing']>(
    props.slack_alert?.channel_routing ?? 'channel'
  )

  const persistedAudience =
    ((props.slack_alert?.account_view || props.slack_alert?.static_list) as AudienceOption) || null

  const [audience, setSelectedAudience] = useState<AudienceOption | null>(persistedAudience)
  const [slackChannel, setSlackChannel] = useState<string | undefined>(props.slack_alert?.slack_channel_id ?? undefined)
  const [extraFields, setExtraFields] = useState<ExtraField[]>(props.slack_alert?.extra_fields ?? [])
  const fieldModal = useDisclosure()
  const headingSize = props.inline ? 'xs' : 'sm'

  const removeField = useCallback((field: string) => {
    setExtraFields((prev) => prev.filter((f) => f.facet !== field))
  }, [])

  const eligibleForRouting = useMemo(() => {
    if (audience === null) {
      return false
    }
    return Boolean(audience?.team_id || audience?.team) && Object.keys(ownershipFilters(audience)).length > 0
  }, [audience])

  useEffect(() => {
    if (!eligibleForRouting) {
      setChannelRouting('channel')
    }
  }, [eligibleForRouting])

  const isSpace = !!props.slack_alert?.space_id

  return (
    <Stack pb={props.inline ? undefined : '150px'} spacing={8}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={1}>
          <Heading size={props.inline ? 'sm' : 'md'}>
            {props.slack_alert?.id ? 'Edit your Slack Alert' : 'Create a new Slack Alert'}
          </Heading>
          <PageDescription>Get alerts in Slack about the most important intent signals in real-time.</PageDescription>
        </Stack>
        {props.slack_alert?.id && (
          <Flex alignItems="center" gap={2} py={1}>
            <HStack spacing={2}>
              <Text fontSize="sm" color={enabled ? undefined : 'gray.500'}>
                {enabled ? 'Enabled' : 'Paused'}
              </Text>
              <Switch
                size="md"
                isChecked={optimisticEnabled}
                onChange={async (e) => {
                  const checked = e.target.checked
                  setOptimisticEnabled(checked)

                  await put(slackAlertPath(props.slack_alert?.id, `/status`), {
                    slack_alert: {
                      enabled: checked
                    }
                  })
                    .then(() => {
                      toast.success(`Slack Alert ${checked ? 'enabled' : 'paused'}`)
                      setEnabled(checked)
                    })
                    .catch((e) => {
                      toast.error(`Failed to ${checked ? 'enable' : 'pause'} your Slack Alert`, {
                        description: e.message
                      })
                      // revert
                      setOptimisticEnabled(!checked)
                    })
                }}
              />
            </HStack>
            {!isSpace && <OptionsMenu id={props.slack_alert.id} />}
          </Flex>
        )}
      </HStack>

      <form
        ref={formRef}
        id="slack-alert-form"
        method="POST"
        action={persisted ? slackAlertPath(props.slack_alert?.id) : slackAlertsPath()}
        onSubmit={() => {
          setSubmitting(true)
        }}
      >
        {persisted && <input type="hidden" name="_method" value="PATCH" />}
        <AuthenticityToken />

        <Flex flexDirection="column" gap={6}>
          {props.hideAudience ? (
            <>
              <input
                type="hidden"
                name="slack_alert[account_view_id]"
                value={props.slack_alert?.account_view_id ?? ''}
              />
              <input type="hidden" name="slack_alert[static_list_id]" value={props.slack_alert?.static_list_id ?? ''} />
              <input type="hidden" name="slack_alert[space_id]" value={props.slack_alert?.space_id ?? ''} />
            </>
          ) : props.slack_alert?.space_id ? (
            <FormControl bg="background.light" p={6} rounded="lg">
              <input type="hidden" name="slack_alert[space_id]" value={props.slack_alert.space_id} />

              <Stack spacing={3}>
                <Box>
                  <Flex alignItems="baseline" justifyContent="space-between">
                    <Heading size={headingSize}>Audience</Heading>
                    <Link
                      display="inline-flex"
                      alignItems="center"
                      gap={1}
                      isExternal
                      fontSize="xs"
                      href={projectPath(`/spaces/${props.slack_alert.space_id}`)}
                      color="purple.500"
                    >
                      View space
                      <IconExternalLink size={13} />
                    </Link>
                  </Flex>
                  <FormHelperText mb={0}>
                    This Slack Alert will target all of the lists in the "{props.slack_alert.space?.name}" space.
                  </FormHelperText>
                </Box>
              </Stack>
            </FormControl>
          ) : (
            <FormControl bg="background.light" p={6} rounded="lg">
              <Stack spacing={3}>
                <Box>
                  <Heading size={headingSize}>Audience</Heading>
                  <FormHelperText>What accounts or visitors do you want to get notified about?</FormHelperText>
                </Box>
                <AudienceSelection
                  list_id={props.slack_alert?.account_view_id || props.slack_alert?.static_list_id}
                  initialAudience={persistedAudience}
                  audience={audience}
                  setSelectedAudience={setSelectedAudience}
                />
              </Stack>
            </FormControl>
          )}

          <FormControl bg="background.light" p={6} rounded="lg">
            <Stack spacing={3}>
              <Box>
                <Heading size={headingSize}>Trigger</Heading>
                <FormHelperText>What intent do you want to get notified about?</FormHelperText>
              </Box>

              <TriggerMultiSelect
                anyIntent={props.slack_alert?.any_intent ?? false}
                selectedSignalIds={props.slack_alert?.intent_triggers || []}
                setSelectedSignal={setSelectedSignal}
                selectedAudienceKind={audience?.kind}
              />
            </Stack>
          </FormControl>

          <FormControl>
            <Heading size={headingSize}>Slack channel</Heading>
            <Box marginTop={5}>
              <SlackChannel
                slack_channel_id={props.slack_alert?.slack_channel_id}
                slack_channel_name={props.slack_alert?.slack_channel_name}
                atMentions={atMentions}
                setAtMentions={setAtMentions}
                setSlackChannel={setSlackChannel}
                intentSummary={intentSummary}
                setIntentSummary={setIntentSummary}
                setChannelRouting={setChannelRouting}
                channelRouting={channelRouting}
                audience={audience}
                apps={props.apps ?? {}}
                eligibleForRouting={eligibleForRouting}
              />
            </Box>
          </FormControl>

          <FormControl bg="background.light" p={6} rounded="lg">
            <Heading size={headingSize}>Customize your message</Heading>
            <Stack spacing={5} marginTop={5}>
              <SlackMessagePreview
                mentions={atMentions}
                extraFields={extraFields}
                removeField={removeField}
                includeIntent={intentSummary}
                title={defaultTitle}
                variables={{
                  actor: 'Someone from Dunder Mifflin',
                  account: {
                    name: 'Dunder Mifflin',
                    domain: 'dundermifflin.com',
                    logo_url:
                      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Dunder_Mifflin%2C_Inc.svg/480px-Dunder_Mifflin%2C_Inc.svg.png'
                  },
                  signal: {
                    name: selectedSignal?.name
                  }
                }}
              />
              <Button
                width="full"
                variant="outline"
                bg="white"
                iconSpacing={1.5}
                leftIcon={<IconPlus size={16} />}
                onClick={fieldModal.onOpen}
              >
                Add fields
              </Button>
            </Stack>
            <input type="hidden" name="slack_alert[extra_fields]" value={JSON.stringify(extraFields)} />
            <SelectFieldsModal {...fieldModal} apps={props.apps} extraFields={extraFields} onChange={setExtraFields} />
          </FormControl>

          <HStack spacing={4}>
            <Button type="submit" size="sm" colorScheme="purple" alignSelf="flex-start" isLoading={submitting}>
              {persisted ? 'Save Changes' : 'Create Slack Alert'}
            </Button>
            <SendTestAlert formRef={formRef} slackChannel={slackChannel} channelRouting={channelRouting} />
          </HStack>
        </Flex>
      </form>
    </Stack>
  )
}

interface SendTestAlertProps {
  formRef: React.RefObject<HTMLFormElement>
  slackChannel?: string
  channelRouting: 'channel' | 'user'
}

interface PreviewResponse {
  slack_alert: SlackAlert
  valid: boolean
  samples: Notification[]
}

function SendTestAlert({ formRef, slackChannel, channelRouting }: SendTestAlertProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = React.useState(false)
  const [samples, setSamples] = React.useState<Notification[]>([])
  const [responseError, setResponseError] = React.useState(null)
  const [lookback, _setLookback] = React.useState('2 weeks ago')

  useEffect(() => {
    if (!isOpen || !formRef.current) {
      return
    }

    const data = new FormData(formRef.current)
    const controller = new AbortController()

    setLoading(true)
    setSamples([])

    postForm<PreviewResponse>(slackAlertsPath('/preview'), data, { signal: controller.signal })
      .then((res) => {
        setSamples(res.samples)
      })
      .catch((error) => {
        setResponseError(error.body)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      controller?.abort()
    }
  }, [isOpen, formRef, onClose])

  const handleClose = useCallback(() => {
    onClose()
    setLoading(false)
    setSamples([])
  }, [onClose])

  return (
    <>
      <PreviewDrawer
        notifications={samples}
        loading={loading}
        isOpen={isOpen}
        onClose={handleClose}
        lookback={lookback}
        error={responseError}
      />
      <Button
        size="sm"
        variant="outline"
        leftIcon={<IconPlayerPlay size={14} />}
        isDisabled={!slackChannel && channelRouting === 'channel'}
        isLoading={loading}
        onClick={onOpen}
      >
        Send Test…
      </Button>
    </>
  )
}

function ownershipFilters(audience: AudienceOption) {
  const entries = audience.filters?.facets ?? {}
  const ownershipEntries = Object.entries(entries).filter(([_facet, value]) => {
    if (JSON.stringify(value).includes('Current user')) {
      return true
    }

    return false
  })

  return Object.fromEntries(ownershipEntries)
}

function OwnershipFiltersSummary({ audience, apps }: { audience: AudienceOption; apps: Apps }) {
  const filtered = useMemo(() => ownershipFilters(audience), [audience])
  const facets = useFacets({
    facet_filters: filtered as FacetFilters
  })

  return (
    <HStack p="1">
      {audience.team && (
        <Flex>
          <Button
            as={Link}
            href={projectPath(`/settings/teams/${audience.team.id}`)}
            colorScheme={'purple'}
            isExternal
            size="sm"
            rightIcon={<IconExternalLink size="12" />}
            aria-label="View Team"
            variant={'outline'}
          >
            {teamName(audience.team)}
          </Button>
        </Flex>
      )}
      <FilterPreview {...facets} kind={audience.kind} canClearFilters={false} isEditable={false} apps={apps} />
      {audience.team && (
        <HelpTooltip mode="popover">
          <Stack spacing="2" p="2">
            <Heading size="xs">Team Ownership Routing</Heading>
            <Text fontSize={'xs'}>Koala will provision a new Slack channel for the Account Owner in Slack using:</Text>
            <UnorderedList px="4" spacing="1" fontSize={'xs'}>
              <ListItem>
                The email addresses for all team members of the <i>{teamName(audience.team)}</i>.
              </ListItem>
              <ListItem>
                The Account Ownership filters for the <i>{audience.name}</i> Audience.
              </ListItem>
            </UnorderedList>
          </Stack>
        </HelpTooltip>
      )}
    </HStack>
  )
}

interface SlackChannelProps {
  slack_channel_id?: string | null
  slack_channel_name?: string | null
  atMentions?: boolean
  intentSummary?: boolean
  setAtMentions?: (value: boolean) => void
  setIntentSummary?: (value: boolean) => void
  setSlackChannel?: (channel: string) => void
  channelRouting: 'channel' | 'user'
  setChannelRouting: (channel: 'channel' | 'user') => void
  compact?: boolean
  audience?: AudienceOption | null
  apps: Apps
  eligibleForRouting?: boolean
}

export function SlackChannel(props: SlackChannelProps) {
  const connection = useSlack()

  const { channelRouting, setChannelRouting } = props
  const disconnected = useMemo(() => !connection.isLoading && !connection.data?.connected, [connection])
  const channels = useMemo(() => connection.data?.deps?.channels ?? [], [connection])

  const [selectedChannel, setSelectedChannel] = React.useState<any | undefined>({
    id: props.slack_channel_id,
    name: props.slack_channel_name
  })
  const [newChannelName, setNewChannelName] = React.useState<string>()
  const [creatingChannel, setCreatingChannel] = React.useState(false)
  const [refreshingChannels, setRefreshingChannels] = React.useState(false)

  const onSlackConnected = useCallback(() => {
    connection.refetch()
  }, [connection])

  if (connection.isLoading) {
    return (
      <Box minH="32px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  if (connection.data && disconnected) {
    return (
      <PopupConnectDialog app_id={connection.data.app_id} onConnected={onSlackConnected}>
        {({ onStart }) => (
          <Button size="sm" onClick={onStart} colorScheme="purple" variant="outline">
            Connect Slack to select a channel
          </Button>
        )}
      </PopupConnectDialog>
    )
  }

  if (!connection.data) {
    return null
  }

  const createChannel = async (name: string) => {
    setCreatingChannel(true)

    await post<{ ok: boolean; channel: any }>(projectPath('/slack-alerts/create_channel'), {
      app_instance_id: connection.data.app_id,
      channel_name: name
    })
      .then((response) => {
        if (response?.channel?.id) {
          setSelectedChannel(response.channel)
          setNewChannelName(undefined)
          channels.push(response.channel)
          toast.success(
            <div>
              Channel <strong>{name}</strong> created successfully
            </div>
          )
        }
      })
      .catch((e) => {
        const body = e.body
        if (body.error_type === 'slack_integration') {
          toast.error(
            <div>
              Slack returned the error <strong>{body.error_code}</strong>: {body.description}
              Please contact support@getkoala.com for addtional help.
            </div>
          )
        } else {
          toast.error("We couldn't create the channel. Please contact support@getkoala.com.")
        }
      })

    setCreatingChannel(false)
  }

  const refreshChannels = async () => {
    setRefreshingChannels(true)

    await post<{ ok: boolean; channels: any[] }>(projectPath('/slack-alerts/refresh_channels'), {
      app_instance_id: connection.data.app_id
    })
      .then((response) => {
        setSelectedChannel(undefined)
        setNewChannelName(undefined)
        connection.data.deps.channels = response.channels
        toast.success('Channels refreshed successfully')
      })
      .catch((e) => {
        const body = e.body
        if (body.error_type === 'already_refreshed') {
          return toast.error(
            'You or someone on your team have recently refreshed your channels list. This action can only be performed once every hour.'
          )
        }

        if (body.error_type === 'slack_integration') {
          return toast.error(
            <div>
              Slack returned the error <strong>{body.error_code}</strong>: {body.description}
            </div>
          )
        }

        toast.error("We couldn't refresh your channels. Please contact support@getkoala.com.")
      })
    setRefreshingChannels(false)
  }

  return (
    <>
      <Stack spacing={4} w="100%">
        <FormControl w="100%" as={GrayCard}>
          <Stack w="100%">
            <Stack spacing="1">
              <FormLabel mb="0">Message Routing</FormLabel>
              <FormHelperText>What channel do you want to receive this notification in?</FormHelperText>
            </Stack>

            {props.eligibleForRouting && (
              <RadioGroup
                size="sm"
                value={channelRouting}
                onChange={(val) => setChannelRouting(val as 'user' | 'channel')}
                as={HStack}
                spacing="4"
                py="2"
              >
                <Radio bg="white" value="channel">
                  Send all messages to a Slack channel
                  <HelpTooltip mode="popover">
                    <Stack>
                      <Text>
                        We will forward all Slack notifications to the channel you specify from the list below.
                      </Text>
                    </Stack>
                  </HelpTooltip>
                </Radio>
                <Divider orientation="vertical" />
                <Radio bg="white" value="user">
                  Route Messages to Team Member
                  <HelpTooltip mode="popover">
                    <Stack>
                      <Text>
                        Koala will provision a new Slack channel for the Account Owner in Slack using their email
                        address and the Team filters below. This means each team member will get their own Slack channel
                        where they can receive notifications for the Accounts they own.
                      </Text>
                    </Stack>
                  </HelpTooltip>
                </Radio>
              </RadioGroup>
            )}

            {props.eligibleForRouting && (
              <input type="hidden" name="slack_alert[channel_routing]" value={channelRouting} />
            )}

            {!props.eligibleForRouting && <input type="hidden" name="slack_alert[channel_routing]" value={'channel'} />}

            {channelRouting === 'user' && props.eligibleForRouting && (
              <>
                <LightBgCard bg="white" fontSize="sm">
                  <Stack spacing="4">
                    <Heading size="xs" fontWeight={'semibold'}>
                      Channel Routing
                    </Heading>

                    <Text>
                      Koala will provision a new Slack channel for the Account Owner in Slack using their email address
                      and the Team filters below.
                    </Text>

                    {props.audience && <OwnershipFiltersSummary audience={props.audience} apps={props.apps} />}

                    <FormHelperText>
                      Each Account Owner will get their own Slack channel where they can receive notifications for the
                      Accounts they own.
                    </FormHelperText>
                  </Stack>
                </LightBgCard>
              </>
            )}

            {(channelRouting === 'channel' || !props.eligibleForRouting) && (
              <>
                {newChannelName && <input type="hidden" name="slack[channel_name]" value={newChannelName} />}
                {selectedChannel?.id && (
                  <input type="hidden" name="slack_alert[slack_channel_id]" value={selectedChannel.id} />
                )}
                <ChannelPicker
                  isNewChannelEntered={Boolean(newChannelName)}
                  channels={channels}
                  channel={selectedChannel}
                  setChannel={(channel, inputValue) => {
                    if (channel?.id) {
                      setSelectedChannel(channel)
                      setNewChannelName(undefined)
                      props.setSlackChannel?.(channel.id)
                      return
                    }
                    if (channel?.newChannel) {
                      setNewChannelName(inputValue!)
                      setSelectedChannel(undefined)
                      props.setSlackChannel?.(inputValue!)
                      return
                    }
                    if (channel?.refreshChannels) {
                      setSelectedChannel(undefined)
                      setNewChannelName(undefined)
                      refreshChannels()
                      return
                    }
                  }}
                  chakraInputProps={{
                    size: 'sm',
                    isDisabled: disconnected,
                    isRequired: true,
                    bg: 'white'
                  }}
                />

                {refreshingChannels && (
                  <InfoBox colorScheme="blue">
                    <Text>Refresh all channels</Text>
                    <Spinner colorScheme="green" size="sm" marginLeft="auto" />
                  </InfoBox>
                )}

                {selectedChannel?.id && !refreshingChannels && !channels.find((c) => c.id == selectedChannel.id) && (
                  <InfoBox colorScheme="red">
                    <Text>The channel selected could not be found, please select a new one or refresh your list.</Text>
                    <Button
                      aria-label="Refresh now"
                      variant="outline"
                      size="xs"
                      onClick={refreshChannels}
                      leftIcon={<IconRefresh size={14} />}
                    >
                      Refresh now
                    </Button>
                  </InfoBox>
                )}

                {newChannelName && (
                  <InfoBox colorScheme="blue">
                    <Text>
                      A new public channel named{' '}
                      <Code color="inherit" fontSize="xs" fontWeight="semibold" bg="transparent" marginX={0}>
                        #{newChannelName}
                      </Code>{' '}
                      will be created.
                    </Text>
                    <Button
                      aria-label="Create it now"
                      variant="outline"
                      size="xs"
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        createChannel(newChannelName)
                      }}
                      disabled={creatingChannel}
                      leftIcon={<IconPlayerPlay size={14} />}
                    >
                      Create now
                    </Button>
                  </InfoBox>
                )}
              </>
            )}
          </Stack>
        </FormControl>

        <Divider />

        <FormControl as={GrayCard}>
          <input name="slack_alert[mentions]" type="hidden" value={props.atMentions ? 'true' : 'false'} />
          <Stack>
            <FormLabel mb="0">Message Settings</FormLabel>
            <Checkbox
              isChecked={props.atMentions}
              onChange={(e) => props.setAtMentions?.(e.target.checked)}
              marginTop={4}
            >
              <HStack>
                <Text fontSize="sm">@mention Account Owners in Slack</Text>
                <HelpTooltip mode="popover">
                  <Stack>
                    <Text>
                      We will mention the Account Owner in Slack using their email address from your CRM. The mentioned
                      user will also be notified about the reference in Slack.
                    </Text>
                    <Link
                      display="flex"
                      alignItems="center"
                      gap={1}
                      href="https://slack.com/help/articles/205240127-Use-mentions-in-Slack"
                      color="purple.500"
                      isExternal
                    >
                      Learn more about mentions in Slack <Icon as={IconExternalLink} boxSize={3.5} />
                    </Link>
                  </Stack>
                </HelpTooltip>
              </HStack>
            </Checkbox>

            {!props.compact && (
              <>
                {!props.intentSummary && <input name="slack_alert[intent_summary]" type="hidden" value="false" />}
                <Checkbox
                  name="slack_alert[intent_summary]"
                  value="true"
                  isChecked={props.intentSummary}
                  onChange={(e) => props.setIntentSummary?.(e.target.checked)}
                  marginTop={4}
                >
                  <HStack>
                    <Text fontSize="sm">Include Detailed Intent Summary</Text>
                    <HelpTooltip mode="popover">
                      <Stack>
                        <Text>
                          Include detailed intent summaries in the Slack message. Intent summaries are a list of the
                          most recent events that triggered a notification.
                        </Text>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                </Checkbox>
              </>
            )}
          </Stack>
        </FormControl>
      </Stack>
    </>
  )
}

interface TriggerMultiSelectProps {
  anyIntent?: boolean
  selectedSignalIds?: string[]
  setSelectedSignal?: (signal: KqlDefinition | null) => void
  selectedAudienceKind?: 'account' | 'profile'
  skipCreation?: boolean
  disabled?: boolean
  skipForms?: boolean
  onChange?: (ids: string[], selectAnyIntent: boolean) => void
  skipAnyIntent?: boolean
}

export function TriggerMultiSelect(props: TriggerMultiSelectProps) {
  const disclosure = useDisclosure()
  const createModal = useDisclosure()
  const { data, isLoading, refetch } = useIntentSignals()
  const signals = useMemo(() => data?.definitions ?? [], [data?.definitions])
  const signalsById = useMemo(() => keyBy(signals, 'id'), [signals])

  const [anyIntentSelected, setAnyIntentSelected] = useState(props.anyIntent ?? false)
  const initialSelectedIds = props.selectedSignalIds
  const selectedAudienceKind = props.selectedAudienceKind
  const [selected, setSelected] = useState<string[]>(initialSelectedIds || [])

  const setSelectedSignal = props.setSelectedSignal
  useEffect(() => {
    setSelectedSignal?.(signalsById[selected[0]] || null)
  }, [selected, signalsById, setSelectedSignal])

  useEffect(() => {
    setSelected((prev) => {
      if (prev.length) {
        return prev
      }

      if (initialSelectedIds?.length) {
        return initialSelectedIds
      }

      return []
    })
  }, [initialSelectedIds, signals])

  useEffect(() => {
    props.onChange?.(selected, anyIntentSelected)
  }, [selected, anyIntentSelected, props])

  // if there aren't many signals, just show them inline
  const showAdvancedModal = !isLoading && (signals.length === 0 || signals.length > 8)
  const displayedSignals = showAdvancedModal ? selected : Object.keys(signalsById)

  const restrictedSignalTypes = useMemo(() => {
    return selectedAudienceKind === 'profile' ? ['account_trait'] : []
  }, [selectedAudienceKind])

  // Don't include restricted signals in the inputs
  const supportedSelections = useMemo(() => {
    return selected.filter((id) => {
      return signalsById[id] && !restrictedSignalTypes.includes(signalsById[id].signal_type || '')
    })
  }, [selected, signalsById, restrictedSignalTypes])

  if (isLoading) {
    return (
      <Box minH="40px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  return (
    <div>
      {!props.skipForms && (
        <>
          <input type="hidden" name="slack_alert[any_intent]" value={anyIntentSelected ? 'true' : 'false'} />

          {supportedSelections.map((id) => (
            <input key={id} type="hidden" name="slack_alert[intent_triggers][]" value={id} />
          ))}
        </>
      )}

      <Stack spacing={4} opacity={props.disabled ? 0.5 : 1} pointerEvents={props.disabled ? 'none' : undefined}>
        {(!showAdvancedModal || anyIntentSelected) && !props.skipAnyIntent && (
          <Signal
            signal={anyIntent}
            checked={anyIntentSelected}
            info="Any pageview, form submission or custom event"
            onChange={(e) => {
              if (e.target.checked) {
                setAnyIntentSelected(true)
                setSelected([])
              } else {
                setAnyIntentSelected(false)
              }
            }}
          />
        )}
        {displayedSignals.length > 0 ? (
          <Stack spacing={2}>
            <CheckboxGroup
              value={selected}
              onChange={(v) => {
                setSelected(v as string[])
                setAnyIntentSelected(false)
              }}
            >
              {displayedSignals
                .filter((id) => signalsById[id])
                .map((id) => {
                  const signal = signalsById[id]
                  return (
                    <Signal
                      key={id || signal.name}
                      signal={signal}
                      checked={selected.includes(id)}
                      disabled={restrictedSignalTypes.includes(signal.signal_type || '') || anyIntentSelected}
                      disabledReason={
                        restrictedSignalTypes.includes(signal.signal_type || '')
                          ? 'This signal type is not available for your selected audience.'
                          : undefined
                      }
                    />
                  )
                })}
            </CheckboxGroup>
          </Stack>
        ) : null}

        {showAdvancedModal ? (
          <Button
            width="full"
            variant="outline"
            bg="white"
            iconSpacing={1.5}
            leftIcon={<IconPlus size={16} />}
            onClick={disclosure.onOpen}
          >
            Select triggers
          </Button>
        ) : (
          !props.skipCreation && (
            <Button alignSelf="flex-start" size="sm" variant="outline" onClick={createModal.onOpen}>
              Create an Intent Signal
            </Button>
          )
        )}
      </Stack>

      {showAdvancedModal ? (
        <SelectSignalsModal
          {...disclosure}
          anyIntent={anyIntentSelected}
          skipAnyIntent={props.skipAnyIntent}
          selectedSignalIds={selected}
          restrictedSignalTypes={restrictedSignalTypes}
          onChange={(ids, selectAnyIntent) => {
            setSelected(ids)
            setAnyIntentSelected(selectAnyIntent)
          }}
        />
      ) : (
        <DefinitionFormModal {...createModal} onComplete={refetch} />
      )}
    </div>
  )
}

interface AudienceSelectionProps {
  list_id?: string | null
  initialAudience?: AudienceOption | null
  audience: AudienceOption | null
  setSelectedAudience?: (list: null | AudienceOption) => void
}

interface AudienceOption {
  id?: string
  name: string
  slug?: string
  summary?: string
  filters: {
    facets?: {}
  }
  kind: 'account' | 'profile'
  shared?: boolean
  created_by_you?: boolean
  team_id?: string
  team?: Team
  class_name?: string
}

const everyone: AudienceOption = {
  id: '',
  name: 'Everyone',
  summary: 'Any visitor that triggers one of your selected intent signals (except global exclusions)',
  kind: 'account' as const,
  filters: { facets: {} }
}

function AudienceSelection(props: AudienceSelectionProps) {
  const initialSelectedId = props.list_id || null
  const setSelectedAudience = props.setSelectedAudience
  const user = useCurrentUser()

  // exclude views that belong to Spaces from the list
  const { data, isLoading } = useAccountViews({ includePrivate: true, ownership: ['private', 'shared', 'team'] })
  const allAudiences = useMemo(() => {
    const all = sortBy(data?.account_views ?? [], 'kind').map((a) => ({
      ...a,
      created_by_you: a.created_by === user.id
    }))

    // if for some reason the api isn't returning the initial account_view, we should try to add it here.
    if (initialSelectedId && props.audience && !all.find((a) => a.id === initialSelectedId)) {
      all.unshift(props.audience as any)
    }
    return all
  }, [data?.account_views, user?.id, initialSelectedId, props.audience])

  // show audiences that are shared, created by you, or the already-selected audience
  const audiences = useMemo(() => {
    const isPowerUser = user.role === 'admin' || user.isInternalUser

    const filtered = allAudiences.filter(
      (a) =>
        a.created_by_you ||
        a.ownership === 'shared' ||
        // if you're admin of the workspace, you can see all team lists
        (isPowerUser && a.ownership === 'team') ||
        // if you're a team member, you can see team lists
        (user.email && a.team?.emails.includes?.(user.email)) ||
        a.id === initialSelectedId
    )
    return [everyone, ...filtered] as AudienceOption[]
  }, [allAudiences, initialSelectedId, user])

  // if there's a deleted list, we should include it
  const placeholderList = {
    id: initialSelectedId,
    name: '[Unknown list]',
    summary: 'This list seems to be invalid or deleted.',
    kind: 'account' as const,
    filters: { facets: {} }
  } as any

  // this will ensure that we use the initial account_view if one is already set on page load
  const [selected, setSelected] = useState<AudienceOption | null>(
    props.audience || (initialSelectedId && !props.initialAudience ? placeholderList : null)
  )

  const selectAudience = useCallback(
    (audience: AudienceOption | null) => {
      setSelected(audience)
      setSelectedAudience?.(audience)
    },
    [setSelectedAudience]
  )

  const hasLists = Boolean(data?.account_views?.length ?? 0)

  if (isLoading) {
    return (
      <Box minHeight="57px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  return (
    <Stack>
      {!hasLists ? (
        <InfoBox>
          <Text flex="1">Looks like you don't have any lists defined yet. Create one to use it as an audience.</Text>
          <Button
            as={Link}
            size="xs"
            variant="outline"
            rightIcon={<IconArrowUpRight size={14} />}
            isExternal
            href={projectPath('/views/new')}
          >
            Create List
          </Button>
        </InfoBox>
      ) : props.list_id && !props.audience ? (
        <InfoBox colorScheme="orange" icon={<Icon as={IconAlertTriangle} flex="none" boxSize={4} />} gap={2}>
          <Text flex="1">The audience you selected is no longer available. Please pick another audience.</Text>
        </InfoBox>
      ) : null}

      {selected?.class_name === 'StaticList' ? (
        <>
          <input type="hidden" name="slack_alert[static_list_id]" value={selected?.id || ''} />
          <input type="hidden" name="slack_alert[account_view_id]" value="" />
        </>
      ) : (
        <>
          <input type="hidden" name="slack_alert[account_view_id]" value={selected?.id || ''} />
          <input type="hidden" name="slack_alert[static_list_id]" value="" />
        </>
      )}

      <ComboboxWithSearch
        items={audiences}
        selectedItem={selected || everyone}
        onChange={selectAudience}
        filterItem={(a, val) => a.name.toLowerCase().includes(val)}
        itemToString={(item) => item?.name || 'Unknown list'}
        itemRenderer={AudiencePreview}
        selectButtonRenderer={AudiencePreview}
      />
      {props.audience?.id && (
        <Flex justifyContent="flex-end">
          <Link
            display="inline-flex"
            alignItems="center"
            gap={1}
            isExternal
            fontSize="xs"
            href={
              props.audience.class_name === 'StaticList'
                ? projectPath(`/lists/${props.audience.id}`)
                : accountViewPath(props.audience as AccountView)
            }
            color="purple.500"
            marginBottom={-3}
          >
            View list
            <IconExternalLink size={13} />
          </Link>
        </Flex>
      )}
    </Stack>
  )
}

export function teamName(team: Team) {
  if (team.name.includes('Team') || team.name.includes('team')) {
    return team.name
  }

  return `${team.name} Team`
}

interface AudiencePreviewProps {
  item: AudienceOption | null
  selectedItem?: AudienceOption | null
}

export function AudiencePreview(props: AudiencePreviewProps) {
  const audience = props.item || everyone
  const filterSummary = filtersAsText(audience.filters ?? {})
  const isStaticList = audience.class_name === 'StaticList'

  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <HStack width="100%" spacing={2}>
        <Center flex="none" width={8}>
          <Icon as={audience.kind === 'profile' ? IconUsers : BuildingIcon} color="gray.600" boxSize={5} />
        </Center>
        <Box flex="1">
          <HStack spacing={1.5}>
            <Text fontSize="sm" fontWeight="medium" lineHeight="20px">
              {audience.name || 'Unknown list'}
            </Text>
            {audience.shared === false && !audience.created_by_you && (
              <Icon as={IconLock} boxSize={4} color="gray.500" />
            )}
          </HStack>
          <HStack>
            <Text fontSize="xs" fontStyle="italic" color="gray.500">
              {isStaticList
                ? audience.kind === 'profile'
                  ? 'People on this list'
                  : 'Accounts on this list'
                : filterSummary ||
                  audience.summary ||
                  `No applicable filters (all ${audience.kind === 'profile' ? 'people' : 'accounts'})`}
            </Text>
            {audience.team && (
              <Tag colorScheme={'purple'} size="sm">
                {teamName(audience.team)}
              </Tag>
            )}
          </HStack>
        </Box>
      </HStack>
      {props.selectedItem?.id === audience.id && (
        <Icon as={IconCheck} flex="none" color="purple.500" boxSize={4} marginRight={2} />
      )}
    </Flex>
  )
}

interface MessagePreviewProps {
  title: string
  variables?: Record<string, any>
  mentions?: boolean
  extraFields?: ExtraField[]
  removeField?: (field: string) => void
  includeIntent?: boolean
}

export function SlackMessagePreview(props: MessagePreviewProps) {
  return (
    <Box>
      <Box bg="white" padding={4} rounded="md" shadow="base">
        <HStack width="100%" maxWidth="700px" paddingRight={4} alignItems="flex-start" spacing={4}>
          <KoalaLogo kind="mark" size="28px" />
          <Stack spacing={1} w="100%">
            <HStack spacing={1}>
              <Heading fontSize="0.95rem" fontWeight="bold">
                Koala
              </Heading>
              <Text
                fontSize="10px"
                fontWeight={600}
                height="14px"
                lineHeight="1.25"
                borderRadius="2px"
                bg="rgba(29, 28, 29, 0.13)"
                color="rgba(29, 28, 29, 0.7)"
                padding="1px 3px"
              >
                APP
              </Text>
              <Text fontSize="xs" color="GrayText" paddingX={1}>
                1:00 PM
              </Text>
            </HStack>
            <Stack>
              <Heading size="sm" fontSize="lg" fontWeight="bold" pt="1">
                <ReactLiquid template={props.title} data={props.variables} />
              </Heading>
              <Flex
                paddingTop={2}
                justifyContent="space-between"
                fontSize="sm"
                css={{
                  strong: { fontWeight: '600' },
                  a: { color: '#1264a3' },
                  '.s-mention': {
                    display: 'inline-block',
                    color: '#1264a3',
                    background: 'rgba(29,155,209,0.1)',
                    borderRadius: '4px',
                    padding: '0 2px 1px',
                    lineHeight: '22px',
                    cursor: 'pointer',
                    '&:hover': {
                      background: 'rgba(29,155,209,0.2)'
                    }
                  }
                }}
              >
                <Stack flex="1" spacing={1}>
                  <ReactLiquid template={toHTML(companySummaryBlock(props.mentions))} data={props.variables} html />
                  {props.extraFields?.map((field) => (
                    <Box key={field.facet} alignSelf="flex-start">
                      <HStack
                        spacing={1}
                        rounded="md"
                        border="1px solid"
                        borderColor="gray.200"
                        paddingLeft={1.5}
                        fontWeight="semibold"
                      >
                        <Text>{field.label}</Text>
                        <IconButton
                          aria-label="Remove field"
                          size="xs"
                          roundedLeft={0}
                          variant="ghost"
                          icon={<IconX size={14} />}
                          onClick={() => props.removeField?.(field.facet)}
                        />
                      </HStack>
                    </Box>
                  ))}
                </Stack>
                <CompanyAvatar
                  rounded="md"
                  size="96px"
                  domain={props.variables?.account?.domain}
                  name={props.variables?.account?.name}
                />
              </Flex>
              <Divider />
              {props.includeIntent && (
                <>
                  <Stack fontSize={'sm'} spacing="1">
                    <Link color={'blue.600'} fontWeight="semibold">
                      Intent Summary
                    </Link>
                    <Stack spacing="0">
                      <Text>
                        <strong>Page View:</strong> /pricing
                      </Text>
                      <Text>
                        <strong>Page View:</strong> /demo
                      </Text>
                    </Stack>
                  </Stack>
                </>
              )}
              <ButtonGroup
                variant="outline"
                size="sm"
                paddingTop={3}
                as={Flex}
                flexWrap="wrap"
                rowGap={2}
                columnGap={0}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {[buttons['Open in Koala'], buttons['Salesforce']].map((button) => (
                  <Button
                    key={JSON.stringify(button)}
                    paddingX={2.5}
                    height="30px"
                    fontWeight="semibold"
                    fontSize="13px"
                    colorScheme={button.style === 'primary' ? 'green' : undefined}
                  >
                    {button.text}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
          </Stack>
        </HStack>
      </Box>
    </Box>
  )
}

export function OptionsMenu(props: { id: string; editPath?: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Menu autoSelect={false} placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          aria-label="Options"
          variant="ghost"
          color="gray.500"
          _active={{
            color: 'gray.900'
          }}
          _hover={{
            color: 'gray.900'
          }}
          icon={<Icon as={IconDotsVertical} boxSize={4} />}
        />
        <MenuList zIndex="popover">
          {props.editPath && (
            <MenuItem icon={<IconEdit size={16} />} as={Link} href={props.editPath} _hover={{ textDecoration: 'none' }}>
              Edit
            </MenuItem>
          )}
          <MenuItem icon={<IconCopy size={16} />} as={Link} href={slackAlertNewFromAlertPath(props.id)}>
            Clone
          </MenuItem>
          <MenuItem color="red.500" icon={<IconTrash size={16} />} onClick={onOpen}>
            Delete…
          </MenuItem>
        </MenuList>
      </Menu>

      <DeleteConfirmation
        isOpen={isOpen}
        onClose={onClose}
        deletePath={slackAlertPath(props.id)}
        title="Delete Slack Alert"
      >
        Are you sure you want to delete this Slack Alert?
      </DeleteConfirmation>
    </>
  )
}

const companySummaryBlock = (mentions?: boolean) =>
  `
  *<https://{{ account.domain }}|{{ account.name }}>*
  *Website*: <https://{{ account.domain }}|{{ account.domain }}>
  *Intent*: Very High
  *Fit*: A (95th percentile)
  *Visitors*: 11 this month
  *Session time*: 2 hours this month
  *Account Owner*: ${mentions ? `<@1234|Kate Morrison>` : 'Kate Morrison'}
  `.trim()
