import { useMutation } from 'react-query'
import { post } from '../../lib/api'
import { StaticList } from '../../types/StaticList'
import { projectPath } from '../ui/ProjectsContext'

interface ListItemParams {
  listId: string
  recordIds: string[]
}

export function useRemoveListItems() {
  return useMutation(({ listId, recordIds }: ListItemParams) => {
    const path = projectPath(`/lists/${listId}/records/remove`)
    return post(path, {
      record_ids: recordIds
    })
  })
}

interface CreateAndAddListParams {
  listId?: string | null
  list?: {
    name: string
    description?: string
    kind: 'account' | 'profile'
  }
  recordIds: string[]
}

interface AddListItemsResponse {
  list: StaticList
  success?: boolean
}

export function useAddListItems() {
  return useMutation<AddListItemsResponse, unknown, CreateAndAddListParams>(({ recordIds, listId, list }) => {
    const path = listId ? projectPath(`/lists/${listId}/records`) : projectPath('/lists')

    const payload = {
      record_ids: recordIds,
      static_list: list
    }

    return post(path, payload)
  })
}
