import { useIdentifiedVisitors } from '@app/components/data/use-identified-visitors'
import { useImportContactsToCRM } from '@app/components/data/use-import-contacts-to-crm'
import { Card, GrayCard, LightBgCard } from '@app/components/ui/Card'
import useFuzzyCrmMatches from '@app/components/ui/useFuzzyCrmMatches'
import { Account } from '@app/types/Account'
import { App } from '@app/types/App'
import { isActionDisabled } from '@app/types/AppActions'
import { ProfileRecord } from '@app/types/Profile'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Spinner,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useCheckboxGroup,
  useDisclosure,
  UseDisclosureProps,
  VStack
} from '@chakra-ui/react'
import { IconAlertCircle, IconExternalLink } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import { LinkedinIcon, TwitterIcon } from '../../../ui/icons'
import { SearchBar } from '../../accounts/facets/search-bar'
import { Sequences } from '../../apps/outreach/widgets/Sequences'
import { CrmMatchSummary } from './CrmMatchSummary'

interface ImportContactsSideSheetProps extends UseDisclosureProps {
  account: Account | null
  // optional pre-selected profile
  profile?: ProfileRecord
  app?: App
}

export function salesNavigatorLink(account: Account | null, titles: string[] = []): undefined | string {
  if (!account || !account.company.domain) {
    return undefined
  }

  const params = new URLSearchParams({
    companyIncluded: account.company.name ?? account.company.domain,
    companyTimeScope: 'CURRENT',
    titleIncluded: titles.join(',')
  })

  return `https://www.linkedin.com/sales/search/people?${params.toString()}`
}

export function salesNavigatorPersonLink(account: Account | null, profile: ProfileRecord): undefined | string {
  if (!account || !account.company.domain) {
    return undefined
  }

  const company = account.company.name ?? account.company.domain
  const personKeywords = [profile.name, profile.email?.split('@')?.[0]].filter(Boolean).join(' ')
  return `https://www.linkedin.com/sales/search/people?query=(filters:List((type:CURRENT_COMPANY,values:List((text:${company},selectionType:INCLUDED)))),keywords:${personKeywords})`
}

export function salesNavigatorCompanyLink(account: Account | null): undefined | string {
  if (!account || !account.company.domain) {
    return undefined
  }

  const keywords = [account.company.domain, account.company.name].filter(Boolean).join(' OR ')
  return `https://www.linkedin.com/sales/search/company?query=(keywords:${keywords})`
}

export function ImportContactsSideSheet(props: ImportContactsSideSheetProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose

  const { allAccounts, strongMatches, fuzzyMatches, mainCrmRecord, selectedCrmRecord, setSelectedCrmRecord } =
    useFuzzyCrmMatches({
      account: props.account,
      app: props.app,
      fuzzy: true,
      create: props.app?.clean_module_name === 'Outreach'
    })

  const visitors = useIdentifiedVisitors(props.account?.company_id)
  const [selectedVisitors, setSelectedVisitors] = React.useState<string[]>(
    props.profile?.email ? [props.profile.email] : []
  )
  const [selectedSequences, setSelectedSequences] = React.useState<number[]>([])

  const hasStrongMatches = useMemo(() => strongMatches.length > 0, [strongMatches])
  const hasFuzzyMatches = useMemo(() => fuzzyMatches.length > 0, [fuzzyMatches])
  const [displayAllMatches, setDisplayAllMatches] = useBoolean(!hasStrongMatches && hasFuzzyMatches)

  useEffect(() => {
    if (!hasStrongMatches && hasFuzzyMatches) {
      setDisplayAllMatches.on()
    }
  }, [hasStrongMatches, hasFuzzyMatches, setDisplayAllMatches])

  const displayedMatches = React.useMemo(() => {
    if (displayAllMatches) {
      return strongMatches.concat(fuzzyMatches)
    }
    return strongMatches
  }, [displayAllMatches, strongMatches, fuzzyMatches])

  const importContactsToCrm = useImportContactsToCRM()

  const allSelected = React.useMemo(() => [...selectedVisitors], [selectedVisitors])

  const updateSelectedVisitors = React.useCallback((value: string[]) => {
    setSelectedVisitors(value)
  }, [])

  const updateSelectedSequences = React.useCallback((value: number[]) => {
    setSelectedSequences(value)
  }, [])

  const handleImport = React.useCallback(async () => {
    if (!props.account?.company.domain || !props.app) {
      return
    }

    const companyName = props.account.company.name
    const domain = props.account.company.domain
    const crmEntityId = selectedCrmRecord

    try {
      if (!crmEntityId || !allSelected.length) {
        return
      }

      await importContactsToCrm.mutateAsync({
        domain,
        profile_emails: allSelected,
        crm_entity_id: crmEntityId,
        app_module: props.app.app_module,
        outreach_sequences: selectedSequences
      })

      toast.message(`🎉  Imported ${allSelected.length} contacts to ${companyName}`, {
        description: `You can now view the imported data in ${props.app.title || 'the CRM'}`
      })

      onClose()
    } catch (err: any) {
      toast.error(`Import failed`, {
        description:
          err.body?.error ?? 'Something is not quite right. Please reach out so we can help you debug what is wrong.'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.account?.company,
    props.app,
    importContactsToCrm.mutateAsync,
    allSelected,
    selectedCrmRecord,
    selectedSequences,
    onClose
  ])

  const isImporting = importContactsToCrm.isLoading
  const isImportContactsDisabled = (() => {
    if (isImporting || !allSelected.length) {
      return true
    }
    return props.app && isActionDisabled(props.app.actions, `${props.app.action_prefix}_import_contacts`)
  })()

  return (
    <Drawer size="lg" {...disclosure} placement="right" preserveScrollBarGap>
      <DrawerOverlay zIndex="modal" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md" display="flex" gap={2} alignItems="center">
            {props.app ? (
              <>
                Import contacts to {props.account?.company.name} in
                <Image src={props.app.logo} boxSize="5" />
                {props.app.title}
              </>
            ) : (
              <>Connect an app to import contacts from {props.account?.company.name}</>
            )}
          </Heading>
        </DrawerHeader>

        <DrawerBody paddingTop={0} paddingBottom={10}>
          <VStack alignItems="stretch" spacing={8}>
            <VStack alignItems="stretch" spacing={3}>
              {mainCrmRecord ? (
                <Text fontSize="sm" color="gray.600" fontWeight="medium">
                  Pick the contacts and prospects you want to associate with this account.
                </Text>
              ) : allAccounts.length ? (
                <Text fontSize="sm" color="gray.600" fontWeight="medium">
                  We found some matches in {props.app?.title || 'the CRM'} for this company. Pick which account you want
                  to associate contacts and prospects with.
                </Text>
              ) : (
                <Stack as={LightBgCard}>
                  <Heading size="sm" fontWeight={'semibold'}>
                    Create Account
                  </Heading>
                  <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    We couldn't find any matching accounts in {props.app?.title || 'the CRM'} by domain or name. Please
                    create an account first.
                  </Text>
                  <Button colorScheme={'purple'}>Create Account</Button>
                </Stack>
              )}

              {displayedMatches.map((crmAccount) => (
                <Card
                  key={crmAccount.crm_entity_id}
                  display="flex"
                  borderWidth={1}
                  borderColor={selectedCrmRecord === crmAccount.crm_entity_id ? 'blue.500' : 'gray.200'}
                  padding={4}
                  shadow="sm"
                >
                  {(strongMatches.length > 1 || hasFuzzyMatches) && (
                    <Checkbox
                      marginRight={4}
                      isDisabled={isImporting}
                      isChecked={selectedCrmRecord === crmAccount.crm_entity_id}
                      value={crmAccount.crm_entity_id}
                      onChange={(e) => setSelectedCrmRecord(e.target.checked ? e.target.value : undefined)}
                    />
                  )}
                  <CrmMatchSummary
                    name={crmAccount.crm_entity.name}
                    logo={crmAccount.match_type !== 'name' ? props.account?.company.logo : undefined}
                    domain={crmAccount.crm_entity.domain}
                    permalink={crmAccount.crm_entity.permalink}
                    crm={crmAccount.crm}
                    matchType={crmAccount.match_type}
                  />
                  {isImporting && selectedCrmRecord === crmAccount.crm_entity_id && <Spinner color="gray.400" />}
                </Card>
              ))}

              {hasStrongMatches && hasFuzzyMatches && (
                <Button size="sm" variant="outline" onClick={setDisplayAllMatches.toggle} colorScheme="purple">
                  {displayAllMatches ? 'Hide' : 'Show'} +{fuzzyMatches.length} fuzzy{' '}
                  {pluralize('match', fuzzyMatches.length)}
                </Button>
              )}
            </VStack>

            <Divider />

            <VStack alignItems="stretch" position="relative" spacing={10}>
              {!!props.app && props.app.title == 'Outreach' && (
                <>
                  <Sequences app={props.app} updateSelectedSequences={updateSelectedSequences} />
                </>
              )}
              <VStack alignItems="stretch" spacing={5}>
                <VStack alignItems="stretch" spacing={1}>
                  <Heading size="sm" fontWeight={'semibold'}>
                    Known Users
                  </Heading>
                  <Text fontSize="xs" color="gray.600">
                    Below is a list of people who have submitted their email address on the site.
                  </Text>
                </VStack>

                {visitors.isLoading ? (
                  <Center>
                    <Spinner color="gray.400" />
                  </Center>
                ) : (
                  <Flex w="100%">
                    <PeopleTable
                      key={`visitors:${props.account?.company_id}`}
                      title="Identified Visitors"
                      people={visitors.data?.identified ?? []}
                      preSelected={props.profile}
                      isLoading={isImporting}
                      onSelectionChange={updateSelectedVisitors}
                      showSessionTime
                    />
                  </Flex>
                )}
              </VStack>

              <VStack alignItems="stretch" spacing={5}>
                <VStack alignItems="stretch" spacing={1}>
                  <HStack alignItems="center" spacing={2}>
                    <Image src="https://logo.clearbit.com/business.linkedin.com" boxSize="5" />
                    <Heading size="sm" fontWeight={'semibold'}>
                      Sales Navigator
                    </Heading>
                  </HStack>
                  <Text fontSize="sm" color="gray.600">
                    Here is quick link to do direct prospecting within Sales Navigator.
                  </Text>
                </VStack>
                <Button
                  as={Link}
                  href={salesNavigatorLink(props.account)}
                  isExternal
                  variant="ghost"
                  colorScheme="blue"
                  rightIcon={<IconExternalLink size={16} />}
                >
                  Search in Sales Navigator
                </Button>
              </VStack>
            </VStack>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderTopColor="gray.200">
          <Flex width="100%" gap={2} alignItems="center" justifyContent="end">
            {allSelected.length > 0 && <Text marginRight="auto">{allSelected.length} contacts selected</Text>}
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Tooltip
              label={`Importing Contacts is currently disabled for the ${props.app?.title} integration. You can turn it 
                      back on in the ${props.app?.title} Integration settings page, or request that your workspace Admin 
                      enables it for you.`}
              p={4}
              shouldWrapChildren={
                props.app && isActionDisabled(props.app.actions, `${props.app.action_prefix}_import_contacts`)
              }
            >
              <Tooltip
                label={`Your user does not exist in ${
                  props.app ? props.app.title : 'the CRM'
                }. Koala will import the contacts, but you won't be assigned as their owner.`}
                p={4}
                isDisabled={props.app?.user_in_app}
              >
                <Button
                  colorScheme="purple"
                  disabled={isImportContactsDisabled}
                  isLoading={isImporting}
                  loadingText="Importing..."
                  onClick={handleImport}
                  leftIcon={props.app && !props.app.user_in_app ? <IconAlertCircle size={16} /> : undefined}
                >
                  Import Contacts
                </Button>
              </Tooltip>
            </Tooltip>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

interface PeopleTableProps extends TableProps {
  title?: string
  people: Array<ProfileRecord>
  isLoading?: boolean
  onSelectionChange?: (selected: Array<string>) => void
  showSessionTime?: boolean
  preSelected?: ProfileRecord
}

function PeopleTable({
  people,
  title,
  isLoading,
  onSelectionChange,
  showSessionTime,
  preSelected,
  ...props
}: PeopleTableProps) {
  const [filter, setFilter] = React.useState('')

  const { value, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: preSelected?.email ? [preSelected.email] : [],
    onChange: onSelectionChange
  })

  const visiblePeople = React.useMemo(() => {
    let filtered = people

    if (filter) {
      filtered = people.filter((p) => {
        const searchable = [p.name, p.email, p.display_name, p.title].filter(Boolean).join(' ')
        return searchable.toLowerCase().includes(filter.toLowerCase())
      })
    }

    return orderBy(filtered, (p) => {
      return p.id === preSelected?.id ? -1 : 0
    })
  }, [filter, people, preSelected?.id])

  const toggleAll = React.useCallback(() => {
    setValue((prev) => (prev.length === people.length ? [] : (visiblePeople.map((p) => p.email) as string[])))
  }, [people, setValue, visiblePeople])

  if (!isLoading && !people.length) {
    return (
      <>
        <Heading size="sm">No {title} found</Heading>
      </>
    )
  }

  return (
    <Stack w="100%" as={GrayCard} spacing="4">
      <SearchBar onChange={(e) => setFilter(e)} value={filter} size="sm" />
      <Flex maxH="900" overflowY={'auto'} bg="white" as={LightBgCard}>
        <Table size="sm" {...props}>
          <Thead>
            <Tr>
              <Th background="white" zIndex={1} position="sticky" top={0} paddingLeft={0} width="240px">
                <HStack spacing="3">
                  <Checkbox
                    isDisabled={isLoading}
                    isChecked={people.length > 0 && value.length === people.length}
                    onChange={toggleAll}
                  />
                  <Text>{title || 'Name'}</Text>
                </HStack>
              </Th>
              <Th background="white" zIndex={1} position="sticky" top={0} width="240px">
                Title
              </Th>
              {showSessionTime !== false && (
                <Th background="white" zIndex={1} position="sticky" top={0} isNumeric width="240px">
                  Session Time
                </Th>
              )}
              <Th background="white" zIndex={1} position="sticky" top={0}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {visiblePeople.map((person) => {
              return (
                <Tr key={person.email || person.person?.id}>
                  <Td paddingLeft={0}>
                    <HStack spacing="4">
                      <Checkbox {...getCheckboxProps({ isDisabled: isLoading, value: person.email })} />
                      <HStack>
                        <Box>
                          <Text fontSize="xs" fontWeight="medium">
                            {person.name || person.person?.name?.fullName || person.anonymous_name}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            {person.email || person.person?.email}
                          </Text>
                        </Box>
                      </HStack>
                    </HStack>
                  </Td>
                  <Td>
                    <Text fontSize="xs">
                      {person.person?.employment?.title || person.title || person.person?.title}
                    </Text>
                  </Td>
                  {showSessionTime !== false && !!person.focus_time && (
                    <Td isNumeric>
                      <Text fontWeight="semibold">{person.focus_time > 0 ? ms(person.focus_time) : '-'}</Text>
                    </Td>
                  )}
                  <Td paddingX={2}>
                    <HStack>
                      {person.person?.linkedin?.handle && (
                        <IconButton
                          minWidth="0"
                          variant="link"
                          color="gray.400"
                          as={Link}
                          isExternal
                          aria-label="LinkedIn link"
                          href={`https://linkedin.com/${person.person?.linkedin?.handle}`}
                          icon={<LinkedinIcon size={20} />}
                        />
                      )}
                      {person.person?.twitter?.handle && (
                        <IconButton
                          minWidth="0"
                          variant="link"
                          color="gray.400"
                          as={Link}
                          isExternal
                          aria-label="Twitter link"
                          href={`https://twitter.com/${person.person.twitter?.handle}`}
                          icon={<TwitterIcon size={20} />}
                        />
                      )}
                    </HStack>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Flex>
    </Stack>
  )
}
