import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Persona } from './personas'
import {
  GridItem,
  Avatar,
  Text,
  Badge,
  IconButton,
  Link,
  VStack,
  HStack,
  Box,
  Center,
  Flex,
  ButtonGroup,
  Tooltip
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconArrowLeft,
  IconAt,
  IconMailX,
  IconMailSearch,
  IconPhoneCheck,
  IconPhoneX,
  IconPhonePlus
} from '@tabler/icons-react'
import { SalesforceIcon, AiSparklesIcon, LinkedinBoxIcon, KoalaIcon } from '@app/components/ui/icons'
import { Prospect } from '../../data/use-prospects'
import { HubSpotIcon } from '@app/components/ui/icons/HubspotIcons'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { profilePath } from '../profiles/lib/path'
import { accountPath } from '../accounts/lib/account-path'
import { ActionMenu } from './action-menu'
import { post } from '../../../lib/api'
import { toast } from 'sonner'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'

export function ProspectsGrid({
  prospects,
  domain,
  isLoading,
  apps,
  account,
  actions,
  context,
  setPage,
  pageMeta
}: {
  prospects: Prospect[]
  domain: string
  isLoading?: boolean
  apps: Apps
  account: Account
  actions?: boolean
  context?: 'explore' | 'account_page' | 'triangulation'
  setPage: (page: number | null) => void
  pageMeta?: {
    next_page: number | null
    total_pages: number | null
    current_page: number | null
  }
}) {
  const scrollRef = React.useRef<HTMLDivElement | null>(null)
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const { next_page, total_pages, current_page } = pageMeta!
  const [localProspects, setLocalProspects] = React.useState<Prospect[]>(prospects)

  const hasMore = useMemo(() => current_page !== total_pages, [current_page, total_pages])

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 400,
        behavior: 'smooth'
      })
    }
  }

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -400,
        behavior: 'smooth'
      })
    }
  }

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current
      setIsAtStart(scrollLeft === 0)
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 10) // Leave some room for extra spaces
    }
  }

  const loadMore = useCallback(async () => {
    if (hasMore) {
      setPage(next_page)
    }
  }, [next_page, setPage, hasMore])

  useEffect(() => {
    const currentScroll = scrollRef.current
    if (currentScroll) {
      currentScroll.addEventListener('scroll', checkScrollPosition)
      checkScrollPosition()
    }
    return () => {
      if (currentScroll) {
        currentScroll.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [])

  const endOfListRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const option = {
      root: scrollRef.current,
      rootMargin: '0px',
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        loadMore()
      }
    }, option)

    if (endOfListRef.current) {
      observer.observe(endOfListRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [loadMore])

  return (
    <VStack>
      <Box w="full" maxW="1200px" h="100%" overflow="hidden">
        <HStack
          ref={scrollRef}
          w="full"
          maxW="100%"
          spacing="4"
          align="start"
          overflowX="scroll"
          justify="flex-start" // Ensure cards are aligned to the right
          css={{
            '&::-webkit-scrollbar': {
              display: 'none' // Hides the scrollbar
            },
            '-ms-overflow-style': 'none', // Hides scrollbar in IE and Edge
            'scrollbar-width': 'none' // Hides scrollbar in Firefox
          }}
        >
          {localProspects.map((prospect) => (
            <ProspectCard
              key={prospect.id}
              prospect={prospect}
              domain={domain}
              apps={apps}
              actions={actions}
              context={context}
              account={account}
              isLoading={isLoading}
              onProspectChange={(updatedProspect) => {
                setLocalProspects((prev) => {
                  return prev.map((p) => {
                    if (p.id === updatedProspect.id) {
                      return updatedProspect
                    }
                    return p
                  })
                })
              }}
            />
          ))}

          <Center paddingTop={10} paddingBottom={20} h="260px">
            <Flex w={200} flexDirection="column" alignItems="center">
              {hasMore ? (
                <Box ref={endOfListRef} width="200px">
                  {isLoading && (
                    <Text fontSize="sm" color="gray.600">
                      Loading more...
                    </Text>
                  )}
                </Box>
              ) : (
                <>
                  <CompanyAvatar size="sm" domain={domain} marginBottom="1em" />
                  <Link
                    href={projectPath(`/prospector?domain=${domain}`)}
                    color="gray.500"
                    fontSize="sm"
                    _hover={{ textDecoration: 'none', color: 'gray.600' }}
                    textAlign={'center'}
                  >
                    Other people from {domain}
                  </Link>
                </>
              )}
            </Flex>
          </Center>
        </HStack>
      </Box>
      {!isAtStart && (
        <IconButton
          aria-label="Scroll Left"
          icon={<IconArrowLeft />}
          onClick={handleScrollLeft}
          position="absolute"
          left="-20px"
          top="40%"
          transform="translateY(-40%)"
          zIndex="1"
          size="lg"
          variant="outline"
          color="blackAlpha.500"
          background="white"
          borderRadius={25}
        />
      )}

      {!isAtEnd && (
        <IconButton
          aria-label="Scroll Right"
          icon={<IconArrowRight />}
          onClick={handleScrollRight}
          position="absolute"
          right="-20px"
          top="40%"
          transform="translateY(-40%)"
          zIndex="1"
          size="lg"
          variant="outline"
          color="blackAlpha.500"
          backgroundColor="white"
          borderRadius={25}
        />
      )}
    </VStack>
  )
}

function ProspectCard({
  prospect,
  domain,
  isLoading,
  apps,
  account,
  context,
  persona,
  onProspectChange
}: {
  prospect: Prospect
  persona?: Persona
  domain: string
  isLoading?: boolean
  apps: Apps
  account: Account
  actions?: boolean
  context?: 'explore' | 'account_page' | 'triangulation'
  onProspectChange: (prospect: Prospect) => void
}) {
  const isLocked = prospect.unlock_state === 'locked' || prospect.unlock_state === null
  const isPhoneLocked = prospect.phone_state === 'locked' || prospect.phone_state === null
  const emailAvailable =
    prospect.email && (prospect.unlock_state === 'unlocked' || prospect.unlock_state === 'auto-unlocked')
  const phoneAvailable = prospect.phone_numbers?.mobile && prospect.phone_state === 'unlocked'

  const [isEmailLoading, setIsEmailLoading] = useState(false)
  const [isPhoneLoading, setIsPhoneLoading] = useState(false)

  const onUnlockPhone = useCallback(
    (prospect: Prospect) => {
      setIsPhoneLoading(true)
      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock-phone`), {
        context
      })
        .then((res) => {
          if (res.prospect.phone_state === 'unlocked') {
            toast.success('Phone number unlocked')
            onProspectChange(res.prospect)
          } else if (res.prospect.phone_state === 'not_found') {
            toast.warning('Phone number not found')
          }
        })
        .catch(() => {
          toast.error('Failed to unlock phone number')
        })
        .finally(() => {
          setIsPhoneLoading(false)
        })
    },
    [domain, context, onProspectChange]
  )

  const onEmailUnlock = useCallback(
    (prospect: Prospect) => {
      setIsEmailLoading(true)
      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          dismissible: true
        })
      }, 2500)

      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock`), {
        context
      })
        .then((res) => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked')
            onProspectChange(res.prospect)
          } else {
            toast.warning(`Could not find email for ${res.prospect.first_name} ${res.prospect.last_name}`)
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setIsEmailLoading(false)
        })
    },
    [domain, context, onProspectChange]
  )

  return (
    <GridItem
      w="100%"
      minW="300px"
      h="260px"
      p="4"
      borderWidth="1px"
      borderRadius="lg"
      bgGradient={'linear(to-b, blue.50, transparent)'}
      backgroundSize="100% 50%"
      backgroundRepeat="no-repeat"
    >
      <VStack spacing="4" align="start">
        <HStack justify="space-between" w="full" visibility={'hidden'} minH="20px">
          {prospect.rank?.match(/high/) && (
            <>
              <HStack>
                <AiSparklesIcon color="grey" boxSize={3} />
                <Text fontSize="xs">Suggested based on hints</Text>
              </HStack>
              <Badge
                textTransform="capitalize"
                variant="solid"
                colorScheme="teal"
                fontSize="10px"
                borderRadius="10"
                paddingX="1.5"
                paddingY="1px"
              >
                Strong match
              </Badge>
            </>
          )}
        </HStack>

        <VStack spacing="4" align="start">
          <Avatar
            src={projectPath(`/accounts/${domain}/prospects/${prospect.id}/avatar`)}
            name={prospect.first_name + ' ' + prospect.last_name}
            size="lg"
            border="3px solid white"
          />
          <VStack align="start" spacing="0">
            <HStack spacing={1} mb={0.5}>
              <Text color="gray.800" fontWeight="medium" fontSize="sm" lineHeight="20px">
                {prospect.first_name} {prospect.last_name}
              </Text>

              <ButtonGroup size="tiny" variant="ghost" spacing={0.5} py="0">
                {prospect.linkedin_url && (
                  <IconButton
                    as={Link}
                    icon={<LinkedinBoxIcon color={'linkedin.700'} boxSize="18px" />}
                    aria-label="View LinkedIn Profile"
                    href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                    isExternal
                  />
                )}

                {(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                  <Tooltip label="View in Salesforce">
                    <IconButton
                      as={Link}
                      icon={<SalesforceIcon color="salesforce" boxSize="18px" />}
                      aria-label="View in Salesforce"
                      href={prospect.salesforce_contact_cache?.permalink ?? prospect.salesforce_lead_cache?.permalink}
                      isExternal
                    />
                  </Tooltip>
                )}

                {prospect.hubspot_contact_cache &&
                  !(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                    <Tooltip label="View in HubSpot">
                      <IconButton
                        as={Link}
                        icon={<HubSpotIcon color="hubspot" boxSize="18px" />}
                        aria-label="View in HubSpot"
                        href={prospect.hubspot_contact_cache?.permalink}
                        isExternal
                      />
                    </Tooltip>
                  )}

                {prospect.profile && (
                  <Tooltip label="View activity in Koala">
                    <IconButton
                      as={Link}
                      icon={<KoalaIcon color="purple.500" boxSize="18px" />}
                      aria-label="View activity in Koala"
                      href={profilePath(prospect.profile)}
                      isExternal
                    />
                  </Tooltip>
                )}
              </ButtonGroup>
            </HStack>

            <Text isTruncated fontSize="sm" color="gray.800" maxW="260px" title={prospect.title}>
              {prospect.title}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {[prospect.city, prospect.country].join(', ')}
            </Text>
          </VStack>
        </VStack>

        <HStack w="full" justify="space-between">
          <ButtonGroup isAttached colorScheme="purple" variant="solid" borderWidth="thin" borderRadius={5} spacing="-1">
            <Tooltip label={emailAvailable ? `Copy to clipboard: ${prospect.email}` : undefined}>
              <IconButton
                aria-label="Email"
                role="group"
                color={emailAvailable ? 'purple.500' : 'gray.500'}
                icon={
                  emailAvailable ? (
                    <IconAt size="14" />
                  ) : prospect.unlock_state === 'not_found' ? (
                    <IconMailX size="14" />
                  ) : (
                    <IconMailSearch size="14" />
                  )
                }
                size="sm"
                variant={'ghost'}
                _hover={isLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                colorScheme={prospect.unlock_state === 'not_found' ? 'red' : 'gray'}
                isLoading={isEmailLoading || isLoading}
                onClick={() => {
                  if (emailAvailable) {
                    navigator.clipboard.writeText(prospect.email!)
                    toast.success('Email copied to clipboard', {
                      position: 'bottom-right'
                    })
                    window.ko?.track('Prospect Email Copied', {
                      email: prospect.email,
                      prospected_profile_id: prospect.id,
                      company: domain,
                      context: context
                    })
                    return
                  }

                  onEmailUnlock(prospect)
                }}
              >
                {emailAvailable && prospect.email}
                {isLocked && 'Find email'}
                {prospect.unlock_state === 'not_found' && 'Email not found'}
              </IconButton>
            </Tooltip>
            <Tooltip label={phoneAvailable ? `Copy to clipboard: ${prospect.phone_numbers!.mobile!}` : undefined}>
              <IconButton
                aria-label="Phone"
                color={phoneAvailable ? 'purple.500' : 'gray.500'}
                role="group"
                _hover={isPhoneLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                icon={
                  prospect.phone_state === 'unlocked' ? (
                    <IconPhoneCheck size="14" />
                  ) : prospect.phone_state === 'not_found' ? (
                    <IconPhoneX size="15" />
                  ) : (
                    <IconPhonePlus size="14" />
                  )
                }
                colorScheme={
                  prospect.phone_state === 'unlocked' ? 'gray' : prospect.phone_state === 'not_found' ? 'red' : 'gray'
                }
                size="sm"
                variant={'ghost'}
                isLoading={isPhoneLoading || isLoading}
                onClick={() => {
                  if (phoneAvailable) {
                    navigator.clipboard.writeText(prospect.phone_numbers!.mobile!)
                    toast.success('Phone number copied to clipboard', {
                      position: 'bottom-right'
                    })
                    window.ko?.track('Prospect Phone Copied', {
                      email: prospect.email,
                      prospected_profile_id: prospect.id,
                      company: domain,
                      context: context
                    })
                    return
                  }

                  onUnlockPhone(prospect)
                }}
              >
                {phoneAvailable && prospect.phone_numbers?.mobile}
                {isPhoneLocked && 'Find phone'}
                {prospect.phone_state === 'not_found' && 'Phone not found'}
              </IconButton>
            </Tooltip>
          </ButtonGroup>

          <ActionMenu
            isButtonGroup
            isIconButton
            prospect={prospect}
            domain={domain}
            isLoading={isLoading}
            apps={apps}
            account={account}
            persona={persona}
            context={context}
            onEmailLoadingChange={setIsEmailLoading}
            onPhoneLoadingChange={setIsPhoneLoading}
            onChange={onProspectChange}
            iconButtonProps={{
              'aria-label': 'Actions',
              color: 'gray.400',
              variant: 'ghost',
              _hover: { bg: 'transparent', color: 'purple.500' },
              marginRight: -3
            }}
          />
        </HStack>
      </VStack>
    </GridItem>
  )
}
