import { Box, HStack, Icon, Spinner, Circle, Text } from '@chakra-ui/react'
import Avatar from './Avatar'
import { UserCircleIcon } from './icons'
import React, { useCallback, useMemo } from 'react'
import { useUsers } from '../data/use-users'
import { useCurrentUser } from './UserContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'

interface PartialUser {
  id?: string
  name?: string
  email?: string
  avatar?: string
  you?: boolean
}

const unassigned: PartialUser = {
  id: '',
  name: 'No assignee'
}

interface UserSelectProps {
  selectedUserId?: string | null
  onChange?: (userId: string | null) => void
}

export function UserSelector({ selectedUserId, onChange }: UserSelectProps) {
  const { data, isLoading } = useUsers()
  const currentUser = useCurrentUser()

  const users: PartialUser[] = useMemo(() => {
    return [
      unassigned,
      ...(data?.users ?? []).map((u) => ({
        ...u,
        you: u.id === currentUser?.id
      }))
    ]
  }, [data, currentUser])

  const selected = useMemo(() => {
    return users.find((u) => u.id === selectedUserId) || null
  }, [users, selectedUserId])

  const handleChange = useCallback(
    (user) => {
      onChange?.(user?.id || null)
    },
    [onChange]
  )

  if (isLoading) {
    return (
      <Box minHeight="42px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  return (
    <ComboboxWithSearch
      items={users}
      selectedItem={selected || unassigned}
      onChange={handleChange}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name || ''}
      itemRenderer={UserRenderer}
      selectButtonRenderer={UserRenderer}
    />
  )
}

interface UserRendererProps {
  item: PartialUser | null
  selectedItem?: PartialUser | null
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item || unassigned

  return (
    <HStack spacing={2}>
      {user === unassigned ? (
        <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.300" overflow="hidden">
          <Icon boxSize="15px" bg="gray.300" as={UserCircleIcon} color="white" />
        </Circle>
      ) : (
        <Avatar size="tiny" name={user.name || user.email} src={user.avatar} />
      )}
      <Text fontSize="sm" fontWeight="medium">
        {user.name || user.email || 'Unknown'}
        {user.you ? ' (you)' : ''}
      </Text>
    </HStack>
  )
}
