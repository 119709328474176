import { Box, Button, Flex, Link, Stack, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import router from '../../../../../lib/router'
import type { Account } from '../../../../../types/Account'
import type { App, Apps } from '../../../../../types/App'
import { ProjectActions } from '../../../../../types/AppActions'
import { KQLByDay } from '../../../../../types/KQL'
import { openUpgradeFlow } from '../../../../ui/billing-banners/accounts-banner'
import { LightBgCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { AiSparklesIcon } from '../../../../ui/icons'
import { ListsCard } from '../../../../ui/ListsCard'
import { useCurrentProject } from '../../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../../ui/SegmentedControl'
import { AccountUpgrade, isLimitedAccount, useEntitlements } from '../../../../ui/useEntitlements'
import { useSearchParams } from '../../../../ui/useSearchState'
import { ImportAccountSideSheet } from '../../../account_views/components/ImportAccountSideSheet'
import { billingPlansPath } from '../../../billing/lib/path-helpers'
import { KqlDefinition } from '../../../kql_definitions/types'
import { AutoProspectCard } from '../../../profiles/components/AutoProspectCard'
import { AccountIntentFeedV3 } from '../../../profiles/components/profile-feed'
import { ProspectorCTA } from '../../../prospector/components/ProspectorCTA'
import { accountPath } from '../../lib/account-path'
import { AccountIntegrationSidebar } from '../AccountIntegrationSidebar'
import { AccountEvents } from './AccountEvents'
import { ActivityCard } from './ActivityCard'
import { Analytics } from './Analytics'
import { CompanyDetailsCard } from './CompanyDetailsCard'
import { GhostProfiles } from './GhostProfiles'
import { InactiveAccount } from './InactiveAccount'
import { NewsCard } from './NewsCard'
import { SummaryCard } from './SummaryCard'
import { TechStackCard } from './TechStackCard'
import { TraitsCard } from './TraitsCard'
import { Visitors } from './Visitors'

interface Props {
  account: Account
  actions: ProjectActions
  apps: Apps
  signalsByDay: KQLByDay[]
  signalDefns: KqlDefinition[]
}

export default function DetailsPage(props: Props) {
  const { searchParams, setSearchParam } = useSearchParams({
    tab: 'feed'
  })

  const tab = useMemo(() => searchParams.tab as string, [searchParams.tab])
  const setTab = (tab: string) => setSearchParam('tab', tab)

  const [importApp, setImportApp] = React.useState<App | undefined>(undefined)

  const resetImport = React.useCallback(async () => {
    setImportApp(undefined)
    // refresh current state
    router.visit(accountPath(props.account))
  }, [props.account])

  const entitlements = useEntitlements()
  const isRedacted = useMemo(() => {
    if (!entitlements) return false
    return isLimitedAccount(entitlements, props.account)
  }, [entitlements, props.account])

  useEffect(() => {
    if (isRedacted) {
      window.history.replaceState(
        {},
        'Locked Account',
        accountPath({
          company: {
            domain: props.account.company_id
          }
        })
      )
    }
  }, [isRedacted, props.account.company_id])

  const smallScreen = useBreakpointValue({ base: true, lg: false, xl: false })
  const stacked = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false })

  return (
    <Flex gap={[6, 6, 6, 6, 8]} alignItems="flex-start">
      <Stack flexGrow={1} flexShrink={1} minWidth="320px" maxWidth="100%" spacing={6}>
        <Stack spacing={4}>
          {isRedacted ? (
            <LightBgCard justifyContent={'center'} alignItems={'center'} borderColor={'orange.400'} bg="orange.50">
              <AccountUpgrade
                type="Account"
                colorScheme={'orange'}
                element={props.account}
                showLock
                entitlements={entitlements}
              />
            </LightBgCard>
          ) : (
            <SummaryCard account={props.account} scoringEnabled={entitlements?.icp_scoring ?? false} />
          )}

          {props.account.ghost && <GhostProfiles account={props.account} />}
          {props.account.inactive && !props.account.ghost && <InactiveAccount account={props.account} />}

          {!props.account.inactive && (
            <ActivityCard
              focusTrend={props.account.focus_time_trend}
              pageviewTrend={props.account.page_views_trend}
              signalsByDay={props.signalsByDay}
              signalDefns={props.signalDefns}
              intent={props.account.intent}
            />
          )}

          <ProspectorCTA account={props.account} />

          {stacked && (
            <>
              <TraitsCard object={props.account} initiallyCollapsed />
              <ListsCard kind="account" recordId={props.account.id} />
              <AccountIntegrationSidebar
                {...props}
                onRequestImport={setImportApp}
                isRedacted={isRedacted}
                initiallyCollapsed
              />
            </>
          )}
        </Stack>

        <SegmentedControl
          display="grid"
          width="100%"
          size={smallScreen ? 'sm' : 'md'}
          maxWidth="100%"
          alignSelf="center"
          gridTemplateColumns={`1fr 1px 1fr 1px 1fr 1px 1fr 1px 1fr`}
        >
          <Button isActive={tab == 'feed'} onClick={() => setTab('feed')}>
            Activity
          </Button>
          <Button isActive={tab == 'visitors'} onClick={() => setTab('visitors')}>
            Visitors
          </Button>
          <Button
            isActive={tab == 'prospects'}
            onClick={() => setTab('prospects')}
            leftIcon={<AiSparklesIcon color="accent.500" boxSize={4} />}
            iconSpacing={1.5}
          >
            Prospector
          </Button>
          <Button isActive={tab == 'analytics'} onClick={() => setTab('analytics')}>
            Analytics
          </Button>
          <Button isActive={tab == 'company'} onClick={() => setTab('company')}>
            Company
          </Button>
        </SegmentedControl>
        <Box flex="1" paddingBottom={tab !== 'prospects' ? '100px' : undefined}>
          {tab === 'feed' ? (
            <AccountIntentFeedV3 account={props.account} />
          ) : tab === 'visitors' ? (
            <Visitors account={props.account} />
          ) : tab === 'analytics' ? (
            <Analytics account={props.account} />
          ) : tab === 'company' ? (
            <CompanyDetails account={props.account} isRedacted={isRedacted} />
          ) : tab === 'prospects' ? (
            <AutoProspectCard
              apps={props.apps}
              account={props.account}
              company={props.account.company}
              headerProps={{
                bg: 'gray.50'
              }}
            />
          ) : tab == 'events' ? (
            <AccountEvents account={props.account} />
          ) : null}
        </Box>
      </Stack>

      {!stacked && (
        <Stack flex="none" width={['100%', '100%', '320px', '320px', '340px', '360px']} maxWidth="100%" spacing={4}>
          <TraitsCard object={props.account} initiallyCollapsed />
          <ListsCard kind="account" recordId={props.account.id} />
          <AccountIntegrationSidebar {...props} onRequestImport={setImportApp} isRedacted={isRedacted} />
        </Stack>
      )}

      {props.account.company_id && importApp && (
        <ImportAccountSideSheet
          key={props.account.company_id}
          app={importApp}
          account={props.account}
          onClose={resetImport}
        />
      )}
    </Flex>
  )
}

function CompanyDetails(props: { account: Account; isRedacted?: boolean }) {
  const project = useCurrentProject()

  if (props.isRedacted) {
    return (
      <LightBgCard>
        <CardHeading icon="https://logo.clearbit.com/clearbit.com">Clearbit</CardHeading>
        <Link
          href={billingPlansPath()}
          fontSize={'sm'}
          pt="2"
          onClick={(e) => {
            e.preventDefault()
            openUpgradeFlow()
          }}
        >
          Unlock Account to view
        </Link>
      </LightBgCard>
    )
  }

  return (
    <Stack spacing={4}>
      <CompanyDetailsCard account={props.account} />
      {props.account.company_technologies && (
        <TechStackCard
          account={{
            company_technologies: props.account.company_technologies
          }}
        />
      )}
      {['koala', 'vercel'].includes(project?.slug ?? '') && <NewsCard company={props.account.company} />}
    </Stack>
  )
}
