import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Textarea,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import React, { useCallback, useRef } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../lib/api'

export function openWishForm(detail: Omit<FeedbackFormProps, 'projectSlug'> = {}) {
  window.dispatchEvent(new CustomEvent('openfeedback', { detail }))
}

interface FeedbackFormProps {
  projectSlug?: string
  title?: string
  placeholder?: string
  placement?: PopoverProps['placement']
  variant?: 'modal' | 'popover'
  preamble?: string
}

export function FeedbackForm(props: React.PropsWithChildren<FeedbackFormProps>) {
  const [message, setMessage] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const [title, setTitle] = React.useState(props.title || '')
  const [placeholder, setPlaceholder] = React.useState(props.placeholder || '')
  const [placement, setPlacement] = React.useState(props.placement || 'bottom-end')
  const [variant, setVariant] = React.useState(props.variant || 'popover')
  const [preamble, setPreamble] = React.useState(props.preamble || '')

  React.useEffect(() => {
    if (props.title) setTitle(props.title)
    if (props.placeholder) setPlaceholder(props.placeholder)
    if (props.placement) setPlacement(props.placement)
    if (props.variant) setVariant(props.variant)
    if (props.preamble) setPreamble(props.preamble)
  }, [props.title, props.placeholder, props.variant, props.placement, props.preamble])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  React.useEffect(() => {
    const onOpenFeedback = (event) => {
      const newProps = (event as CustomEvent<Omit<FeedbackFormProps, 'projectSlug'>>).detail ?? {}

      if (newProps.title) setTitle(newProps.title)
      if (newProps.placeholder) setPlaceholder(newProps.placeholder)
      if (newProps.placement) setPlacement(newProps.placement)
      if (newProps.variant) setVariant(newProps.variant)
      if (newProps.preamble) setPreamble(newProps.preamble)

      onOpen()
    }

    window.addEventListener('openfeedback', onOpenFeedback)

    return () => {
      window.removeEventListener('openfeedback', onOpenFeedback)
    }
  }, [onOpen])

  const formId = 'feedback-form'
  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setSubmitting(true)

      const data = new FormData(event.target as HTMLFormElement)
      data.append('title', document.title)
      data.append('preamble', preamble || '')

      try {
        const path = `${props.projectSlug ? `/projects/${props.projectSlug}` : ''}/feedback`
        await postForm(path, data)
        setMessage('')
        toast('🎉  Thanks, we got your message!')

        setSubmitting(false)
        onClose()
      } catch (_e) {
        toast.error('There was an issue receiving your message.')
        setSubmitting(false)
      }
    },
    [onClose, props.projectSlug, preamble]
  )

  const trigger = (
    <Box flex="0 0 auto" onClick={onOpen}>
      {props.children}
    </Box>
  )

  const form = (
    <form id={formId} onSubmit={onSubmit}>
      <Textarea
        ref={initialFocusRef}
        name="message"
        width="100%"
        variant="unstyled"
        resize="none"
        fontSize="sm"
        value={message}
        rows={6}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={placeholder ?? 'Wish for anything! Help us make the app better.'}
      />
    </form>
  )

  const footer = (
    <>
      <Button variant="outline" size="sm" onClick={onClose}>
        Cancel
      </Button>
      <Button type="submit" form={formId} colorScheme="purple" size="sm" isDisabled={!message} isLoading={submitting}>
        Send
      </Button>
    </>
  )

  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })
  if (isSmall || variant === 'modal') {
    return (
      <>
        {trigger}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title || 'Share feedback'}</ModalHeader>
            <ModalBody fontSize="sm">{form}</ModalBody>
            <ModalFooter justifyContent="flex-end" gap={3}>
              {footer}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      placement={placement || 'bottom-end'}
      isLazy
      closeOnBlur={false}
      initialFocusRef={initialFocusRef}
      onClose={onClose}
      onOpen={onOpen}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent maxWidth="350px" fontSize="sm" shadow="md">
          <PopoverArrow />
          <PopoverBody>{form}</PopoverBody>
          <PopoverFooter
            display="flex"
            gap={3}
            bg="gray.50"
            borderBottomRadius="md"
            flexDirection="row-reverse"
            justifyContent="space-between"
          >
            {footer}
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
