import { CircularProgress, Flex, Heading } from '@chakra-ui/react'
import type { KoalaSDK } from '@getkoala/browser'
import { deepEqual } from 'fast-equals'
import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Toaster } from 'sonner'
import { fetchURL } from '../lib/api'
import { MetaVisit } from '../lib/router'
import { NewLayout, DefaultLayout } from './DefaultLayout'
import { ErrorPage } from './pages/errors/components/ErrorPage'
import PageNotFound from './pages/errors/PageNotFound'
import TooManyRequests from './pages/errors/TooManyRequests'
import { GrayAccountsBanner, PricingFormFlow, UpgradeFlow } from './ui/billing-banners/accounts-banner'
import BillingBanners from './ui/BillingBanners'
import ErrorBoundary from './ui/ErrorBoundary'
import Notices, { FlashMessages } from './ui/Notices'
import Omnisearch from './ui/Omnisearch'
import { useCurrentProject } from './ui/ProjectsContext'
import { ScheduledForDeletionBanner } from './ui/ScheduledForDeletionBanner'
import { TopNav } from './ui/TopNav'
import { useCurrentUser } from './ui/UserContext'
import { useLayoutMode } from './ui/useLayoutMode'
import { KoalaUDialog } from './ui/KoalaUDialog'
import dayjs from 'dayjs'

interface ReactData {
  component_path: string
  props: object
  flash?: {
    notice?: string
    error?: string
    warning?: string
  }
}

declare global {
  interface Window {
    ko?: KoalaSDK
    component_path: string
    component_props: any
    layout_mode?: string | null
  }
}

interface PageComponent {
  url: string
  pathname: string
  path: string
  childProps: any
  flash?: FlashMessages | undefined
}

function DefaultError() {
  return (
    <ErrorPage
      title={
        <Heading as="h1" size="lg">
          😬 There was an error loading this page.
        </Heading>
      }
      description="We've been notified of the error. In the meantime, you can try reloading the page."
    />
  )
}

const requireServerNav = ['/users/', '/auth/', '/login', '/signup', '/logout', '/terms', '/privacy']
const requiresServerNav = (url: string) => {
  const projectsMayChange = window.location.pathname === '/join' || window.location.pathname === '/welcome'
  const isProjectUrl = url.includes('/projects')
  return (projectsMayChange && isProjectUrl) || requireServerNav.some((path) => url.includes(path))
}

const components = import.meta.globEager('./pages/**/*.tsx')
const isProdEnv = import.meta.env.PROD

export function scrollToTop(options: ScrollIntoViewOptions = { behavior: 'auto' }) {
  const elements = document.querySelectorAll('.scroll-into-view')
  for (const element of elements) {
    element?.scrollIntoView({ behavior: 'auto', ...options })
  }
}

export default function Page() {
  const project = useCurrentProject()
  const user = useCurrentUser()

  const [showOmnisearch, setShowOmnisearch] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const [delayedLoading, setDelayedLoading] = React.useState(false)
  const [error, setError] = React.useState<null | number>(null)

  const supportMode = React.useMemo(() => {
    const accessibleProjectIds = user.project_ids ?? []
    return project && !accessibleProjectIds.includes(project.id)
  }, [project, user])

  React.useEffect(() => {
    if (isProdEnv && user?.email) {
      window.ko?.identify({ email: user.email })
    }
  }, [user])

  const [pageComponent, setPageComponent] = React.useState<PageComponent | null>({
    url: window.location.pathname,
    pathname: window.location.pathname,
    path: window.component_path,
    childProps: window.component_props,
    flash: {
      notice: window.notice,
      error: window.flashError,
      warning: window.flashWarning
    }
  })

  // Keep window.component_path in sync with the current pageComponent
  React.useEffect(() => {
    if (pageComponent?.path) {
      window.component_path = pageComponent.path
    }
  }, [pageComponent?.path])

  // Keep window.component_props in sync with the current pageComponent
  React.useEffect(() => {
    if (pageComponent?.childProps) {
      window.component_props = pageComponent.childProps
    }
  }, [pageComponent?.childProps])

  const child = React.useMemo(() => {
    let componentKey
    if (pageComponent) {
      componentKey = Object.keys(components).find((c) => c.startsWith(`./${pageComponent.path}`))
    }

    const component: any = components[componentKey]
    return {
      component: componentKey ? component?.default : null,
      childProps: pageComponent?.childProps,
      pathname: pageComponent?.pathname
    }
  }, [pageComponent])

  React.useEffect(() => {
    scrollToTop()
  }, [child.component])

  const urlRef = React.useRef<string>(window.location.pathname + window.location.search)

  React.useEffect(() => {
    const loadUrl = (url: string) => {
      let newPath = url

      try {
        newPath = new URL(url, window.location.origin).pathname
      } catch (_error) {
        // do nothing
      }

      // Keep track every navigation, so we only update the component based on the latest navigation
      urlRef.current = url
      setLoading(true)
      setError(null)

      const timer = setTimeout(() => {
        setDelayedLoading(true)
      }, 1000)

      fetchURL(url)
        .on('data', (res: ReactData, _cached: boolean) => {
          clearTimeout(timer)

          if (urlRef.current !== url) {
            return
          }

          setPageComponent((c) => {
            if (
              c?.pathname === newPath &&
              c.path === res.component_path &&
              deepEqual(c.childProps, res.props) &&
              deepEqual(c.flash, res.flash)
            ) {
              return c
            } else {
              return { url, pathname: newPath, path: res.component_path, childProps: res.props, flash: res.flash }
            }
          })
          setDelayedLoading(false)
          setLoading(false)

          window.dispatchEvent(new CustomEvent('pagefetch'))
        })
        .on('error', (err) => {
          clearTimeout(timer)

          if (urlRef.current !== url) {
            return
          }

          // Ignore canceled navigations
          if (err.name === 'AbortError') {
            return
          }

          setLoading(false)
          setDelayedLoading(false)
          // Clear out the current page when we navigate, but encounter an error
          // this prevents a weird inbetween state of a new url, but the old page, not realizing there was an error
          setPageComponent(null)
          setError(err?.statusCode ?? 500)
        })
    }

    const beforeLocationChange = (event) => {
      const url = (event as CustomEvent<{ url: string }>).detail?.url ?? window.location.href
      if (requiresServerNav(url)) {
        window.location.href = url
        event.preventDefault()
        return
      }
    }

    const onPopState = (e: Event) => {
      const url = window.location.href
      if (requiresServerNav(url)) {
        return
      }

      e.preventDefault()
      loadUrl(url)
    }

    const onLoadPage = (event: Event) => {
      const detail = (event as CustomEvent<{ url: string; meta: MetaVisit }>).detail
      const url = detail?.url ?? window.location.href
      const meta = detail.meta
      if (meta.fetch !== false) {
        loadUrl(url)
      }
    }

    window.addEventListener('router:before-visit', beforeLocationChange)
    window.addEventListener('popstate', onPopState)
    window.addEventListener('router:visit', onLoadPage)

    return () => {
      window.removeEventListener('router:before-visit', beforeLocationChange)
      window.removeEventListener('popstate', onPopState)
      window.removeEventListener('router:visit', onLoadPage)
    }
  }, [])

  const ChildComponent = child.component as React.ComponentType<any> | null
  const showNav = Boolean(project || user.id)
  const layoutMode = useLayoutMode()
  const hideNav = window.location.search.includes('nav=false') || layoutMode === 'new'

  useHotkeys(
    'ctrl+k,cmd+k',
    () => {
      if (user.id && project) {
        setShowOmnisearch((prev) => !prev)
      }
    },
    { enableOnTags: ['INPUT'] },
    [user, project]
  )

  const onSearchClick = React.useCallback(() => setShowOmnisearch(true), [])

  React.useEffect(() => {
    if (showOmnisearch) {
      window.ko?.track('Omnisearch Opened')
    }
  }, [showOmnisearch])

  const Layout = layoutMode === 'new' ? NewLayout : DefaultLayout

  const daysAgo = dayjs().diff(dayjs(project?.created_at), 'day')
  const displayKoalaUDialog =
    !/\/onboarding|\/mission-control|\/welcome/.test(window.location.pathname) &&
    !project?.mission_control_enabled &&
    daysAgo > 2

  return (
    <>
      <Flex direction="column" w="100%" minHeight="100vh" alignItems="stretch">
        <div className="scroll-into-view" />

        {showNav && (
          <>
            {project?.deleted_at && <ScheduledForDeletionBanner />}
            {!hideNav && <TopNav onSearchClick={onSearchClick} supportMode={supportMode} />}
            {!project?.deleted_at && <BillingBanners />}
          </>
        )}
        {showOmnisearch && <Omnisearch onClose={() => setShowOmnisearch(false)} />}
        {project?.koala_subscription && <UpgradeFlow />}
        {project && <PricingFormFlow />}

        {project && displayKoalaUDialog && <KoalaUDialog />}

        <Flex as="main" direction="column" flex={1} position="relative">
          <GrayAccountsBanner />
          {ChildComponent ? (
            <ErrorBoundary errorView={<DefaultError />}>
              <Layout
                path={child.pathname}
                loading={loading}
                takingAwhile={delayedLoading}
                onSearchClick={onSearchClick}
                supportMode={supportMode}
                layoutMode={layoutMode}
              >
                <ChildComponent key={child.pathname} {...child.childProps} />
              </Layout>
            </ErrorBoundary>
          ) : loading ? (
            <Flex flex="1" w="100%" h="100%" justifyContent="center" alignItems="flex-start" paddingTop="80px">
              <CircularProgress size="8" isIndeterminate color="purple.500" thickness="5px" />
            </Flex>
          ) : error && error === 429 ? (
            <TooManyRequests />
          ) : error && error > 404 ? (
            <DefaultError />
          ) : error && error >= 400 && error <= 403 ? (
            <ErrorPage
              showLogIn
              title={
                <Heading as="h1" size="lg">
                  🔒 You are not authorized to access this page.
                </Heading>
              }
            />
          ) : (
            <PageNotFound />
          )}
        </Flex>
      </Flex>

      <Toaster visibleToasts={4} toastOptions={{ className: 'ko-toast' }} richColors position="bottom-center" />
      <Notices flash={pageComponent?.flash} />
    </>
  )
}
