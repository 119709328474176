import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Wrap
} from '@chakra-ui/react'
import { IconAccessibleOff, IconCheck, IconClockExclamation, IconHelpCircle } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { AdminBreadcrumb } from '..'
import { post, postForm } from '../../../../lib/api'
import dayjs, { formatDate } from '../../../../lib/dayjs'
import router from '../../../../lib/router'
import { PageMeta } from '../../../../types/PageMeta'
import { Project } from '../../../../types/Project'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { Card } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import Middot, { MiddotDivider } from '../../../ui/Middot'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { TableFooter } from '../../../ui/TableFooter'
import { TimeAgo } from '../../../ui/TimeAgo'
import useLocation from '../../../ui/useLocation'
import { User } from '../../../ui/UserContext'
import { SearchBar } from '../../accounts/facets/search-bar'
import { KoalaSubscription } from '../../billing/show'
import { mergeParams } from '../../icps/types'

type LogEntry = {
  action: string
  data: object
  actor: string
}

interface KoalaEmployeeRequest {
  id: string
  project_id: string
  user_id: string
  expires_at: string
  approved_at: string
  denied_at: string
  approved_by_id: string
  reason: string
  user?: User
}

type AdminSub = KoalaSubscription & { metadata: Record<string, any> } & {
  project: Project & { member_count: number; koala_employee_requests: KoalaEmployeeRequest[] }
} & { audit_log: LogEntry[] }

interface Props {
  subs: AdminSub[]
  page_meta: PageMeta
  stats: {
    total: number
    total_paid: number
    plans: {
      free_plan?: number
      team_plan?: number
      team_invoiced_plan?: number
      pro_plan?: number
      starter_plan?: number
      starter_plus_plan?: number
      starter_invoiced_plan?: number
      pro_invoiced_plan?: number
      business_plan?: number
      unlimited_plan?: number
    }
    statuses: {
      active?: number
      trialing?: number
      past_due?: number
      canceled?: number
      unpaid?: number
    }
  }
  user: {
    email: string
    name: string
    superadmin: boolean
    koala_employee: boolean
  }
}

const plans = {
  free_plan: 'Free',
  team_plan: 'Team',
  team_invoiced_plan: 'Team Invoiced',
  pro_plan: 'Pro',
  starter_plan: 'Starter',
  starter_plus_plan: 'Starter Plus',
  starter_invoiced_plan: 'Starter Invoiced',
  pro_invoiced_plan: 'Pro Invoiced',
  business_plan: 'Business',
  unlimited_plan: 'Unlimited'
}

function validJson(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

function SubscriptionModal(props: { isSuperAdmin: boolean; subscription: AdminSub; onClose: () => void }) {
  const { subscription } = props
  const [shouldTrial, setShouldTrial] = useState(!!props.subscription.expires_at)
  const [allowTrial, setAllowTrial] = useState(!!props.subscription.metadata.allow_trial)
  const [selectedSku, setSelectedSku] = useState(props.subscription.product_sku)
  const [entitlements, setEntitlements] = useState(JSON.stringify(props.subscription.entitlements ?? {}, null, 2))
  const [isLoading, setIsLoading] = useState(false)

  const validEntitlements = useMemo(() => validJson(entitlements), [entitlements])

  const updateSub = useCallback(
    async (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault()
      setIsLoading(true)

      const data = new FormData(e.target as HTMLFormElement)
      postForm(`/admin/subscriptions/${subscription.project_id}.json`, data)
        .then(() => {
          toast.success('Subscription Updated')
          props.onClose()
        })
        .catch((err) => {
          toast.error('Failed to Update Subscription ' + err.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [subscription, props]
  )

  return (
    <Modal
      size="xl"
      isOpen={!!subscription}
      onClose={() => {
        if (isLoading) {
          return
        }

        props.onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent
        as={'form'}
        onSubmit={(e) => {
          e.preventDefault()
          updateSub(e)
        }}
      >
        <ModalCloseButton disabled={isLoading} />
        <ModalHeader>Edit Subscription for {subscription?.project.slug}</ModalHeader>

        <ModalBody>
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <Stack spacing="8">
            <HStack spacing={4}>
              <CompanyAvatar
                size="40px"
                name={subscription.project.name ?? subscription.project.slug}
                domain={subscription.project.domain}
              />

              <Stack spacing={1} minWidth="100px" lineHeight="1.2">
                <Text fontSize="md" fontWeight="semibold" textOverflow="ellipsis" overflow="hidden">
                  {subscription.project.name ?? subscription.project.slug}
                </Text>
                <Text fontSize="sm" color="gray.500" textOverflow="ellipsis" overflow="hidden">
                  {subscription.project.slug}
                </Text>
              </Stack>
            </HStack>

            <FormControl>
              <FormLabel>Version</FormLabel>
              <Select
                name="subscription[version]"
                disabled={isLoading}
                size="sm"
                rounded="md"
                defaultValue={subscription?.version ?? 'v1'}
              >
                <option value="v1">v1</option>
                <option value="v2">v2</option>
                <option value="v3">v3</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Plan</FormLabel>
              <Select
                name="subscription[product_sku]"
                disabled={isLoading}
                size="sm"
                rounded="md"
                defaultValue={subscription?.product_sku ?? ''}
                value={selectedSku}
                onChange={(e) => {
                  setSelectedSku(e.target.value as KoalaSubscription['product_sku'])
                  if (e.target.value === 'unlimited_plan') {
                    setEntitlements('{}')
                  }
                }}
              >
                <option value="free_plan">Free</option>
                <option value="team_plan" disabled>
                  Team
                </option>
                <option value="starter_plan" disabled>
                  Starter
                </option>
                <option value="starter_plus_plan" disabled>
                  Starter Plus
                </option>
                <option value="pro_plan" disabled>
                  Pro
                </option>
                <option value="starter_invoiced_plan">Starter Invoiced</option>
                <option value="pro_invoiced_plan">Pro Invoiced</option>
                <option value="team_invoiced_plan">Team Invoiced</option>
                <option value="business_plan">Business</option>
                <option value="unlimited_plan">Unlimited</option>
              </Select>
              <FormHelperText>
                Note: Self Service Team, Starter, Starter Plus, and Pro plans are only support through the Koala app
                because of all the stripe logic.
              </FormHelperText>

              {props.subscription.plan === 'team' && (
                <Alert status="warning" variant={'left-accent'} fontSize="sm">
                  <AlertIcon />
                  <Stack spacing="0">
                    <AlertTitle>Note:</AlertTitle>
                    <AlertDescription>
                      Please make sure to cancel any existing Team subscriptions in Stripe when moving from Team to
                      Business or Team Invoiced.
                    </AlertDescription>
                  </Stack>
                </Alert>
              )}
            </FormControl>

            <FormControl isInvalid={!!entitlements && !validEntitlements}>
              <FormLabel>Entitlements</FormLabel>
              <Textarea
                name={'subscription[entitlements_text]'}
                disabled={isLoading}
                rows={6}
                value={entitlements}
                onChange={(e) => setEntitlements(e.target.value)}
                defaultValue={JSON.stringify(subscription.entitlements ?? {}, null, 2)}
              />
              {entitlements && !validEntitlements ? (
                <FormErrorMessage>Invalid JSON. Please check for missing quotes or dangling commas.</FormErrorMessage>
              ) : (
                <FormHelperText>
                  Note: Leaving the entitlements as an empty object will default workspaces to the plan defaults.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Allow trial at any time</FormLabel>
              <Stack>
                <Checkbox
                  size="sm"
                  disabled={selectedSku !== 'free_plan'}
                  defaultChecked={allowTrial}
                  checked={allowTrial}
                  onChange={() => {
                    setAllowTrial(!allowTrial)
                  }}
                >
                  Enable
                </Checkbox>
              </Stack>
              {allowTrial && <input type="hidden" name="subscription[allow_trial]" value="true" />}
              <FormHelperText>Note: Only business and unlimited plans support manual trials</FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Trial</FormLabel>
              <Stack>
                <Checkbox
                  size="sm"
                  disabled={selectedSku === 'free_plan'}
                  defaultChecked={shouldTrial}
                  checked={shouldTrial}
                  onChange={() => {
                    setShouldTrial(!shouldTrial)
                  }}
                >
                  Include trial?
                </Checkbox>
                <Input
                  disabled={!shouldTrial}
                  size="sm"
                  type="date"
                  defaultValue={
                    subscription.expires_at ? dayjs(subscription?.expires_at).format('YYYY-MM-DD') : undefined
                  }
                  name="subscription[expires_at]"
                  required={shouldTrial}
                />
              </Stack>
              {!shouldTrial && <input type="hidden" name="subscription[expires_at]" value="" />}
              <FormHelperText>Note: Only business and unlimited plans support manual trials</FormHelperText>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              disabled={isLoading}
              variant={'ghost'}
              size="sm"
              onClick={() => {
                if (isLoading) {
                  return
                }
                props.onClose()
              }}
            >
              Close
            </Button>
            <Button disabled={!props.isSuperAdmin} isLoading={isLoading} type="submit" colorScheme={'purple'} size="sm">
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default function Index(props: Props) {
  const loc = useLocation()
  const [subscription, setSubscription] = useState<AdminSub | undefined>()
  const searchParams = useMemo(() => new URLSearchParams(loc.search), [loc.search])
  const [loading, setLoading] = useState(false)

  const onRequestAccess = useCallback(
    (projectId: string, reason: string) => {
      setLoading(true)
      post('/admin/subscriptions/access', {
        requested_project_id: projectId,
        email: props.user.email,
        reason: reason
      })
        .then(() => {
          toast.success('Access Requested')
          router.visit(window.location.toString())
        })
        .catch((err) => {
          toast.error('Failed to Request Access ' + err.message)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [props.user.email]
  )

  return (
    <PageLayout size="full">
      <AdminBreadcrumb paths={[{ path: '/admin/subscriptions', title: 'Subscriptions' }]} />

      <Stack spacing="12">
        <Grid width="100%" templateColumns={['repeat(2, 1fr)', 'repeat(auto-fit, minmax(300px, 1fr))']} gap={6}>
          <Card p={2}>
            <Stack spacing={0}>
              <Stat label="Workspaces" value={props.stats.total} />
              <Stat label="Total paid" value={props.stats.total_paid} />
              {Object.keys(plans).map((plan) => (
                <Stat key={plan} label={plans[plan] || plan} value={props.stats.plans[plan] || 0} />
              ))}
            </Stack>
          </Card>
          <Card p={2}>
            <Stack spacing={0}>
              {/* Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid. */}
              <Stat label="Trialing" value={props.stats.statuses.trialing} />
              <Stat label="Active" value={props.stats.statuses.active} />
              <Stat label="Past due" value={props.stats.statuses.past_due} />
              <Stat label="Unpaid" value={props.stats.statuses.unpaid} />
              <Stat label="Canceled" value={props.stats.statuses.canceled} />
            </Stack>
          </Card>
        </Grid>

        <Stack>
          <HStack justifyContent={'space-between'} flexWrap={'wrap'} alignItems="center">
            <Stack flex="2">
              <PageTitle flex="3">Subscriptions</PageTitle>
              <Text fontSize={'sm'}>Select a subscription to upgrade it.</Text>
            </Stack>
            <Stack flex="2" justifyContent={'flex-end'} alignItems="flex-end">
              <SearchBar
                placeholder="Search"
                debounce={500}
                size="sm"
                inputProps={{
                  defaultValue: searchParams.get('query') ?? ''
                }}
                onChange={(val) => {
                  router.visit(
                    mergeParams(window.location.toString(), {
                      query: val || undefined,
                      page: '1'
                    })
                  )
                }}
              />
              <RadioGroup
                size={'sm'}
                as={HStack}
                fontSize={'xs'}
                defaultValue={searchParams.get('plan') ?? 'all'}
                onChange={(plan) => {
                  router.visit(
                    mergeParams(window.location.toString(), {
                      plan,
                      page: '1'
                    })
                  )
                }}
              >
                <Wrap shouldWrapChildren flexWrap={'wrap'} rowGap="2" gap={'4'}>
                  <Radio value="all">All</Radio>
                  <Radio value="v1">v1</Radio>
                  <Radio value="v2">v2</Radio>
                  <Radio value="v3">v3</Radio>
                  <Radio value="free_plan">Free</Radio>
                  <Radio value="team_plan">Team</Radio>
                  <Radio value="team_invoiced_plan">Team (Invoiced)</Radio>
                  <Radio value="starter_plan">Starter</Radio>
                  <Radio value="starter_plus_plan">Starter Plus</Radio>
                  <Radio value="starter_invoiced_plan">Starter (Invoiced)</Radio>
                  <Radio value="pro_plan">Pro</Radio>
                  <Radio value="pro_invoiced_plan">Pro (Invoiced)</Radio>
                  <Radio value="business_plan">Business</Radio>
                  <Radio value="unlimited_plan">Unlimited</Radio>
                </Wrap>
              </RadioGroup>
            </Stack>
          </HStack>
        </Stack>

        {subscription && (
          <SubscriptionModal
            subscription={subscription}
            isSuperAdmin={props.user.superadmin}
            onClose={() => {
              setSubscription(undefined)
              router.visit(mergeParams(window.location.toString(), { page: '1' }))
            }}
          />
        )}

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Workspace</Th>
                <Th>Entitlements</Th>
                <Th isNumeric>Seats</Th>
                <Th isNumeric>Last Updated</Th>
                <Th></Th>
                <Th>Access Requests</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.subs.map((sub) => {
                return (
                  <Tr key={sub.id}>
                    <Td py="8">
                      <Stack spacing="4">
                        <HStack spacing={4}>
                          <CompanyAvatar
                            size="40px"
                            name={sub.project.name ?? sub.project.slug}
                            domain={sub.project.domain}
                          />

                          <Stack spacing={1} minWidth="100px" lineHeight="1.2">
                            <Text
                              as={Link}
                              href={`/projects/${sub.project.slug}`}
                              isExternal
                              fontSize="md"
                              fontWeight="semibold"
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              {sub.project.name ?? sub.project.slug}
                            </Text>
                            <HStack spacing={1.5} divider={<MiddotDivider />}>
                              <Text
                                as={Link}
                                href={`/projects/${sub.project.slug}`}
                                isExternal
                                fontSize="sm"
                                color="gray.500"
                              >
                                {sub.project.slug}
                              </Text>
                              <Text
                                as={Link}
                                href={'https://' + sub.project.domain}
                                isExternal
                                fontSize="sm"
                                color="gray.500"
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {sub.project.domain}
                              </Text>
                            </HStack>
                          </Stack>
                        </HStack>

                        <Stack spacing="1" bg="gray.50" p="4" rounded="md" borderWidth={'1px'}>
                          <Text display="inline">
                            <Text display="inline" textTransform="capitalize">
                              {sub.status}
                            </Text>{' '}
                            <Middot /> {sub.product_sku} ({sub.version || 'v1'})
                            {sub.past_due && (
                              <>
                                <Middot /> Past Due
                              </>
                            )}
                          </Text>
                          {sub.stripe_customer_id && (
                            <HStack>
                              <Link
                                color="blue.500"
                                isExternal
                                href={`https://dashboard.stripe.com/customers/${sub.stripe_customer_id}`}
                              >
                                {sub.stripe_customer_id}
                              </Link>
                            </HStack>
                          )}
                          {sub.trialing && <Text fontSize={'xs'}>Trial ends {formatDate(sub.expires_at)}</Text>}
                        </Stack>

                        <Text>Index Status: {sub.project.index_status}</Text>
                      </Stack>
                    </Td>
                    <Td py="8">
                      <pre>{JSON.stringify(sub.entitlements, null, 2)}</pre>
                    </Td>
                    <Td isNumeric>{sub.project.member_count}</Td>
                    <Td isNumeric>{formatDate(sub.updated_at)}</Td>
                    <Td isNumeric>
                      <HStack spacing={0}>
                        <Button onClick={() => setSubscription(sub)} colorScheme={'purple'} variant={'ghost'} size="sm">
                          Edit
                        </Button>
                        <Popover closeOnBlur={false} isLazy lazyBehavior="keepMounted">
                          <PopoverTrigger>
                            <Button disabled={loading} colorScheme={'purple'} variant={'ghost'} size="sm">
                              Request Access
                            </Button>
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent textAlign={'left'}>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Stack
                                  as={'form'}
                                  onSubmit={(e) => {
                                    e.preventDefault()
                                    const data = new FormData(e.currentTarget as any)
                                    const reason = data.get('reason')

                                    ;(e.currentTarget as unknown as HTMLFormElement).reset()

                                    onRequestAccess(sub.project_id, reason?.toString() ?? '')
                                  }}
                                >
                                  <Heading size="sm">Workspace Access Request</Heading>
                                  <Text fontSize={'sm'}>
                                    Please provide a reason for requesting access to this workspace.
                                  </Text>
                                  <Textarea disabled={loading} required name="reason"></Textarea>
                                  <Button size="sm" type="submit" disabled={loading}>
                                    Request Access
                                  </Button>
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </HStack>
                    </Td>
                    <Td>
                      <Stack>
                        {sub.project.koala_employee_requests.map((req) => {
                          let color = req.approved_at ? 'green' : req.denied_at ? 'red' : 'gray'
                          let status = req.approved_at ? 'Approved' : req.denied_at ? 'Denied' : 'Pending'
                          let IconComponent = req.approved_at
                            ? IconCheck
                            : req.denied_at
                              ? IconAccessibleOff
                              : IconHelpCircle

                          let href = `/admin/subscriptions/access/grant?request_id=${
                            req.id
                          }&redirect_to=${encodeURIComponent(window.location.toString())}`

                          if (status === 'Approved') {
                            href = `/admin/subscriptions/access/deny?request_id=${
                              req.id
                            }&redirect_to=${encodeURIComponent(window.location.toString())}`
                          }

                          const isExpired = dayjs(req.expires_at).isBefore(dayjs())
                          if (isExpired) {
                            IconComponent = IconClockExclamation
                            color = 'gray'
                            status = 'Expired'
                          }

                          return (
                            <HStack
                              key={req.id}
                              borderWidth="thin"
                              py="4"
                              px="3"
                              rounded="md"
                              justifyContent={'space-between'}
                            >
                              <HStack alignItems={'flex-start'}>
                                <Icon as={IconComponent} boxSize={4} color={`${color}.500`} />
                                <Stack spacing="0">
                                  <Text
                                    fontSize="xs"
                                    color={`${color}.500`}
                                    textTransform="uppercase"
                                    fontWeight={'semibold'}
                                  >
                                    {status}
                                  </Text>
                                  <Text fontSize={'xs'} colorScheme={color}>
                                    {req.user?.email}
                                  </Text>

                                  {req.reason && (
                                    <Text pt="2" fontSize={'xs'} color="gray.500">
                                      Reason: {req.reason}
                                    </Text>
                                  )}

                                  {req.expires_at && (
                                    <HStack spacing="1" fontSize="xs" pt="2">
                                      <Text>{isExpired ? 'Expired' : 'Expires'}</Text>
                                      <TimeAgo time={req.expires_at} />
                                    </HStack>
                                  )}
                                </Stack>
                              </HStack>

                              {['Approved', 'Denied', 'Pending'].includes(status) && (
                                <Button
                                  disabled={!props.user.superadmin}
                                  size="xs"
                                  as={Link}
                                  href={props.user.superadmin ? href : undefined}
                                >
                                  {status === 'Approved' && 'Revoke'}
                                  {status === 'Denied' && 'Approve'}
                                  {status === 'Pending' && 'Approve'}
                                </Button>
                              )}
                            </HStack>
                          )
                        })}
                      </Stack>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <TableFooter
          pageMeta={props.page_meta}
          page={props.page_meta.current_page}
          prevPath={mergeParams(window.location.toString(), {
            page: props.page_meta.prev_page?.toString() ?? '1'
          })}
          nextPath={mergeParams(window.location.toString(), {
            page: props.page_meta.next_page?.toString() ?? '1'
          })}
        />
      </Stack>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number }) {
  return (
    <Flex flex="1 1 0%" gap={2} justifyContent="space-between" _hover={{ bg: 'gray.100' }} px={2} py={1.5} rounded="md">
      <Heading size="xs" fontWeight="normal" color="gray.600">
        {props.label}
      </Heading>
      <Text fontSize="md" fontWeight="semibold" lineHeight={1} css={{ fontVariantNumeric: 'tabular-nums' }}>
        {(props.value || 0).toLocaleString()}
      </Text>
    </Flex>
  )
}
