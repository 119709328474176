import {
  Button,
  Divider,
  FormControl,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  UseDisclosureProps,
  IconButtonProps,
  ButtonGroup
} from '@chakra-ui/react'
import {
  IconAddressBook,
  IconAddressBookOff,
  IconChevronDown,
  IconDotsVertical,
  IconMailSearch,
  IconPhonePlus
} from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Prospect, useSaveProspects, useUnsaveProspects } from '../../data/use-prospects'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { SalesforceIcon, SfContactIcon, SfLeadIcon, ThumbsDownIcon } from '../../ui/icons'
import { projectPath } from '../../ui/ProjectsContext'
import { accountPath } from '../accounts/lib/account-path'
import { AddToSequenceModal } from '../profiles/components/apollo-actions'
import { AddToOutreachSequenceModal } from '../profiles/components/outreach-actions'
import { Persona } from './personas'

interface ActionMenuProps {
  prospect: Prospect
  domain: string
  account: Account
  apps: Apps
  context?: 'explore' | 'account_page' | 'triangulation'
  isIconButton?: boolean
  isButtonGroup?: boolean
  isLoading?: boolean
  onChange: (prospect: Prospect) => void
  onEmailLoadingChange?: (loading: boolean) => void
  onPhoneLoadingChange?: (loading: boolean) => void
  persona?: Persona | null
  iconButtonProps?: IconButtonProps
}

export function ActionMenu(props: React.PropsWithChildren<ActionMenuProps>) {
  const {
    prospect,
    domain,
    account,
    apps,
    context,
    isLoading,
    isIconButton,
    isButtonGroup,
    onChange,
    onEmailLoadingChange,
    onPhoneLoadingChange,
    iconButtonProps
  } = props

  const isLocked = !prospect.email && (prospect.unlock_state === 'locked' || prospect.unlock_state === null)
  const isPhoneLocked = prospect.phone_state === 'locked' || prospect.phone_state === null
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [emailLoadingState, setEmailLoadingState] = useState(false)
  const [phoneLoadingState, setPhoneLoadingState] = useState(false)

  const hasApollo = useMemo(() => apps?.['Apps::Apollo::App']?.valid || false, [apps])
  const hasOutreach = useMemo(() => apps?.['Apps::Outreach::App']?.valid || false, [apps])

  const [selectedProspect, setSelectedProspect] = useState<Prospect | null>(null)

  const { mutateAsync: saveProspectsAsync } = useSaveProspects()
  const { mutateAsync: unsaveProspectsAsync } = useUnsaveProspects()
  const [saving, setSaving] = useState({})

  const saveProspect = useCallback(
    async (prospect: Prospect) => {
      try {
        setSaving((prev) => ({ ...prev, [prospect.id]: true }))
        await saveProspectsAsync({ prospectIds: [prospect.id] })
        onChange({
          ...prospect,
          saved: true
        })
        toast.success('Prospect saved')
      } catch (err) {
        toast.error('Failed to save prospect')
      } finally {
        setSaving((prev) => ({ ...prev, [prospect.id]: false }))
      }
    },
    [saveProspectsAsync, onChange]
  )

  const unsaveProspect = useCallback(
    async (prospect: Prospect) => {
      try {
        setSaving((prev) => ({ ...prev, [prospect.id]: true }))
        await unsaveProspectsAsync({ prospectIds: [prospect.id] })
        onChange({
          ...prospect,
          saved: false
        })
        toast.success('Prospect removed from Saved Prospects')
      } catch (err) {
        toast.error('Failed to remove prospect from Saved Prospects')
      } finally {
        setSaving((prev) => ({ ...prev, [prospect.id]: false }))
      }
    },
    [unsaveProspectsAsync, onChange]
  )

  const apolloSequenceDisclosure = useDisclosure()
  const outreachSequenceDisclosure = useDisclosure()

  const onUnlock = useCallback(
    (prospect: Prospect) => {
      setEmailLoadingState(true)
      onEmailLoadingChange?.(true)

      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          dismissible: true
        })
      }, 2500)

      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock`), {
        context
      })
        .then((res) => {
          clearTimeout(timer)
          onChange(res.prospect)

          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked')
          } else {
            toast.warning(`Could not find email for ${res.prospect.first_name} ${res.prospect.last_name}`)
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setEmailLoadingState(false)
          onEmailLoadingChange?.(false)
        })
    },
    [domain, context, onEmailLoadingChange, onChange]
  )

  const onUnlockPhone = useCallback(
    (prospect: Prospect) => {
      setPhoneLoadingState(true)
      onPhoneLoadingChange?.(true)

      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock-phone`), {
        context
      })
        .then((res) => {
          onChange(res.prospect)

          if (res.prospect.phone_state === 'unlocked') {
            toast.success('Phone number unlocked')
          } else if (res.prospect.phone_state === 'not_found') {
            toast.warning('Phone number not found')
          }
        })
        .catch(() => {
          toast.error('Failed to unlock phone number')
        })
        .finally(() => {
          setPhoneLoadingState(false)
          onPhoneLoadingChange?.(false)
        })
    },
    [domain, context, onChange, onPhoneLoadingChange]
  )

  const importSFContact = useCallback(
    (prospect: Prospect, contactType: 'contact' | 'lead') => {
      if (!account) {
        return
      }

      if (prospect.salesforce_contact_cache?.permalink || prospect.salesforce_lead_cache?.permalink) {
        window.open(prospect.salesforce_contact_cache?.permalink ?? prospect.salesforce_lead_cache?.permalink, '_blank')
        return
      }

      const appInstance = apps?.[`Apps::Salesforce::App`]
      if (!appInstance?.valid || !appInstance?.connected) {
        toast.warning(`Please connect Salesforce to your account first`)
        router.visit(projectPath(`/apps/salesforce?return_to=${encodeURIComponent(window.location.pathname)}`))
        return
      }

      setIsLoadingAction(true)

      const path = accountPath(account, `/prospects/${prospect.id}/import?app=Salesforce&type=${contactType}`)
      post<{ prospect: Prospect }>(path, {
        context
      })
        .then((res) => {
          onChange(res.prospect)
          toast.success('Prospect imported to Salesforce')
        })
        .catch((err) => {
          toast.error(`Failed to import prospect to Salesforce: ${err.message}`)
        })
        .finally(() => {
          setIsLoadingAction(false)
        })
    },
    [account, apps, context, onChange]
  )

  const importHSContact = useCallback(
    (prospect: Prospect) => {
      if (!account) {
        return
      }

      if (prospect.hubspot_contact_cache?.permalink) {
        window.open(prospect.hubspot_contact_cache.permalink, '_blank')
        return
      }

      const appInstance = apps?.[`Apps::Hubspot::App`]
      if (!appInstance?.valid || !appInstance?.connected) {
        toast.warning(`Please connect HubSpot to your account first`)
        router.visit(projectPath(`/apps/hubspot?return_to=${encodeURIComponent(window.location.pathname)}`))
        return
      }

      setIsLoadingAction(true)

      const path = accountPath(account, `/prospects/${prospect.id}/import?app=Hubspot`)
      post<{ prospect: Prospect }>(path, {
        context
      })
        .then((res) => {
          onChange(res.prospect)

          toast.success('Prospect imported to HubSpot')
        })
        .catch(() => {
          toast.error('Failed to import prospect to HubSpot')
        })
        .finally(() => {
          setIsLoadingAction(false)
        })
    },
    [account, apps, context, onChange]
  )

  const onAction = useCallback(
    (app: string, prospect: Prospect) => {
      const appInstance = apps?.[`Apps::${app}::App`]

      window.ko?.track('Prospect Action', {
        app,
        prospected_profile_id: prospect.id,
        email: prospect.email,
        company: domain,
        app_valid: appInstance?.valid,
        app_connected: appInstance?.connected,
        context
      })

      if (!appInstance?.valid || !appInstance?.connected) {
        toast.warning(
          `Please connect ${app} to your account first. You'll be redirected to the ${app} app page to finish your setup.`
        )

        setTimeout(() => {
          router.visit(
            projectPath(`/apps/${app.toLowerCase()}?return_to=${encodeURIComponent(window.location.pathname)}`)
          )
        }, 1000)

        return
      }

      if (app === 'Apollo') {
        setSelectedProspect(prospect)
        apolloSequenceDisclosure.onOpen()
      } else if (app === 'Hubspot') {
        importHSContact(prospect)
      } else if (app === 'Outreach') {
        setSelectedProspect(prospect)
        outreachSequenceDisclosure.onOpen()
      }
    },
    [apps, apolloSequenceDisclosure, importHSContact, outreachSequenceDisclosure, domain, context]
  )

  const feedbackModal = useDisclosure({
    onClose: () => {
      setSelectedProspect(null)
    }
  })

  const menuContent = (
    <>
      <Menu size="sm">
        {isIconButton ? (
          <MenuButton
            as={IconButton}
            aria-label="Actions"
            size="sm"
            variant="ghost"
            borderColor="transparent"
            shadow="none"
            colorScheme="gray"
            icon={<IconDotsVertical size={16} />}
            {...iconButtonProps}
          />
        ) : (
          <MenuButton
            as={Button}
            size="xs"
            rightIcon={<Icon as={IconChevronDown} boxSize={4} />}
            iconSpacing={1}
            isLoading={isLoadingAction || isLoading}
          >
            Actions
          </MenuButton>
        )}
        <Portal>
          <MenuList zIndex="popover">
            {isLocked && (
              <>
                <MenuItem
                  isDisabled={emailLoadingState}
                  icon={<IconMailSearch size="14" />}
                  onClick={() => onUnlock(prospect)}
                >
                  Find email
                </MenuItem>
              </>
            )}

            {isPhoneLocked && (
              <>
                <MenuItem
                  isDisabled={phoneLoadingState}
                  icon={<IconPhonePlus size="14" />}
                  onClick={() => onUnlockPhone(prospect)}
                >
                  Find phone
                </MenuItem>
              </>
            )}

            {prospect.saved ? (
              <MenuItem
                icon={<IconAddressBookOff size="14" />}
                isDisabled={saving[prospect.id]}
                onClick={() => unsaveProspect(prospect)}
              >
                Unsave Prospect
              </MenuItem>
            ) : (
              <MenuItem
                icon={<IconAddressBook size="14" />}
                isDisabled={saving[prospect.id]}
                onClick={() => saveProspect(prospect)}
              >
                Save Prospect
              </MenuItem>
            )}

            {(isPhoneLocked || isLocked) && (
              <>
                <MenuDivider />
              </>
            )}

            <MenuGroup title="CRM Actions" pl="0" ml="2" fontSize="xs">
              {!prospect.salesforce_contact_cache && !prospect.salesforce_lead_cache ? (
                <>
                  <MenuItem
                    fontSize={'sm'}
                    icon={<SfContactIcon boxSize={3.5} />}
                    onClick={() => importSFContact(prospect, 'contact')}
                    isDisabled={isLocked}
                  >
                    Add Contact
                  </MenuItem>
                  <MenuItem
                    fontSize={'sm'}
                    icon={<SfLeadIcon boxSize={3.5} />}
                    onClick={() => importSFContact(prospect, 'lead')}
                    isDisabled={isLocked}
                  >
                    Add Lead
                  </MenuItem>
                </>
              ) : (
                <MenuItem
                  fontSize={'sm'}
                  icon={<SalesforceIcon boxSize={3.5} color="salesforce" />}
                  isDisabled={isLocked}
                  onClick={() => importSFContact(prospect, 'contact')}
                >
                  View in Salesforce
                </MenuItem>
              )}
              <MenuItem
                icon={<CompanyAvatar size="14px" src="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg" />}
                fontSize={'sm'}
                isDisabled={isLocked}
                onClick={() => onAction('Hubspot', prospect)}
              >
                {prospect.hubspot_contact_cache?.permalink ? 'View in HubSpot' : 'Add Contact'}
              </MenuItem>
            </MenuGroup>

            <MenuDivider />

            <MenuGroup title="Sequences" pl="0" ml="2" fontSize={'xs'}>
              <MenuItem
                icon={<CompanyAvatar size="14px" domain={'outreach.io'} />}
                isDisabled={isLocked}
                fontSize={'sm'}
                onClick={() => onAction('Outreach', prospect)}
              >
                Add to Outreach
              </MenuItem>
              <MenuItem
                icon={<CompanyAvatar size="14px" domain={'apollo.io'} />}
                isDisabled={isLocked}
                fontSize={'sm'}
                onClick={() => onAction('Apollo', prospect)}
              >
                Add to Apollo
              </MenuItem>
            </MenuGroup>

            <Divider />

            <MenuGroup title="Match Quality" pl="0" ml="2" fontSize="xs">
              <MenuItem
                icon={<ThumbsDownIcon boxSize={3.5} color="gray.500" />}
                fontSize={'sm'}
                onClick={() => {
                  setSelectedProspect(prospect)
                  feedbackModal.onOpen()
                }}
              >
                Bad match
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>

      {hasApollo && selectedProspect && apolloSequenceDisclosure.isOpen && (
        <AddToSequenceModal
          {...apolloSequenceDisclosure}
          onClose={() => {
            apolloSequenceDisclosure.onClose()
            setSelectedProspect(null)
          }}
          profileType="ProspectedProfile"
          profile={{
            id: selectedProspect.id,
            email: selectedProspect.email!
          }}
        />
      )}

      {hasOutreach && selectedProspect && outreachSequenceDisclosure.isOpen && (
        <AddToOutreachSequenceModal
          {...outreachSequenceDisclosure}
          app={apps!['Apps::Outreach::App']}
          onClose={() => {
            outreachSequenceDisclosure.onClose()
            setSelectedProspect(null)
          }}
          profileType="ProspectedProfile"
          account={account!}
          profile={{
            id: selectedProspect.id,
            email: selectedProspect.email!
          }}
        />
      )}

      {selectedProspect && (
        <ProspectFeedback
          persona={props.persona}
          context={context}
          domain={domain}
          prospect={selectedProspect}
          {...feedbackModal}
        />
      )}
    </>
  )

  if (isButtonGroup) {
    return (
      <ButtonGroup colorScheme="gray" variant="outline">
        {apps?.['Apps::Salesforce::App']?.valid &&
        !prospect.salesforce_contact_cache &&
        !prospect.salesforce_lead_cache ? (
          <Button
            size="sm"
            leftIcon={<SfContactIcon boxSize={3.5} />}
            onClick={() => importSFContact(prospect, 'contact')}
            color="gray.500"
            isDisabled={isLocked}
          >
            Add Contact
          </Button>
        ) : apps?.['Apps::Hubspot::App']?.valid && !prospect.hubspot_contact_cache?.permalink ? (
          <Button
            leftIcon={<CompanyAvatar size="14px" src="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg" />}
            size={'sm'}
            isDisabled={isLocked}
            color="gray.500"
            onClick={() => onAction('Hubspot', prospect)}
          >
            {prospect.hubspot_contact_cache?.permalink ? 'View in HubSpot' : 'Add Contact'}
          </Button>
        ) : apps?.['Apps::Apollo::App']?.valid ? (
          <Button isDisabled={isLocked} size={'sm'} onClick={() => onAction('Apollo', prospect)} color="gray.500">
            <CompanyAvatar size="14px" domain={'apollo.io'} marginRight="2" />
            Add to Sequence
          </Button>
        ) : apps?.['Apps::Outreach::App']?.valid ? (
          <Button isDisabled={isLocked} size={'sm'} onClick={() => onAction('Outreach', prospect)} color="gray.500">
            <CompanyAvatar size="14px" domain={'outreach.io'} marginRight="2" />
            Add to Sequence
          </Button>
        ) : null}
        {menuContent}
      </ButtonGroup>
    )
  }

  return menuContent
}

interface ProspectFeedbackProps extends UseDisclosureProps {
  prospect: Prospect
  domain: string
  persona?: Persona | null
  reason?: 'good' | 'persona-mismatch' | 'stale'
  context?: 'explore' | 'account_page' | 'triangulation' | 'profile_page'
}

export function ProspectFeedback(props: ProspectFeedbackProps) {
  const [comments, setComments] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState<'good' | 'persona-mismatch' | 'stale' | 'other' | undefined>(props.reason)
  const disclosure = useDisclosure(props)

  const onSubmit = useCallback(() => {
    setIsLoading(true)
    post<{ prospect: Prospect }>(
      projectPath(`/accounts/${props.domain}/prospects/${props.prospect.id}/feedback?domain=${props.domain}`),
      {
        feedback: {
          feedback_type: reason,
          persona_id: props.persona?.id,
          comments
        },
        context: props.context
      }
    )
      .then(() => {
        toast.success('Feedback received! It has been sent to our team for review.')
        disclosure.onClose()
      })
      .catch(() => {
        toast.error('Failed to submit feedback')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [comments, reason, props, disclosure])

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'md'}>Leave us feedback</ModalHeader>
        <ModalBody fontSize={'sm'}>
          <Stack spacing="4">
            <Stack spacing="1">
              <Text>
                <b>Prospect: </b>
                {props.prospect.first_name} {props.prospect.last_name}{' '}
                {props.prospect.email ? `(${props.prospect.email})` : ''}
              </Text>
              {props.prospect.linkedin_url && (
                <Text>
                  <b>LinkedIn: </b>
                  <Link href={`https://${props.prospect.linkedin_url}`} isExternal>
                    {props.prospect.linkedin_url.split('linkedin.com/')[1]}
                  </Link>
                </Text>
              )}
            </Stack>

            <RadioGroup onChange={(nextValue) => setReason(nextValue as any)} value={reason} size="sm" py={2}>
              <Stack>
                <Radio value="stale">It has stale data</Radio>
                <Radio value="wrong-match">They don't work at this company</Radio>
                <Radio value="persona-mismatch">They don't match my persona</Radio>
                <Radio value="other">Other reason</Radio>
              </Stack>
            </RadioGroup>

            <FormControl>
              <Textarea
                isDisabled={isLoading}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                size="sm"
                rounded="md"
                placeholder="Any additional feedback?"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button size="sm" variant="ghost" onClick={props.onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={onSubmit} isDisabled={!reason} isLoading={isLoading}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
