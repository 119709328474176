import { Box, Center, Flex, FormControl, FormLabel, HStack, Icon, Link, Spinner, Stack, Text } from '@chakra-ui/react'
import { IconCheck, IconExternalLink, IconUsers } from '@tabler/icons-react'
import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { Apps } from '../../../../types/App'
import { useAccountViews } from '../../../data/use-account-views'
import { FacetParams, filtersAsText, useFacets } from '../../../data/use-facets'
import { ButtonRadioGroupProps, CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { BuildingIcon } from '../../../ui/icons'
import { useSearchParams } from '../../../ui/useSearchState'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { validFilter } from '../../accounts/facets/categories'
import { accountViewPath } from '../../account_views/lib/list-paths'
import { FollowRule } from '../../notifications'

interface TerritorySetupProps {
  territoryRules?: FollowRule['territory_rules']
  followRule?: FollowRule
  targetType?: 'Profile' | 'Account'
  apps?: Apps
  onFacetsChange?: (facets: FacetParams) => void
}

type TerritoryPreset = 'smart-list' | 'custom'

function defaultPreset(rule?: FollowRule): TerritoryPreset {
  if (Object.keys(rule?.territory_rules?.facets || {}).length > 0) {
    return 'custom'
  }

  return 'smart-list'
}

export function TSetup(props: TerritorySetupProps) {
  const { searchParams } = useSearchParams()

  const [territoryRules, setTerritoryRules] = React.useState(props.followRule?.territory_rules)

  const filteredRules = useMemo(() => {
    if (props.targetType === undefined) {
      return territoryRules
    }

    if (territoryRules?.facets === null || territoryRules?.facets === undefined) {
      return territoryRules
    }

    // filter out any rules where the category doesn't match
    const kind = props.targetType.toLowerCase()
    const facets: FacetFilters = {}

    Object.keys(territoryRules.facets).forEach((facet) => {
      if (validFilter(kind, facet)) {
        facets[facet] = territoryRules.facets![facet]
      }
    })

    return { facets }
  }, [territoryRules, props.targetType])

  const [listId, setListId] = React.useState(
    props.followRule?.account_view_id || props.followRule?.territory_rules?.smart_list
  )
  const [preset, setPreset] = React.useState<TerritoryPreset>(defaultPreset(props.followRule))

  const formParams: FollowRule['territory_rules'] = useMemo(() => {
    if (preset === 'smart-list') {
      return {
        smart_list: listId
      }
    }

    return {
      facets: filteredRules?.facets
    }
  }, [preset, filteredRules, listId])

  const targetLabel =
    props.targetType === 'Profile' ? 'Visitors' : props.targetType === 'Account' ? 'Accounts' : 'Accounts or Visitors'

  return (
    <Stack>
      <Stack pb="4">
        <CardRadioGroup
          size="sm"
          value={preset}
          onChange={(e) => {
            // reset filters on change
            setTerritoryRules(undefined)
            setPreset(e as TerritoryPreset)
          }}
          options={
            [
              {
                label: 'Lists',
                value: 'smart-list',
                description: `Use an existing list to define the ${targetLabel} that should match your filter criteria.`
              },
              {
                label: 'Custom Filters',
                value: 'custom',
                description: `Define your own criteria for filtering ${targetLabel} for the segments that should be included.`
              }
            ].filter(Boolean) as ButtonRadioGroupProps['options']
          }
        />
      </Stack>

      <Flex py="4" direction={'column'} gap="1">
        {preset !== 'smart-list' && (
          <TerritorySetup
            key={JSON.stringify({ props, preset, territoryRules })}
            {...props}
            apps={props.apps}
            territoryRules={filteredRules}
            onFacetsChange={(facets) => {
              setTerritoryRules({ facets: facets.facetFilters })
              setPreset('custom')
            }}
          />
        )}
        {preset === 'smart-list' && (
          <SmartListSetup
            listId={listId}
            onListChange={(listId) => {
              setListId(listId)
            }}
          />
        )}

        {searchParams['debug'] && (
          <pre>
            <Text>{JSON.stringify(formParams, null, 2)}</Text>
          </pre>
        )}

        <input type="hidden" name="follow_rule[territory_rules]" value={JSON.stringify(formParams)} />
        <input type="hidden" name="follow_rule[account_view_id]" value={listId || ''} />
      </Flex>
    </Stack>
  )
}

function SmartListSetup(props: { listId?: string; onListChange: (listId?: string) => void }) {
  const { data, isLoading } = useAccountViews()
  const lists = useMemo(() => data?.account_views ?? [], [data?.account_views])
  const selectedList = useMemo(() => lists.find((l) => l.id === props.listId), [props.listId, lists])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Stack>
      <FormControl>
        <FormLabel>List</FormLabel>
        <ComboboxWithSearch
          items={lists}
          selectedItem={selectedList || everyone}
          onChange={(selectedItem) => {
            props.onListChange(selectedItem?.id ?? undefined)
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => item?.name || ''}
          itemRenderer={AudiencePreview}
          selectButtonRenderer={AudiencePreview}
        />
      </FormControl>

      {selectedList && (
        <FormControl pt={4}>
          <FormLabel>Selected list filters</FormLabel>
          <Stack w="100%">
            <ListFilterPreview key={selectedList.id} {...selectedList} />
            {selectedList && (
              <Link
                display="inline-flex"
                alignItems="center"
                gap={1}
                isExternal
                fontSize="xs"
                href={accountViewPath(selectedList)}
                color="purple.500"
              >
                {selectedList.name}
                <IconExternalLink size={12} />
              </Link>
            )}
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}

const everyone = {
  id: '',
  name: 'Everyone',
  summary: 'Any visitor that triggers one of your selected intent signals (except global exclusions)',
  kind: 'account' as const,
  filters: { facets: {} }
}

interface AudienceOption {
  id?: string
  name: string
  summary?: string
  filters: {
    facets?: {}
  }
  kind: 'account' | 'profile'
}

interface AudiencePreviewProps {
  item: AudienceOption | null
  selectedItem?: AudienceOption | null
}

function AudiencePreview(props: AudiencePreviewProps) {
  const audience = props.item || everyone
  const filterSummary = filtersAsText(audience.filters ?? {})

  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <HStack width="100%" spacing={2}>
        <Center flex="none" width={8}>
          <Icon as={audience.kind === 'profile' ? IconUsers : BuildingIcon} color="gray.600" boxSize={5} />
        </Center>
        <Box flex="1">
          <Text fontSize="sm" fontWeight="medium">
            {audience.name}
          </Text>
          <Text fontSize="xs" fontStyle="italic" color="gray.500">
            {filterSummary ||
              audience.summary ||
              `No applicable filters (all ${audience.kind === 'profile' ? 'visitors' : 'accounts'})`}
          </Text>
        </Box>
      </HStack>
      {props.selectedItem?.id === audience.id && (
        <Icon as={IconCheck} flex="none" color="purple.500" boxSize={4} marginRight={2} />
      )}
    </Flex>
  )
}

function ListFilterPreview(list: AccountView) {
  const fakeFacets = useFacets({
    facet_filters: list.filters.facets
  })

  return <FilterPreview {...fakeFacets} kind={list.kind} isEditable={false} />
}

export function TerritorySetup(props: TerritorySetupProps) {
  const { targetType, apps } = props

  const territoryFacets = useFacets({
    facet_filters: props.territoryRules?.facets ?? {},
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  useUpdateEffect(() => {
    const changed = !isEqual(territoryFacets.facetFilters, props.territoryRules?.facets)
    if (changed) {
      props.onFacetsChange?.(territoryFacets)
    }
  }, [territoryFacets.facetFilters, props, territoryFacets])

  return (
    <Flex>
      <FilterPreview
        {...territoryFacets}
        kind={targetType === 'Profile' ? 'profile' : 'account'}
        apps={apps}
        canClearFilters={false}
        shouldShowIntentFilters={false}
        shouldShow3rdPartyFilters={false}
      />
    </Flex>
  )
}
