import { Heading, HStack, IconButton, PopoverCloseButton, Stack } from '@chakra-ui/react'
import { IconSearch } from '@tabler/icons-react'
import React from 'react'
import { HoverCard } from '../../../ui/HoverCard'

interface HoverContextProps {
  title: React.ReactNode
  children: React.ReactNode
  onOpen?: () => void
  iconSize?: string
}

export default function HoverContext(props: HoverContextProps) {
  return (
    <HoverCard
      trigger="click"
      isPortal
      onOpen={props.onOpen}
      popoverContentProps={{
        maxW: '600px',
        minW: '380px'
      }}
      hoverContent={
        <Stack py="2" spacing="3">
          <HStack>
            <Heading pt="1" mt="-2" size="xs">
              {props.title}
            </Heading>
            <PopoverCloseButton />
          </HStack>
          <Stack px="2" pr="4" spacing="3" fontWeight={'normal'} fontSize="xs">
            {props.children}
          </Stack>
        </Stack>
      }
    >
      <IconButton
        icon={<IconSearch size={props.iconSize || '12'} />}
        size="xs"
        variant="ghost"
        aria-label="See More"
        color="gray.400"
        _hover={{ color: 'gray.700' }}
      />
    </HoverCard>
  )
}
