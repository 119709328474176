import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Switch,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useUpdateEffect
} from '@chakra-ui/react'
import { IconAlertTriangle, IconClick, IconInfoCircle } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import { startCase } from 'lodash'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import { Card } from '../../ui/Card'
import { SearchIcon } from '../../ui/icons/SearchIcon'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import createPersistedState from '../../ui/usePersistedState'
import { SearchBar } from '../accounts/facets/search-bar'
import { mergeParams } from '../icps/types'
import { eventList } from './path-helpers'
import { PatternRules } from './pattern-rules'

export interface EventEntry {
  value: string
  count: number
}

type Model = 'PageView' | 'Event' | 'FormSubmission' | 'ProfileTrait' | 'AccountTrait'

interface Props {
  events: EventEntry[]
  settings: Record<
    string,
    {
      id: string
      ignored: boolean
      event_type: Model
      event_name: string
      matcher_type: MatcherPattern['operator']
    }
  >
  ignore_patterns: Array<{
    id: string
    event_name: string
    ignored: true
    event_type: Model
    matcher_type: MatcherPattern['operator']
  }>
  model: Model
  total_entries?: {
    page_views: number
    events: number
    form_submissions: number
    profile_traits: number
    account_traits: number
  }
  pagination_meta: PageMeta
  range: 'day' | 'week' | 'month'
}

function facetName(model: Props['model'], value?: string) {
  switch (model) {
    case 'PageView': {
      // remove trailing slash from page views
      // because we normalize them in opensearch
      let pv = value
      if (pv?.endsWith('/')) {
        pv = pv.slice(0, -1)
      }

      return `[uniq_page_views][]=${pv}`
    }
    case 'Event':
      return `[uniq_events][]=${value}`
    case 'FormSubmission':
      return `[form_fills.page_path][]=${value}`
    case 'ProfileTrait':
      return `[profile_traits_values.${value}.value][exists]`
    case 'AccountTrait':
      return `[account_traits_values.${value}.value][exists]`
  }
}

export type MatcherPattern = {
  id: string
  pattern: string
  operator: 'exact' | 'starts_with' | 'ends_with' | 'contains' | 'matches'
}

const userDismissedModal = createPersistedState<boolean>('event-manager-dismissed', { expiresIn: ms('1 day') })

export default function EventManager(props: Props) {
  const [filter, setFilter] = useState('')
  const [tab, setTab] = useState<'disabled' | 'all'>('all')
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState(props.settings)

  const [matcherPatterns, setMatcherPatterns] = useState<MatcherPattern[]>(
    props.ignore_patterns.map((s) => ({ id: s.id, pattern: s.event_name, operator: s.matcher_type }))
  )

  const onPatternSubmit = useCallback(
    (e) => {
      e.preventDefault()

      setLoading(true)
      post(projectPath(`/events/patterns?model=${props.model}`), {
        patterns: {
          matchers: matcherPatterns
        }
      })
        .then(() => {
          toast.success('Successfully updated pattern rules!')
        })
        .catch(() => {
          toast.error('Failed to update pattern rules!')
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [matcherPatterns, props.model]
  )

  const disabledEvents = useMemo(() => {
    return Object.keys(settings)
      .map((e) => {
        if (settings[e].event_type !== props.model || settings[e].matcher_type !== 'exact') {
          return null
        }

        return {
          value: e,
          count: props.events.find((ev) => ev.value === e)?.count
        }
      })
      .filter(Boolean) as EventEntry[]
  }, [props.events, settings, props.model])

  const events = useMemo(() => {
    if (tab === 'all') {
      return props.events
    }

    return disabledEvents
  }, [props.events, tab, disabledEvents])

  useEffect(() => {
    setLoading(false)
  }, [props])

  useUpdateEffect(() => {
    setLoading(true)

    if (filter === '') {
      router.visit(mergeParams(location.pathname, { page: '1' }))
      return
    }

    router.visit(mergeParams(location.pathname, { search: filter }))

    return () => {
      setLoading(false)
    }
  }, [filter])

  const toggleEvent = useCallback(
    async (e: string) => {
      const ignored = settings[e]?.ignored

      try {
        setLoading(true)
        await post<Props>(projectPath(`/events/${ignored ? 'restore' : 'ignore'}?name=${e}&model=${props.model}`)).then(
          (res) => {
            setSettings(res.settings)
          }
        )

        toast.success(`Successfully ${ignored ? 'restored' : 'ignored'} event!`)
      } catch (_error) {
        toast.error(`Failed to ${ignored ? 'restore' : 'ignore'} event!`)
      } finally {
        setLoading(false)
      }
    },
    [settings, props.model]
  )

  const [dismissed, setDismissed] = userDismissedModal(false)
  const label =
    props.model === 'Event'
      ? 'Custom Events'
      : props.model === 'ProfileTrait'
        ? 'Person Traits'
        : pluralize(startCase(props.model), 2)

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/events'), title: 'Events' }}
        paths={[
          {
            path: window.location.pathname,
            title: label
          }
        ]}
        offscreen
      />

      <Stack flex="1" overflow="auto" spacing="4" w="100%">
        <SettingsHeader borderBottomWidth={0} paddingBottom={0}>
          <Flex w="100%" alignItems="center" gap={3} justifyContent={'space-between'} flexWrap="wrap">
            <Stack flex="3" w="100%">
              <HStack>
                <IconClick />
                <PageTitle>Events</PageTitle>
              </HStack>
              <PageDescription>
                Manage and view events you want to send into Koala. Any disabled events will not be counted towards your
                event volume quota.
              </PageDescription>
            </Stack>
            <Flex justifyContent={'flex-end'} w="20">
              {loading && <Spinner />}
            </Flex>
            <SegmentedControl size="sm">
              <Button
                isActive={props.range === 'day'}
                as={Link}
                href={mergeParams(location.toString(), {
                  range: 'day'
                })}
              >
                Day
              </Button>
              <Button
                isActive={props.range === 'week'}
                as={Link}
                href={mergeParams(location.toString(), {
                  range: 'week'
                })}
              >
                Week
              </Button>
              <Button
                isActive={props.range === 'month'}
                as={Link}
                href={mergeParams(location.toString(), {
                  range: 'month'
                })}
              >
                Month
              </Button>
            </SegmentedControl>
          </Flex>
        </SettingsHeader>

        <Stack w="100%" spacing="8">
          <SegmentedControl w="100%">
            <Button
              flex={1}
              as={Link}
              href={projectPath(`/events/page-views?range=${props.range}`)}
              isActive={props.model === 'PageView'}
              rightIcon={
                props.total_entries && (
                  <Tag
                    size="sm"
                    variant={props.model === 'PageView' ? 'solid' : 'outline'}
                    colorScheme={props.model === 'PageView' ? 'purple' : 'gray'}
                    display={props.total_entries.page_views > 0 ? undefined : 'none'}
                  >
                    {format(props.total_entries.page_views)}
                  </Tag>
                )
              }
            >
              Page Views
            </Button>
            <Button
              flex={1}
              as={Link}
              href={projectPath(`/events/custom?range=${props.range}`)}
              isActive={props.model === 'Event'}
              rightIcon={
                props.total_entries && (
                  <Tag
                    variant={props.model === 'Event' ? 'solid' : 'outline'}
                    size="sm"
                    colorScheme={props.model === 'Event' ? 'purple' : 'gray'}
                    display={props.total_entries.events > 0 ? undefined : 'none'}
                  >
                    {format(props.total_entries.events)}
                  </Tag>
                )
              }
            >
              Custom Events
            </Button>
            <Button
              flex={1}
              as={Link}
              href={projectPath(`/events/forms?range=${props.range}`)}
              isActive={props.model === 'FormSubmission'}
              rightIcon={
                props.total_entries && (
                  <Tag
                    size="sm"
                    variant={props.model === 'FormSubmission' ? 'solid' : 'outline'}
                    colorScheme={props.model === 'FormSubmission' ? 'purple' : 'gray'}
                    display={props.total_entries.form_submissions > 0 ? undefined : 'none'}
                  >
                    {format(props.total_entries.form_submissions)}
                  </Tag>
                )
              }
            >
              Form Submissions
            </Button>
            <Button
              flex={1}
              as={Link}
              href={projectPath(`/events/visitor-traits?range=${props.range}`)}
              isActive={props.model === 'ProfileTrait'}
              rightIcon={
                props.total_entries && (
                  <Tag
                    display={props.total_entries.profile_traits > 0 ? undefined : 'none'}
                    size="sm"
                    variant={props.model === 'ProfileTrait' ? 'solid' : 'outline'}
                    colorScheme={props.model === 'ProfileTrait' ? 'purple' : 'gray'}
                  >
                    {format(props.total_entries.profile_traits)}
                  </Tag>
                )
              }
            >
              Person Traits
            </Button>
            <Button
              flex={1}
              as={Link}
              href={projectPath(`/events/account-traits?range=${props.range}`)}
              isActive={props.model === 'AccountTrait'}
              rightIcon={
                props.total_entries && (
                  <Tag
                    display={props.total_entries.account_traits > 0 ? undefined : 'none'}
                    variant={props.model === 'AccountTrait' ? 'solid' : 'outline'}
                    size="sm"
                    colorScheme={props.model === 'AccountTrait' ? 'purple' : 'gray'}
                  >
                    {format(props.total_entries.account_traits)}
                  </Tag>
                )
              }
            >
              Account Traits
            </Button>
          </SegmentedControl>

          {props.model !== 'AccountTrait' && props.model !== 'ProfileTrait' && (
            <>
              <form onSubmit={onPatternSubmit}>
                <PatternRules
                  type={props.model === 'Event' ? 'event' : 'page'}
                  isLoading={loading}
                  matcherPatterns={matcherPatterns}
                  setMatcherPatterns={setMatcherPatterns}
                />
              </form>
              <Divider />
            </>
          )}

          {props.events.length === 0 && filter === '' && (
            <Card bg="gray.50" shadow={'sm'} borderWidth="1px">
              <Stack spacing="4">
                <HStack>
                  <IconAlertTriangle />
                  <Heading size="sm" fontWeight={'semibold'}>
                    No events found
                  </Heading>
                </HStack>
                <Text>
                  We couldn't find any events in the past 1 week. Make sure your website is fully instrumented with the
                  Koala pixel in the Instrumentation Settings page.
                </Text>
                <Button colorScheme={'purple'} size="sm" as={Link} href={projectPath('/setup')}>
                  Instrumentation Settings
                </Button>
              </Stack>
            </Card>
          )}

          {(props.events.length > 0 || filter !== '') && (
            <>
              {!dismissed && (
                <Alert status="info" borderRadius="md" fontSize={'sm'}>
                  <AlertIcon />
                  <AlertDescription lineHeight={'1.4'}>
                    Event volumes are calculated on an hourly basis. It may take some time before you can see all events
                    and page views in the event manager dashboard.
                  </AlertDescription>
                  <CloseButton ml="auto" onClick={() => setDismissed(true)} />
                </Alert>
              )}

              <Stack spacing="4">
                <Tabs variant={'soft-rounded'} align="end" position="relative">
                  <HStack position="absolute" spacing="4" pl="2">
                    <Text fontWeight={'semibold'} pt="0.5">
                      Events
                    </Text>
                    <SearchBar
                      debounce={500}
                      inputProps={{
                        isReadOnly: loading
                      }}
                      size="sm"
                      value={filter}
                      onChange={(val) => setFilter(val)}
                    />
                  </HStack>
                  <TabList>
                    <Tab isSelected={tab === 'all'} onClick={() => setTab('all')} mx="2">
                      All
                    </Tab>
                    <Tab isSelected={tab === 'disabled'} onClick={() => setTab('disabled')}>
                      <HStack>
                        <Text>Disabled</Text>
                        <Tag size="sm" variant={'subtle'}>
                          {format(disabledEvents.length)}
                        </Tag>
                      </HStack>
                    </Tab>
                  </TabList>
                </Tabs>

                <Flex bg="gray.50" rounded="md" p="6">
                  <TableContainer w="100%" bg="white" p="6" rounded="md" borderWidth={'1px'}>
                    <Table size="md">
                      <Thead>
                        <Tr border="none" pb="2">
                          <Th border="none">
                            <HStack>
                              <Text>Event</Text>
                              <Tooltip label="These are events that are tracked in the Koala pixel.">
                                <IconInfoCircle size={14} />
                              </Tooltip>
                            </HStack>
                          </Th>
                          <Th border="none" isNumeric>
                            <Tooltip label="The number of times Koala ingested this event">
                              <HStack justifyContent={'flex-end'}>
                                <Text>Volume</Text>
                                <IconInfoCircle size={14} />
                              </HStack>
                            </Tooltip>
                          </Th>
                          <Th border={'none'} isNumeric>
                            Status
                          </Th>
                          <Th border="none" isNumeric></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {events.map((event) => (
                          <Tr
                            key={event.value}
                            _hover={{
                              bg: 'gray.50'
                            }}
                          >
                            <Td>
                              <TextEllipsis tooltip maxW={'500'}>
                                {event.value}
                              </TextEllipsis>
                            </Td>
                            <Td isNumeric>
                              <Text fontWeight={'semibold'}>{event.count ? format(event.count) : '--'}</Text>
                            </Td>
                            <Td isNumeric>
                              <Switch
                                colorScheme={'green'}
                                defaultChecked={!settings[event.value]?.ignored}
                                onChange={() => toggleEvent(event.value)}
                              />
                            </Td>
                            <Td isNumeric>
                              <Tooltip label="View Samples">
                                <IconButton
                                  icon={<SearchIcon size={14} />}
                                  aria-label="View Samples"
                                  as={Link}
                                  size="xs"
                                  variant={'ghost'}
                                  isExternal
                                  href={eventList('visitors', facetName(props.model, event.value), 'week')}
                                  style={{
                                    textDecoration: 'none'
                                  }}
                                />
                              </Tooltip>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Stack>
              <TableFooter
                pageMeta={props.pagination_meta}
                page={props.pagination_meta.current_page}
                nextPath={mergeParams(location.toString(), {
                  page: props.pagination_meta.next_page?.toString()
                })}
                prevPath={mergeParams(location.toString(), {
                  page: props.pagination_meta.prev_page?.toString()
                })}
              />
            </>
          )}
        </Stack>
      </Stack>
    </PageLayout>
  )
}
